import React, { useState, useEffect } from "react";

const ConfirmationBox = (props) => {
  const onHideMessageBox = () => {
    props.onCancel();
  };

  return (
    <div
      class="modal"
      tabindex="-1"
      style={
        props.show
          ? {
              display: "block",
              width: props.width,
              position: "absolute",
              top: "15%",
              left: "35%",
            }
          : { display: "none" }
      }
      id={props.id ? props.id : "confirmationMsg"}
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div
            class="modal-header"
            style={{ backgroundColor: "black", color: "white" }}
          >
            <h5 class="modal-title">Confirmation</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              style={{ backgroundColor: "#ff0000" }}
              onClick={() => onHideMessageBox()}
            ></button>
          </div>
          <div class="modal-body">{props.message}</div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
              style={{ width: "20%" }}
              onClick={() => onHideMessageBox()}
            >
              No
            </button>
            <button
              type="button"
              class="btn btn-success"
              onClick={() => props.onSuccess()}
              style={{ width: "20%" }}
            >
              Yes
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationBox;
