import React, { useState, useEffect } from "react";
import ConfirmationBox from "../components/ConfirmationBox";
import apiHandler from "../commonfunction/data";
import { useNavigate } from "react-router-dom";
import { Icon } from "semantic-ui-react";
import Loader from "../components/Loader";
import globalFunction from "../commonfunction/globalFunction";
import "../assets/css/ButtonOnImage.css";
import assets from "../assets/assets";
import { TransitionablePortal, Segment, Header } from "semantic-ui-react";

const initialData = {
  id: 0,
  image: "",
  salesOfferId: 0,
};
const Carousel = (props) => {
  const [showPopup, setShowHidePopup] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [animation, setAnimation] = useState(assets.transitions[12]);
  const [duration, setduration] = useState(800);
  const [messageText, setMessage] = useState();
  const [loginInfo, setLoginInfo] = useState();
  const [isEdit, setEdit] = useState(false);
  const [globaldata, setData] = useState([{}]);
  const [salesOffersData, setSalesOffersData] = useState([{}]);
  const [openConfirmationBox, setOpenConfirmationBox] = useState(false);
  const [forceRefreshScreen, setforceRefreshScreen] = useState(false);
  const [open, showMessage] = useState(false);
  const [popupTitle, setTitle] = useState("New Carausel Entry");
  const [SelectedCarauselId, setSelectedCarauselId] = useState(0);
  const [editableData, setEditData] = useState(initialData);
  const [imgData, setImageState] = useState();

  const navigation = useNavigate();

  const actionButtonStyle = {
    width: 20,
  };
  useEffect(() => {
    props.showTitle("Carousel Master");
    props.hideSideBar(false);
    const loginData = JSON.parse(
      apiHandler.getDataFromLocalStorage("loginDetails")
    );
    setLoginInfo(loginData);
    if (
      loginData === null ||
      loginData.userName === "" ||
      loginData.password === ""
    ) {
      navigation("/");
    }

    setShowLoader(true);
    setTimeout(() => {
      apiHandler.getCaraousel().then((result) => {
        setData(result);
        apiHandler.getSalesOffers().then((offers) => {
          setSalesOffersData(offers);
          setShowLoader(false);
        });
      });
      setShowLoader(false);
    }, 1000);
  }, [open]);
  useEffect(() => {
    const title = isEdit ? "Edit Caraousel" : "New Caraousel";
    setTitle(title);
  }, [isEdit]);
  useEffect(() => {
    console.log("Test");
  }, [forceRefreshScreen]);
  const onCanel = () => {
    setOpenConfirmationBox(false);
  };
  const ShowConfirmaton = (selectedCaraouselId) => {
    setMessage("Are you sure you want to delete the selected carousel?");

    setOpenConfirmationBox(true);
    setSelectedCarauselId(selectedCaraouselId);
    setforceRefreshScreen(!forceRefreshScreen);
  };
  const onEdit = (selectedId) => {
    const data = globaldata.filter((w) => w.id === selectedId);
    setEditData(data[0]);
    setEdit(true);
    showHidePopup(true);
  };
  const onhandleClose = () => {
    showHidePopup(false);
    window.location.reload(true);
  };
  const reSizeImage = async (file) => {
    try {
      const image = await globalFunction.resizeFile(file);
      return image;
    } catch (err) {
      console.log(err);
    }
  };
  const onSave = () => {
    const file = imgData;
    if (file === undefined) {
      setTimeout(() => {
        apiHandler
          .uploadFile(
            file,
            `caraousel`,
            "caraousel",
            editableData.id,
            loginInfo.id,
            editableData.salesOfferId
          )
          .then((el) => {
            if (el[0].status === "Success") {
              setMessage("File Uploaded Successfully!");
            } else {
              setMessage("File upload failed!");
            }
            setShowHidePopup(false);
            showMessage(true);
            setTimeout(() => {
              showMessage(false);
            }, 1500);
          });
      }, 1000);
    } else {
      reSizeImage(file).then((img) => {
        const fileData = globalFunction.dataURLtoFile(img, file.name);
        let fileSize = file.size / 1024;
        let _file = fileSize > 500 ? fileData : file;
        setTimeout(() => {
          apiHandler
            .uploadFile(
              _file,
              `/caraousel`,
              "caraousel",
              editableData.id,
              loginInfo.id,
              editableData.salesOfferId
            )
            .then((el) => {
              if (el[0].status === "Success") {
                setMessage("File Uploaded Successfully!");
              } else {
                setMessage("File upload failed!");
              }
              setShowHidePopup(false);
              showMessage(true);
              setTimeout(() => {
                showMessage(false);
              }, 1500);
            });
        }, 1000);
      });
    }
  };
  const renderGrid = () => {
    return globaldata.map((w) => {
      return (
        <tr className="table-info" id={w.id}>
          <td className="col-xs" style={actionButtonStyle}>
            <Icon className="trash" onClick={() => ShowConfirmaton(w.id)} />
          </td>
          <td className="col-xs" style={actionButtonStyle}>
            <Icon className="edit" onClick={(e) => onEdit(w.id)} id={w.id} />
          </td>
          <td>
            <img
              src={
                w.image !== undefined
                  ? `${assets.IMAGE_URL}/caraousel/${w.image}`
                  : ""
              }
              style={{ height: 25 }}
              srcSet={
                w.image !== undefined
                  ? `${assets.IMAGE_URL}/caraousel/${w.image} 1x`
                  : ""
              }
            />
          </td>
        </tr>
      );
    });
  };
  const setImage = (e) => {
    const img = e.target.files[0];
    var reader = new FileReader();
    var imgBox = document.getElementById("caraousel_img");
    reader.onload = function (e) {
      imgBox.src = e.target.result;
    };
    reader.readAsDataURL(img);
    setImageState(img);
    setEditData({
      ...editableData,
      [e.target.id]: img.name,
    });
  };
  const onchange = (e) => {
    setEditData({
      ...editableData,
      [e.target.id]:
        e.target.type === "checkbox" ? e.target.checked : e.target.value,
    });
  };
  const renderPopupBody = () => {
    return (
      <>
        <div class="row">
          <div class="col">
            <div class="input-group mb-3">
              <input
                type="file"
                class="form-control"
                id="caraouselImage"
                onChange={(e) => setImage(e)}
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <select
              class="form-select"
              style={{ marginTop: 8, height: 46 }}
              value={editableData.salesOfferId}
              id="salesOfferId"
              onChange={(e) => onchange(e)}
            >
              <option value="-1">Choose Offer</option>
              {salesOffersData.map((el) => (
                <option value={el.id}>{el.offerTitle}</option>
              ))}
            </select>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <img
              src={`${assets.IMAGE_URL}/caraousel/${editableData.image}`}
              class="img-thumbnail float-start"
              alt="..."
              style={{ height: 180, width: 180 }}
              id="caraousel_img"
            />
          </div>
        </div>
      </>
    );
  };
  const showHidePopup = (show) => {
    setShowHidePopup(show);
  };
  const renderAddEditPopup = () => {
    return (
      <div
        class="modal"
        tabindex="-1"
        style={showPopup ? { display: "block" } : {}}
        id="dataEntry"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">{popupTitle}</h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => showHidePopup(false)}
              ></button>
            </div>
            <div class="modal-body">{renderPopupBody()}</div>
            <div class="modal-footer">
              <div class="row" style={{ width: "100%" }}>
                <div class="col">
                  <button
                    type="button"
                    class="btn btn-primary"
                    onClick={() => {
                      onSave();
                    }}
                  >
                    Save changes
                  </button>
                </div>
                <div class="col">
                  <button
                    type="button"
                    class="btn btn-secondary"
                    data-bs-dismiss="modal"
                    onClick={() => onhandleClose()}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const onDelete = () => {
    const prm = {
      id: SelectedCarauselId,
      userId: loginInfo.id,
    };
    setTimeout(() => {
      apiHandler.deleteCaraousel(prm).then((w) => {
        if (w[0].result === "Success") {
          setMessage("Data Deleted Successfully!");
        } else {
          setMessage("Faild to delete Data!");
        }
        setShowLoader(false);
        setOpenConfirmationBox(false);
        showMessage(true);
        setTimeout(() => {
          showMessage(false);
        }, 1000);
      });
      showHidePopup(false);
    }, 500);
  };
  const onAddNew = () => {
    setEdit(false);
    showHidePopup(true);

    setEditData(initialData);
    setforceRefreshScreen(!forceRefreshScreen);
  };
  const renderConfirmationMessageBox = () => {
    return (
      <div>
        <ConfirmationBox
          onCancel={() => {
            onCanel();
          }}
          show={openConfirmationBox}
          message={messageText}
          onSuccess={() => onDelete()}
          width="30%"
        />
      </div>
    );
  };
  return (
    <>
      <div className="container">
        <div style={{ width: 100, paddingBottom: 5 }}>
          <button
            type="button"
            class="btn btn-primary btn-md"
            onClick={() => onAddNew()}
          >
            Add New
          </button>
        </div>
        <table class="table table-striped table-hover table-bordered ">
          <thead>
            <tr className="table-dark">
              <th scope="col-xs">#</th>
              <th scope="col-xs">#</th>
              <th scope="col">Image</th>
            </tr>
          </thead>
          <tbody>{renderGrid()}</tbody>
        </table>
      </div>
      {renderAddEditPopup()}
      <TransitionablePortal open={open} transition={{ animation, duration }}>
        <Segment
          // style={{
          //   left: "85%",
          //   position: "fixed",
          //   top: "20%",
          //   zIndex: 1000,
          //   backgroundColor: "green",
          //   color: "white",
          //   width: 350,
          // }}
          id="transationMessage"
        >
          <Header>Information</Header>
          <p>{messageText}</p>
        </Segment>
      </TransitionablePortal>
      <Loader showLoader={showLoader} />
      {renderConfirmationMessageBox()}
    </>
  );
};

export default Carousel;
