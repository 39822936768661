import React, { useEffect, useState, useContext, useRef } from "react";
import LoginContext from "../context/LoginContext";
import { Icon } from "semantic-ui-react";
import apiHandler from "../commonfunction/data";
import Loader from "../components/Loader";
import assets from "../assets/assets";
import {
  Confirm,
  TransitionablePortal,
  Segment,
  Header,
  Popup,
} from "semantic-ui-react";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import globalFunction from "../commonfunction/globalFunction";
import "../assets/css/ButtonOnImage.css";
import ConfirmationBox from "../components/ConfirmationBox";

const initialData = {
  id: -1,
  productId: "",
  productName: "",
  productDescription: "",
  mrp: "",
  salesPrice: "",
  qty: "",
  committed: "",
  sku: "",
  brandId: -1,
  categoryId: "",
  category: -1,
  brandName: "",
  returnable: 0,
  appliancesType: 0,
  GSTPer: 0,
  propertyData: [],
  itemImageData: [],
  itemOfferData: [
    {
      id: -1,
      offerId: -1,
      itemId: -1,
      offerDetails: "",
      minQty: null,
      maxQty: null,
      salesPriceEach: 0,
    },
  ],
  invImageUrl: "",
  hsnCode: "",
};
const filters = {
  productId: "",
  productName: "",
  mrp: "",
  salesPrice: "",
  qty: "",
  committed: "",
  avail: "",
  sku: "",
  brandId: -1,
  categoryId: "",
  category: -1,
  appliancesType: 0,
  GSTPer: "",
};
const Inventory = (props) => {
  const [showPopup, setShowHidePopup] = useState(false);
  //const [isAddNew, setAddNew] = useState(false);
  const [isEdit, setEdit] = useState(false);
  const [inventory, setInventoryData] = useState([{}]);
  const [allInventory, setAllInventoryData] = useState([{}]);
  const [invPropertyData, setInvPropertyData] = useState([{}]);
  const [invOfferData, setInvOfferData] = useState([{}]);
  const [invImageData, setInvImageData] = useState([{}]);
  const [gblInvPropertyData, setPropertyDataGlobal] = useState([{}]);

  const [editableData, setEditData] = useState(initialData);
  const [popupTitle, setTitle] = useState("New Item Entry");
  const [open, showMessage] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [animation, setAnimation] = useState(assets.transitions[12]);
  const [duration, setduration] = useState(800);
  const [messageText, setMessage] = useState();
  //const [openConfirmationBox, setOpenConfirmationBox] = useState(false);
  const [itemId, setItemID] = useState(0);
  const [SelectedImageId, setSelectedImageId] = useState(0);
  const [productName, setItemName] = useState();
  const [showValidationMessage, setShowValidationMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const loginAuth = useContext(LoginContext);
  const [loginInfo, setLoginInfo] = useState();
  const [imgData, setImageState] = useState([]);
  const [categoryData, setCategoryData] = useState([{}]);
  const [brandData, setBrandData] = useState([{}]);
  const [appliancesType, setAppliancesType] = useState([{}]);
  const [forceRefreshScreen, setforceRefreshScreen] = useState(false);
  const [importError, setImportError] = useState([]);
  const [showErrorPopup, setShowErrorPopUp] = useState(false);
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const [confirmationBoxOpenSource, setConfirmationBoxOpenSource] = useState();
  // const [onBrowseImageState, setStateOnBrowseImage] = useState(false);
  // const [localImageSource, setLocalImageSource] = useState([]);
  const [thumbnail, setThumbnail] = useState([]);
  const [thumbnailLocal, setThumbnailLocal] = useState([]);
  const [onBrowseThumbNailImage, setOnBrowseThumbNailImage] = useState(false);
  const [thumbnailState, setThumbnailState] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(0);
  const [filterData, setFilterData] = useState(filters);
  const [applyFilter, setApplyFilter] = useState(false);
  const [thumbnailImg, setThumbnailImg] = useState();
  const [showZoomPopup, setShowZoomPopup] = useState(false);

  //const [inventoryProperties, setInventoryProperties] = useState([]);

  const navigation = useNavigate();
  const contextRef = useRef();
  const actionButtonStyle = {
    width: 20,
  };
  const hideColumn = { display: "none" };
  useEffect(() => {
    props.showTitle("Inventory");
    props.hideSideBar(false);
    const loginData = JSON.parse(
      apiHandler.getDataFromLocalStorage("loginDetails")
    );
    setLoginInfo(loginData);
    if (
      loginData === null ||
      loginData.userName === "" ||
      loginData.password === ""
    ) {
      navigation("/");
    }
    setShowLoader(true);
    setTimeout(() => {
      apiHandler.getInventory().then((result) => {
        setAllInventoryData(result);
        const activeInv = result.filter((w) => w.active == 1);
        setInventoryData(activeInv);
        apiHandler.getCategory().then((catData) => {
          setCategoryData(catData);
          apiHandler.getBrandData().then((brands) => {
            setBrandData(brands);
            apiHandler.getInvPropertyInJson().then((property) => {
              setInvPropertyData(property);
              setPropertyDataGlobal(property);
              apiHandler.getInventoryImages().then((img) => {
                setInvImageData(img);
                apiHandler.getInventoryOffers().then((offers) => {
                  setInvOfferData(offers);
                  apiHandler.getAppliancesType().then((type) => {
                    setAppliancesType(type);
                  });
                });
              });
            });
          });
        });
        setShowLoader(false);
      });
    }, 500);
  }, [open, forceRefreshScreen]);
  useEffect(
    () => {
      const title = isEdit ? "Edit Item Details" : "Add New Item";
      setTitle(title);
    },
    [isEdit],
    forceRefreshScreen,
    onBrowseThumbNailImage
  );
  useEffect(() => {}, [selectedCategory]);
  useEffect(() => {
    let searchData = allInventory;
    if (filterData.productId !== "") {
      searchData = searchData.filter(
        (w) => w["productId"].toLowerCase().indexOf(filterData.productId) !== -1
      );
    }
    if (filterData.productName !== "") {
      searchData = searchData.filter(
        (w) =>
          w["productName"].toLowerCase().indexOf(filterData.productName) !== -1
      );
    }
    if (parseInt(filterData.categoryId) > -1) {
      searchData = searchData.filter(
        (w) => parseInt(w["categoryId"]) === parseInt(filterData.categoryId)
      );
    }
    if (parseInt(filterData.brandId) > -1) {
      searchData = searchData.filter(
        (w) => parseInt(w["brandId"]) === parseInt(filterData.brandId)
      );
    }
    if (filterData.mrp !== "") {
      searchData = searchData.filter(
        (w) => parseInt(w["mrp"]) === parseInt(filterData.mrp)
      );
    }
    if (filterData.salesPrice !== "") {
      searchData = searchData.filter(
        (w) => parseInt(w["salesPrice"]) === parseInt(filterData.salesPrice)
      );
    }
    if (filterData.committed !== "") {
      searchData = searchData.filter(
        (w) => parseInt(w["committed"]) === parseInt(filterData.committed)
      );
    }
    if (filterData.qty !== "") {
      searchData = searchData.filter(
        (w) => parseInt(w["qty"]) === parseInt(filterData.qty)
      );
    }
    if (filterData.avail !== "") {
      searchData = searchData.filter(
        (w) =>
          parseInt(w["qty"]) - parseInt(w["committed"]) ===
          parseInt(filterData.avail)
      );
    }
    if (filterData.sku !== "") {
      searchData = searchData.filter(
        (w) => w["sku"] === parseInt(filterData.sku)
      );
    }
    if (parseInt(filterData.appliancesType) > 0) {
      searchData = searchData.filter(
        (w) =>
          parseInt(w["appliancesType"]) === parseInt(filterData.appliancesType)
      );
    }
    if (parseInt(filterData.GSTPer) > 0) {
      searchData = searchData.filter(
        (w) => parseInt(w["GSTPer"]) === parseInt(filterData.GSTPer)
      );
    }
    setInventoryData(searchData);
  }, [applyFilter]);
  const showHidePopup = (show) => {
    setShowHidePopup(show);
  };
  const ShowAllInventory = (e) => {
    if (e.target.checked) {
      setInventoryData(allInventory);
    } else {
      const data = allInventory.filter((w) => parseInt(w.active) === 1);
      setInventoryData(data);
    }
  };
  const onAddNew = () => {
    const propData = gblInvPropertyData.filter(
      (w) => parseInt(w.itemId) === -1
    );
    setEdit(false);
    showHidePopup(true);
    Object.assign(initialData.propertyData, { ...propData });

    setEditData(initialData);
    setSelectedCategory(-1);
    setforceRefreshScreen(!forceRefreshScreen);
    setShowValidationMessage(false);
  };
  const onEdit = (selectedId) => {
    const data = inventory.filter((w) => w.id === selectedId);
    let itemProp = invPropertyData.filter(
      (w) => parseInt(w.itemId) === parseInt(selectedId)
    );
    let itemOffers = invOfferData.filter(
      (w) => parseInt(w.itemId) === parseInt(selectedId)
    );
    let itemImages = invImageData.filter(
      (w) => parseInt(w.itemId) === parseInt(selectedId)
    );
    itemProp = itemProp.length
      ? itemProp
      : invPropertyData.filter((w) => w.itemId < 0);
    itemProp.map((w) => {
      w.itemId = selectedId;
    });
    itemOffers = itemOffers.length ? itemOffers : initialData.itemOfferData;
    Object.assign(...itemOffers, { itemId: selectedId });
    Object.assign(...data, {
      propertyData: itemProp,
      itemOfferData: itemOffers,
      itemImageData: itemImages,
    });
    setEdit(true);
    showHidePopup(true);
    setSelectedCategory(data[0].categoryId);
    setEditData(data[0]);
  };
  const onSave = () => {
    if (validateInput() === false) return;
    const data = {
      ...editableData,
      ...{ entryStatus: isEdit ? "update" : "new" },
      ...{ userId: loginInfo.id },
    };
    if (data === undefined) {
      setMessage("Please enter data before save.");
      showMessage(true);
      setTimeout(() => showMessage(false), 1000);
      return;
    } else {
      setMessage("");
    }
    setShowLoader(true);

    setTimeout(() => {
      apiHandler.saveInventory(data).then((inv) => {
        const propData = {
          ...data,
          ...{ prodId: inv[0].id },
        };
        const prm2 = { id: inv[0].id };
        let prod = "";
        (async () => {
          const result = await apiHandler.getItemDetailByItemId(prm2);
          prod = result[0].productId;
        })();

        apiHandler.saveInventoryProperties(propData).then((result) => {
          apiHandler.saveInventoryOffers(propData).then((w) => {
            imgData.map((fileData) => {
              apiHandler
                .uploadFile(fileData, `/inventory/${prod}`, "")
                .then((imgResult) => {});
            });
            //UPLOAD THUMBNAIL IMAGE
            if (thumbnailState.length > 0) {
              apiHandler
                .uploadFile(
                  thumbnailState[0],
                  `/inventory/${prod}`,
                  "invThumbnail",
                  inv[0].id,
                  loginInfo.id,
                  inv[0].id
                )
                .then((el) => {
                  if (el[0].status === "Success") {
                    setMessage("Thumbnail Uploaded Successfully!");
                  } else {
                    setMessage("Thumbnail File upload failed!");
                  }
                });
            }

            apiHandler.saveInventoryImages(propData).then((el) => {
              if (el[0].result === "Success") {
                setMessage("Data saved Successfully!");
              } else {
                setMessage("Faild to save data!");
              }
              setShowHidePopup(false);
              showMessage(true);
              setTimeout(() => {
                showMessage(false);
              }, 1000);
            });
          });
        });
        showMessage(true);
        setforceRefreshScreen(!forceRefreshScreen);
      });
      setShowHidePopup(false);
    }, 500);
  };
  const validateInput = () => {
    if (parseInt(editableData.productName.length) === 0) {
      setErrorMessage("Please enter Product Name..");
      setShowValidationMessage(true);
      return false;
    }
  };
  const ShowConfirmaton = (itemId, productName) => {
    // setOpenConfirmationBox(true);
    setItemID(itemId);
    setItemName(productName);
    onOpenConfirmationDialog("delete");
  };
  const onDelete = () => {
    const prm = {
      id: itemId,
      userId: loginInfo.id,
      productName: productName,
    };
    setTimeout(() => {
      apiHandler.deleteInventory(prm).then((w) => {
        if (w[0].result === "Success") {
          setMessage("Data Deleted Successfully!");
        } else {
          setMessage("Faild to delete Data!");
        }
        setShowLoader(false);
        setShowConfirmationPopup(false);
        showMessage(true);
        setTimeout(() => {
          showMessage(false);
        }, 1000);
      });
      showHidePopup(false);
    }, 500);
  };
  const onchange = (e) => {
    console.log("e.target.value", e.target.value);
    setEditData({
      ...editableData,
      [e.target.id]:
        e.target.type === "checkbox"
          ? e.target.checked
            ? 1
            : 0
          : e.target.value,
    });
    if (e.target.id === "categoryId") {
      setSelectedCategory(e.target.value);
    }
  };
  const onDeleteImage = (imgId) => {
    const prm = { imgId: imgId };
    (async () => {
      const result = await apiHandler.deleteInventoryImage(prm);
      if (result[0].result === "Success") {
        setMessage("Data Deleted Successfully!");
      } else {
        setMessage("Faild to delete Data!");
      }
      apiHandler.getInventoryImages().then((img) => {
        const imgData = img.filter((w) => w.itemId === editableData.id);
        editableData.itemImageData.splice(
          0,
          editableData.itemImageData.length,
          ...imgData
        );
      });
      showMessage(true);

      setTimeout(() => {
        setforceRefreshScreen(!forceRefreshScreen);
        showMessage(false);
      }, 1500);
    })();
  };
  const onPropertyChange = (e, propId, itemId) => {
    const rowIndex = editableData.propertyData.findIndex(
      (w) =>
        parseInt(w.propId) === parseInt(propId) &&
        parseInt(w.itemId) === parseInt(itemId)
    );
    editableData.propertyData[rowIndex].propValue = e.target.value;
    setforceRefreshScreen(!forceRefreshScreen);
  };
  const onOfferChange = (e) => {
    Object.assign(...editableData.itemOfferData, {
      [e.target.id]: e.target.value,
    });
    setforceRefreshScreen(!forceRefreshScreen);
  };
  const renderGrid = () => {
    return inventory.map((w) => {
      return (
        <tr className="table-info" id={w.id}>
          <td className="col-xs" style={actionButtonStyle}>
            <Icon
              className="trash"
              onClick={() => ShowConfirmaton(w.id, w.productName)}
            />
          </td>
          <td className="col-xs" style={actionButtonStyle}>
            <Icon className="edit" onClick={(e) => onEdit(w.id)} id={w.id} />
          </td>
          <td style={{ width: "10%" }}>{w.productId}</td>
          <td style={{ width: "25%", textAlign: "left" }}>{w.productName}</td>
          <td style={{ width: "8%" }}>{w.category}</td>
          <td style={{ width: "8%" }}>{w.brandName}</td>
          <td style={{ width: "8%" }}>{w.mrp}</td>
          <td style={{ width: "8%" }}>{w.salesPrice}</td>
          <td style={{ width: "5%" }}>{w.qty}</td>
          <td style={{ width: "5%" }}>{w.committed}</td>
          <td style={{ width: "5%" }}>{w.qty - w.committed}</td>
          <td style={{ width: "5%" }}>{w.sku}</td>
          <td style={{ width: "10%" }}>{w.appliancesTypeDesc}</td>
          <td style={{ width: "3%" }}>{w.GSTPer}</td>
        </tr>
      );
    });
  };
  const renderPropertyAccordion = () => {
    const data = editableData
      ? editableData.propertyData.filter(
          (w) => parseInt(w.categoryId) === parseInt(selectedCategory)
        )
      : [];
    const propSectionList = _.uniqBy(data, (obj) => obj.propSection);
    return (
      <>
        {propSectionList.map((prop) => (
          <div class="accordion" id={`accordion_${prop.propSection}`}>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingOne">
                <button
                  class="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target={`#collapseOne_${prop.propSection}`}
                  aria-expanded="true"
                  aria-controls={`collapseOne_${prop.propSection}`}
                  style={{
                    backgroundColor: "#0d6efd",
                    color: "white",
                    fontWeight: "bold",
                  }}
                >
                  {prop.propSection === "1" ? "Heighlights" : "Specifications"}
                </button>
              </h2>
              <div
                id={`collapseOne_${prop.propSection}`}
                class="accordion-collapse collapse show"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  {data
                    .filter((w) => w.propSection === prop.propSection)
                    .map((bodyData) => (
                      <div id={`row_${bodyData.id}`}>
                        <div class="row">
                          <div class="col-3">
                            <label>{bodyData.propName}</label>
                          </div>
                          <div class="col-3">
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Property Value"
                              aria-label="Property Value"
                              value={
                                bodyData.propValue ? bodyData.propValue : ""
                              }
                              id={bodyData.propName}
                              onChange={(e) =>
                                onPropertyChange(
                                  e,
                                  bodyData.propId,
                                  bodyData.itemId
                                )
                              }
                              required
                              style={{ height: 35, marginTop: -5 }}
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        ))}
      </>
    );
  };
  // const renderInvOfferAccordion = () => {
  //   const data = editableData
  //     ? editableData.itemOfferData
  //       ? editableData.itemOfferData
  //       : []
  //     : [];
  //   return (
  //     <>
  //       {data.length > 0 ? (
  //         data.map((prop) => (
  //           <div class="accordion" id={`accordionInv_${prop.offerId}`}>
  //             <div class="accordion-item">
  //               <h2 class="accordion-header" id="headingOne">
  //                 <button
  //                   class="accordion-button"
  //                   type="button"
  //                   data-bs-toggle="collapse"
  //                   data-bs-target={`#collapseOneInv_${prop.offerId}`}
  //                   aria-expanded="true"
  //                   aria-controls={`collapseOneInv_${prop.offerId}`}
  //                   style={{
  //                     backgroundColor: "#0d6efd",
  //                     color: "white",
  //                     fontWeight: "bold",
  //                   }}
  //                 >
  //                   Inventory Item Offer
  //                 </button>
  //               </h2>
  //               <div
  //                 id={`collapseOneInv_${prop.offerId}`}
  //                 class="accordion-collapse collapse show"
  //                 aria-labelledby="headingOne"
  //                 data-bs-parent="#accordionExample"
  //               >
  //                 <div class="accordion-body">
  //                   <div id={`row_${prop.offerId}`}>
  //                     <div class="row">
  //                       <div class="col-6">
  //                         <label>Offer Details</label>
  //                       </div>
  //                       <div class="col-6">
  //                         <input
  //                           type="text"
  //                           class="form-control"
  //                           placeholder="Offer Details"
  //                           aria-label="Offer Details"
  //                           value={!prop.offerDetails ? "" : prop.offerDetails}
  //                           id="offerDetails"
  //                           onChange={(e) => onOfferChange(e)}
  //                           required
  //                           style={{ height: 35, marginTop: -5 }}
  //                         />
  //                       </div>
  //                       <div class="col-6">
  //                         <label>Min Qty</label>
  //                       </div>
  //                       <div class="col-6">
  //                         <input
  //                           type="text"
  //                           class="form-control"
  //                           placeholder="Min Qty"
  //                           aria-label="Min qty"
  //                           value={!prop.minQty ? 0 : prop.minQty}
  //                           id="minQty"
  //                           onChange={(e) => onOfferChange(e)}
  //                           required
  //                           style={{ height: 35, marginTop: -5 }}
  //                         />
  //                       </div>
  //                       <div class="col-6">
  //                         <label>Max Qty</label>
  //                       </div>
  //                       <div class="col-6">
  //                         <input
  //                           type="text"
  //                           class="form-control"
  //                           placeholder="Max Qty"
  //                           aria-label="Max qty"
  //                           value={!prop.maxQty ? 0 : prop.maxQty}
  //                           id="maxQty"
  //                           onChange={(e) => onOfferChange(e)}
  //                           required
  //                           style={{ height: 35, marginTop: -5 }}
  //                         />
  //                       </div>
  //                       <div class="col-6">
  //                         <label>{`Sales Price(Each)`}</label>
  //                       </div>
  //                       <div class="col-6">
  //                         <input
  //                           type="text"
  //                           class="form-control"
  //                           placeholder="Sales Price"
  //                           aria-label="Sales Price"
  //                           value={
  //                             !prop.salesPriceEach ? 0 : prop.salesPriceEach
  //                           }
  //                           id="salesPriceEach"
  //                           onChange={(e) => onOfferChange(e)}
  //                           required
  //                           style={{ height: 35, marginTop: -5 }}
  //                         />
  //                       </div>
  //                     </div>
  //                   </div>
  //                 </div>
  //               </div>
  //             </div>
  //           </div>
  //         ))
  //       ) : (
  //         <div class="accordion" id={`accordionInv_1`}>
  //           <div class="accordion-item">
  //             <h2 class="accordion-header" id="headingOne">
  //               <button
  //                 class="accordion-button"
  //                 type="button"
  //                 data-bs-toggle="collapse"
  //                 data-bs-target={`#collapseOneInv_1`}
  //                 aria-expanded="true"
  //                 aria-controls={`collapseOneInv_1`}
  //                 style={{
  //                   backgroundColor: "#0d6efd",
  //                   color: "white",
  //                   fontWeight: "bold",
  //                 }}
  //               >
  //                 Inventory Item Offer
  //               </button>
  //             </h2>
  //             <div
  //               id={`collapseOneInv_1`}
  //               class="accordion-collapse collapse show"
  //               aria-labelledby="headingOne"
  //               data-bs-parent="#accordionExample"
  //             >
  //               <div class="accordion-body">
  //                 <div id={`row_1`}>
  //                   <div class="row">
  //                     <div class="col-6">
  //                       <label>Offer Details</label>
  //                     </div>
  //                     <div class="col-6">
  //                       <input
  //                         type="text"
  //                         class="form-control"
  //                         placeholder="Offer Details"
  //                         aria-label="Offer Details"
  //                         //value={prop.offerDetails}
  //                         id="offerDetails"
  //                         onChange={(e) => onOfferChange(e)}
  //                         required
  //                         style={{ height: 35, marginTop: -5 }}
  //                       />
  //                     </div>
  //                     <div class="col-6">
  //                       <label>Min Qty</label>
  //                     </div>
  //                     <div class="col-6">
  //                       <input
  //                         type="text"
  //                         class="form-control"
  //                         placeholder="Min Qty"
  //                         aria-label="Min qty"
  //                         //value={prop.minQty}
  //                         id="minQty"
  //                         onChange={(e) => onOfferChange(e)}
  //                         required
  //                         style={{ height: 35, marginTop: -5 }}
  //                       />
  //                     </div>
  //                     <div class="col-6">
  //                       <label>Max Qty</label>
  //                     </div>
  //                     <div class="col-6">
  //                       <input
  //                         type="text"
  //                         class="form-control"
  //                         placeholder="Max Qty"
  //                         aria-label="Max qty"
  //                         //value={prop.maxQty}
  //                         id="maxQty"
  //                         onChange={(e) => onOfferChange(e)}
  //                         required
  //                         style={{ height: 35, marginTop: -5 }}
  //                       />
  //                     </div>
  //                     <div class="col-6">
  //                       <label>{`Sales Price(Each)`}</label>
  //                     </div>
  //                     <div class="col-6">
  //                       <input
  //                         type="text"
  //                         class="form-control"
  //                         placeholder="Sales Price"
  //                         aria-label="Sales Price"
  //                         //value={prop.salesPriceEach}
  //                         id="salesPriceEach"
  //                         onChange={(e) => onOfferChange(e)}
  //                         required
  //                         style={{ height: 35, marginTop: -5 }}
  //                       />
  //                     </div>
  //                   </div>
  //                 </div>
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //       )}
  //     </>
  //   );
  // };
  const renderImages = () => {
    let imageData = editableData
      ? editableData.itemImageData.length
        ? editableData.itemImageData
        : []
      : [];
    // imageData = onBrowseImageState ? localImageSource : imageData;
    return (
      <>
        {imageData.map((img) =>
          img ? (
            <td style={{ padding: "22px" }}>
              <div class="containerImg">
                <Icon
                  className="close test"
                  onClick={() => onDeleteImage(img.id)}
                  style={{
                    width: "30px",
                    height: "30px",
                    position: "absolute",
                  }}
                />
                <img
                  src={
                    img.liveImage === 0
                      ? img.Source
                      : `${assets.IMAGE_URL}/inventory/${editableData.productId}/${img.invImageUrl}`
                  }
                  style={{
                    height: "180px",
                    width: "180px",
                    border: "1px solid silver",
                    boxShadow: "10px 10px 5px lightblue",
                    objectFit: "contain",
                  }}
                  srcSet={
                    img.liveImage === 0
                      ? img.Source
                      : `${assets.IMAGE_URL}/inventory/${editableData.productId}/${img.invImageUrl} 10x`
                  }
                  alt=""
                />
              </div>
            </td>
          ) : null
        )}
      </>
    );
  };

  const onBrowseImage = (e) => {
    const files = e.target.files;

    let _file = [];
    let data = [];
    //const imgCount = 0;
    Array.from(files).forEach((file) => {
      var reader = new FileReader();
      reader.onload = function (e) {
        let data1 = { Source: e.target.result };
        data = [...data, data1];
        // setLocalImageSource(data);
        editableData.itemImageData.push({
          imageId: -1,
          invImageUrl: file.name,
          Source: e.target.result,
          liveImage: 0,
        });
      };
      setforceRefreshScreen(!forceRefreshScreen);
      reader.readAsDataURL(file);

      const fileSize = file.size / 1024;

      reSizeImage(file).then((img) => {
        const fileData = globalFunction.dataURLtoFile(img, file.name);
        _file.push(fileSize > 500 ? fileData : file);
      });
    });

    // setStateOnBrowseImage(true);
    setImageState(_file);
  };
  const onhandleClose = () => {
    setShowHidePopup(false);
    window.location.reload(true);
  };
  const onBrowseThumbnailImage = (e) => {
    const files = e.target.files;

    let _file = [];
    let data = [];
    // const imgCount = 0;
    Array.from(files).forEach((file) => {
      var reader = new FileReader();
      reader.onload = function (e) {
        let data1 = { Source: e.target.result };
        data = [...data, data1];
        setThumbnailLocal(data);
      };
      reader.readAsDataURL(file);

      const fileSize = file.size / 1024;

      reSizeImage(file).then((img) => {
        const fileExt = file.name.split(".").pop();
        const fileName = `thumbnail.${fileExt}`;
        const fileData = globalFunction.dataURLtoFile(img, fileName);

        editableData.invImageUrl = fileName;
        var newFileData = [];
        newFileData.push(fileSize > 100 ? fileData : file);
        var thumbnailImg = new File(newFileData, fileName);
        _file.push(thumbnailImg);
      });
    });

    setOnBrowseThumbNailImage(true);
    setThumbnailState(_file);
  };
  const reSizeImage = async (file) => {
    try {
      const image = await globalFunction.resizeFile(file);
      return image;
    } catch (err) {
      console.log(err);
    }
  };
  const renderInvImages = () => {
    return (
      <div class="accordion" id={`accordionImgs_1`}>
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingOne">
            <button
              class="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target={`#collapseOneImgs_1`}
              aria-expanded="true"
              aria-controls={`collapseOneImgs_1`}
              style={{
                backgroundColor: "#0d6efd",
                color: "white",
                fontWeight: "bold",
              }}
            >
              Inventory Images
            </button>
          </h2>
          <div
            id={`collapseOneImgs_1`}
            class="accordion-collapse collapse show"
            aria-labelledby="headingOne"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              <div class="row">
                <div class="mb-3">
                  <input
                    class="form-control"
                    type="file"
                    id="formFileMultiple"
                    multiple
                    onChange={(e) => onBrowseImage(e)}
                  />
                </div>
              </div>
              <div class="table-responsive" style={{ maxHeight: 500 }}>
                <table class="table" id="imgTable">
                  <tbody id="imgTableBody">
                    <tr id="imgTableRow">{renderImages()}</tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const renderPopupBody = () => {
    const img = onBrowseThumbNailImage
      ? thumbnailLocal[0]
        ? thumbnailLocal[0]
        : null
      : editableData.invImageUrl;
    return (
      <>
        <div class="row">
          <div class="col">
            <div className="col form-floating">
              <input
                type="text"
                class="form-control"
                placeholder="Item Number"
                aria-label="Item Number"
                value={editableData.productId}
                id="productId"
                onChange={(e) => onchange(e)}
                readOnly
                style={{
                  backgroundColor: "#EBEBE4",
                  color: "#C0C0C0",
                  fontSize: 17,
                }}
              />
              <label for="productId" style={{ paddingTop: 15 }}>
                Product ID
              </label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div className="col form-floating">
              <input
                type="text"
                class="form-control"
                placeholder="Item Name"
                aria-label="Item Name"
                id="productName"
                value={editableData.productName}
                onChange={(e) => onchange(e)}
              />
              <label for="productName" style={{ paddingTop: 15 }}>
                Product Name
              </label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div className="col form-floating">
              <input
                type="text"
                class="form-control"
                placeholder="Description"
                aria-label="Description"
                id="productDescription"
                value={editableData.productDescription}
                onChange={(e) => onchange(e)}
              />
              <label for="productDescription" style={{ paddingTop: 15 }}>
                Description
              </label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div className="col form-floating">
              <select
                class="form-select"
                style={{ marginTop: 8, height: 46 }}
                value={editableData.categoryId}
                id="categoryId"
                onChange={(e) => onchange(e)}
              >
                <option value="-1">Choose Category</option>
                {categoryData.map((el) => (
                  <option value={el.id}>{el.category}</option>
                ))}
              </select>
              <label for="categoryId" style={{ paddingTop: 6 }}>
                Choose Category
              </label>
            </div>
          </div>
          <div class="col">
            <div className="col form-floating">
              <select
                class="form-select"
                style={{ marginTop: 8, height: 46 }}
                value={editableData.brandId}
                id="brandId"
                onChange={(e) => onchange(e)}
              >
                <option value="-1">Choose Brand</option>
                {brandData.map((el) => (
                  <option value={el.id}>{el.brandName}</option>
                ))}
              </select>
              <label for="brandId" style={{ paddingTop: 6 }}>
                Choose Brand
              </label>
            </div>
          </div>
          <div class="col">
            <div className="col form-floating">
              <select
                class="form-select"
                style={{ marginTop: 8, height: 46 }}
                value={editableData.appliancesType}
                id="appliancesType"
                onChange={(e) => onchange(e)}
              >
                <option value="-1">Choose Type of Appliances</option>
                {appliancesType.map((el) => (
                  <option value={el.id}>{el.description}</option>
                ))}
              </select>
              <label for="appliancesType" style={{ paddingTop: 6 }}>
                Choose Appliances Type
              </label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div className="col form-floating">
              <input
                type="text"
                class="form-control"
                id="mrp"
                placeholder="MRP"
                value={editableData.mrp}
                onChange={(e) => onchange(e)}
                //onKeyDown={(e) => validateNumber(e)}
              />
              <label for="mrp" style={{ paddingTop: 15 }}>
                MRP
              </label>
            </div>
          </div>
          <div class="col">
            <div className="col form-floating">
              <input
                type="text"
                class="form-control"
                id="salesPrice"
                placeholder="Sales Price"
                value={editableData.salesPrice}
                onChange={(e) => onchange(e)}
                //onKeyDown={(e) => validateNumber(e)}
              />
              <label for="salesPrice" style={{ paddingTop: 15 }}>
                Sales Price
              </label>
            </div>
          </div>
          <div class="col">
            <div className="col form-floating">
              <input
                type="text"
                class="form-control"
                id="GSTPer"
                placeholder="GST (%)"
                value={editableData.GSTPer}
                onChange={(e) => onchange(e)}
                //onKeyDown={(e) => validateNumber(e)}
              />
              <label for="GSTPer" style={{ paddingTop: 15 }}>
                GST (%)
              </label>
            </div>
          </div>
          <div class="col">
            <div className="col form-floating">
              <input
                type="text"
                class="form-control"
                id="sku"
                placeholder="SKU"
                value={editableData.sku}
                onChange={(e) => onchange(e)}
              />
              <label for="sku" style={{ paddingTop: 15 }}>
                SKU
              </label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div className="col form-floating">
              <input
                type="text"
                class="form-control"
                id="qty"
                placeholder="In Stock"
                value={editableData.qty}
                onChange={(e) => onchange(e)}
              />
              <label for="qty" style={{ paddingTop: 15 }}>
                In Stock
              </label>
            </div>
          </div>
          <div class="col">
            <div className="col form-floating">
              <input
                type="text"
                class="form-control"
                id="committed"
                placeholder="Committed"
                value={editableData.committed}
                onChange={(e) => onchange(e)}
                disabled
              />
              <label for="committed" style={{ paddingTop: 15 }}>
                Committed
              </label>
            </div>
          </div>
          <div class="col">
            <div className="col form-floating">
              <input
                type="text"
                class="form-control"
                id="available"
                placeholder="Available"
                value={editableData.qty - editableData.committed}
                //onChange={(e) => onchange(e)}
                disabled
              />
              <label for="committed" style={{ paddingTop: 15 }}>
                Available
              </label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div className="col form-floating">
              <input
                type="text"
                class="form-control"
                id="urlLiveLink"
                placeholder="Url (Link to live)"
                value={editableData.urlLiveLink}
                onChange={(e) => onchange(e)}
              />
              <label for="urlLiveLink" style={{ paddingTop: 15 }}>
                Link To Brand URL
              </label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <table class="table-responsive">
              <tbody>
                <tr>
                  <td class="col">
                    <div className="col form-floating">
                      <input
                        type="text"
                        class="form-control"
                        id="hsnCode"
                        placeholder="HSN Code"
                        value={editableData.hsnCode}
                        onChange={(e) => onchange(e)}
                      />
                      <label for="hsnCode" style={{ paddingTop: 15 }}>
                        HSN Code
                      </label>
                    </div>
                  </td>
                  <td class="col">
                    <div class="form-check form-switch">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        role="switch"
                        id="active"
                        style={{ marginLeft: 10 }}
                        checked={
                          parseInt(editableData.active) === 1 ? true : false
                        }
                        onChange={(e) => onchange(e)}
                      />
                    </div>
                  </td>
                  <td>
                    <label
                      class="form-check-label"
                      for="flexSwitchCheckDefault"
                      style={{ paddingLeft: 5 }}
                    >
                      Active
                    </label>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          {/* <div className="col form-floating">
            <input
              type="text"
              class="form-control"
              id="maxReturnDays"
              placeholder="Return Days"
              value={editableData.maxReturnDays}
              onChange={(e) => onchange(e)}
            />
            <label
              for="maxReturnDays"
              style={{ paddingLeft: 20, paddingTop: 15 }}
            >
              Max Return Days
            </label>
          </div> */}
        </div>
        <div class="row">
          <div class="col">
            <div class="input-group mt-3">
              <label class="input-group-text">Choose Thumbnail</label>
              <input
                class="form-control"
                type="file"
                id="thumbnailImage"
                onChange={(e) => onBrowseThumbnailImage(e)}
              />

              {img !== "" && img !== null ? (
                <img
                  src={
                    onBrowseThumbNailImage
                      ? img.Source
                      : `${assets.IMAGE_URL}/inventory/${editableData.productId}/${img}`
                  }
                  style={{
                    height: "33px",
                    width: "33px",
                    border: "1px solid silver",
                    boxShadow: "10px 10px 5px lightblue",
                    objectFit: "contain",
                    marginLeft: 3,
                  }}
                  srcSet={
                    onBrowseThumbNailImage
                      ? img.Source
                      : `${assets.IMAGE_URL}/inventory/${editableData.productId}/${img} 10x`
                  }
                  alt=""
                  onClick={(e) => onHandleZoom(e)}
                  id="thumbnailImg"
                />
              ) : null}
            </div>
          </div>
        </div>
        <div class="row" id="invImages" style={{ marginTop: 5 }}>
          {renderInvImages()}
        </div>
        <div class="row" id="propertyAccord">
          {renderPropertyAccordion()}
        </div>
        {/* <div class="row" id="InvOfferAccord">
          {renderInvOfferAccordion()}
        </div> */}
      </>
    );
  };
  const renderAddEditPopup = () => {
    return (
      <div
        class="modal"
        tabindex="-1"
        style={showPopup ? { display: "block" } : {}}
        id="dataEntry"
      >
        <div class="modal-dialog modal-lg modal-dialog-scrollable">
          <div class="modal-content">
            <div
              class="modal-header"
              style={{ backgroundColor: "black", color: "white" }}
            >
              <h5 class="modal-title">{popupTitle}</h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => showHidePopup(false)}
                style={{ backgroundColor: "#ff0000" }}
              ></button>
            </div>
            <div class="modal-body">{renderPopupBody()}</div>
            <div class="modal-footer">
              <div class="row" style={{ width: "100%" }}>
                <div class="col">
                  <button
                    type="button"
                    class="btn btn-primary"
                    onClick={() => {
                      onSave();
                    }}
                  >
                    Save changes
                  </button>
                </div>
                <div class="col">
                  <button
                    type="button"
                    class="btn btn-secondary"
                    data-bs-dismiss="modal"
                    onClick={() => onhandleClose()}
                  >
                    Close
                  </button>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div
                    style={
                      showValidationMessage === true
                        ? { display: "block", color: "red" }
                        : { color: "red", display: "none" }
                    }
                  >
                    {errorMessage}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const renderImportError = () => {
    return (
      <div class="modal" style={showErrorPopup ? { display: "block" } : {}}>
        <div class="modal-dialog modal-lg modal-dialog-scrollable">
          <div class="modal-content">
            <div
              class="modal-header"
              style={{ backgroundColor: "black", color: "white" }}
            >
              <h5 class="modal-title">{popupTitle}</h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => setShowErrorPopUp(false)}
                style={{ backgroundColor: "#ff0000" }}
              ></button>
            </div>
            <div class="modal-body">
              <table class="table table-striped table-hover table-bordered ">
                <thead>
                  <tr className="table-dark">
                    <th scope="col-xs">Product ID</th>
                    <th scope="col-xs">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {importError.map((w) => {
                    return (
                      <tr>
                        <td>{w.productId}</td>
                        <td>{w.status}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const uploadImageBatch = (files) => {
    return new Promise(function (resolve, reject) {
      const importResult = [];
      Array.from(files).forEach((file) => {
        let fileSize = file.size / 1024;
        let product = file.name.substring(0, file.name.indexOf("_"));
        reSizeImage(file).then((img) => {
          const fileData = globalFunction.dataURLtoFile(img, file.name);

          let _file = fileSize > 500 ? fileData : file;
          apiHandler
            .uploadFile(
              _file,
              `/inventory/${product}`,
              "inventoryImport",
              product,
              loginInfo.id
            )
            .then((el) => {
              importResult.push(el);
            });
        });
      });
      const errorList = importResult.filter((err) => err.status !== "Success");
      if (errorList && errorList.length > 0) {
        setImportError(errorList);
        setShowErrorPopUp(true);
      }
      return resolve({ status: "Success" });
    });
  };
  const uploadBatchThumbnail = (files) => {
    return new Promise(function (resolve, reject) {
      const importResult = [];
      Array.from(files).forEach((file) => {
        let fileSize = file.size / 1024;
        let product = file.name.substring(0, file.name.indexOf("_"));

        reSizeImage(file).then((img) => {
          const fileExt = file.name.split(".").pop();
          const fileName = `thumbnail.${fileExt}`;
          const fileData = globalFunction.dataURLtoFile(img, fileName);
          //let _file = fileSize > 500 ? fileData : file;
          let _file = fileData;
          apiHandler
            .uploadFile(
              _file,
              `/inventory/${product}`,
              "batchThumbnailImport",
              product,
              loginInfo.id
            )
            .then((el) => {
              importResult.push(el);
            });
        });
      });
      const errorList = importResult.filter((err) => err.status !== "Success");
      if (errorList && errorList.length > 0) {
        setImportError(errorList);
        setShowErrorPopUp(true);
      }
      return resolve({ status: "Success" });
    });
  };
  const importData = (prm) => {
    let input = document.createElement("input");
    input.type = "file";
    if (prm === "InventoryImages") input.multiple = true;
    input.accept =
      prm === "InventoryImages"
        ? "image/png, image/gif, image/jpeg"
        : ".xls,.xlsx";
    input.title =
      prm === "InventoryImages"
        ? "Select Images to upload.."
        : "Select Excel File...";
    console.log("inputX", input);
    input.onchange = async (_) => {
      let files = Array.from(input.files);
      if (files.length > 0) {
        if (prm === "InventoryImages") {
          setTimeout(() => {
            uploadImageBatch(files).then((importResult) => {
              if (importResult.status === "Success") {
                showMessage(true);
                setMessage("Images Imported Successfully!");
              } else {
                showMessage(true);
                setMessage("Failed to Imported Images!");
              }
            });
            setTimeout(() => {
              showMessage(false);
              setShowConfirmationPopup(false);
            }, 1000);
          }, 500);
        } else {
          globalFunction.importDataFromExcel(files[0]).then((data) => {
            if (data.length > 0) {
              apiHandler
                .importInventory(
                  Object.assign(
                    {},
                    { [prm]: data, type: prm, userId: loginInfo.id }
                  )
                )
                .then((result) => {
                  const allItemImported = result.filter(
                    (w) => w.status === "Success"
                  );
                  const failedToImport = result.filter(
                    (w) => w.status !== "Success"
                  );
                  if (data.length !== allItemImported.length) {
                    setImportError(failedToImport);
                    setShowErrorPopUp(true);
                    showMessage(true);
                    setMessage("Data Imported Successfully, with exceptions!");
                    // let items = "Following Items failed to import...";
                  } else {
                    showMessage(true);
                    setMessage("Data Imported Successfully!");
                  }
                });
              setTimeout(() => showMessage(false), 1500);

              console.log("importedData", data);
            }
          });
        }
      }
    };
    input.click();
  };
  const importThumbnail = () => {
    console.log("SSS");
    let input = document.createElement("input");
    input.type = "file";
    input.multiple = true;
    input.accept = "image/png, image/gif, image/jpeg";

    input.title = "Select Images to upload..";

    console.log("inputX", input);
    input.onchange = async (_) => {
      let files = Array.from(input.files);
      if (files.length > 0) {
        setTimeout(() => {
          uploadBatchThumbnail(files).then((importResult) => {
            if (importResult.status === "Success") {
              showMessage(true);
              setMessage("Images Imported Successfully!");
            } else {
              showMessage(true);
              setMessage("Failed to Imported Images!");
            }
          });
          setTimeout(() => {
            showMessage(false);
          }, 1000);
        }, 500);
      }
    };
    input.click();
  };
  const onSuccess = () => {
    if (confirmationBoxOpenSource === "delete") {
      onDelete();
    } else if (confirmationBoxOpenSource === "ImportImage") {
      importData("InventoryImages");
    }
  };
  const renderMessageText = () => {
    return (
      <div>
        Please make sure image name should be in following format
        <br />
        <span>
          <strong>P0001_10.jpg</strong>
        </span>
        <br />
      </div>
    );
  };
  const onCanel = () => {
    setShowConfirmationPopup(false);
  };
  const renderConfirmationMessageBox = () => {
    //const message = renderMessageText();
    return (
      <div>
        <ConfirmationBox
          onCancel={() => {
            onCanel();
          }}
          show={showConfirmationPopup}
          message={messageText}
          onSuccess={() => onSuccess()}
          width="30%"
        />
      </div>
    );
  };
  const onOpenConfirmationDialog = (source) => {
    let message = "";
    setConfirmationBoxOpenSource(source);
    if (source === "ImportImage") {
      message = renderMessageText();
      setMessage(message);
    } else if (source === "delete") {
      message = "Are you sure, you want to delete the selected item?";
      setMessage(message);
    }
    setShowConfirmationPopup(true);
  };
  const renderShowAllSwitch = () => {
    return (
      <div>
        <input
          type="checkbox"
          role="switch"
          id="active"
          style={{ marginLeft: 10 }}
          onChange={(e) => ShowAllInventory(e)}
          class="form-check-input"
          ref={contextRef}
        />
        <label className="lblShowAll">Show All(Active/Inactive both)</label>
      </div>
    );
  };
  const onHandleZoom = (e) => {
    const imgSrc = document.getElementById("thumbnailImg").src;
    setShowZoomPopup(true);
    setThumbnailImg(imgSrc);
  };
  const renderZoomThumbnail = () => {
    return (
      <div
        class="modal"
        tabindex="-1"
        style={showZoomPopup ? { display: "block", top: 100 } : {}}
        id="dataEntry"
      >
        <div class="modal-dialog modal-lg modal-dialog-scrollable">
          <div class="modal-content">
            <div
              class="modal-header"
              style={{ backgroundColor: "black", color: "white" }}
            >
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => setShowZoomPopup(false)}
                style={{ backgroundColor: "#ff0000" }}
              ></button>
            </div>
            <div class="modal-body">
              <img src={thumbnailImg} style={{ height: 400, width: 400 }} />
            </div>
            <div class="modal-footer">
              <div class="row" style={{ width: "100%" }}>
                <div class="col">
                  <button
                    type="button"
                    class="btn btn-secondary"
                    data-bs-dismiss="modal"
                    onClick={() => setShowZoomPopup(false)}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const onFilterData = (e) => {
    const fld = e.target.id;
    const val = e.target.value;
    let data = filterData;
    data[fld] = val;
    setFilterData(data);
    setApplyFilter(!applyFilter);
    let searchData = [];
  };
  const renderFilterRow = () => {
    return (
      <div
        id="filterRow"
        class="container"
        style={{
          position: "fixed",
          top: 225,

          backgroundColor: "black",
          width: "84.5%",
        }}
      >
        <tr>
          <th className="col-xs">
            <div style={{ marginTop: -35, width: 20, color: "white" }}>#</div>
          </th>
          <th className="col-xs">
            <div style={{ marginTop: -35, width: 20, color: "white" }}>#</div>
          </th>
          <th scope="col" style={{ width: "9%" }}>
            <input
              type="text"
              class="form-control"
              placeholder="Product ID"
              id="productId"
              value={filters.productId}
              onChange={(e) => onFilterData(e)}
              style={{
                height: "37px",
                fontSize: 12,
                // width: "10%",
              }}
            />
          </th>
          <th scope="col" style={{ width: "22.2%" }}>
            <input
              type="text"
              class="form-control"
              placeholder="Description"
              id="productName"
              value={filters.productName}
              onChange={(e) => onFilterData(e)}
              style={{
                height: "37px",
                fontSize: 12,
              }}
            />
          </th>
          <th scope="col" style={{ width: "7.5%" }}>
            <select
              class="form-select"
              id="categoryId"
              onChange={(e) => onFilterData(e)}
              style={{
                height: "37px",
                marginTop: -45,
                fontSize: 12,
                // marginLeft: -20,
              }}
              value={filters.categoryId}
            >
              <option value="-1">Choose Category</option>
              {categoryData.map((el) => (
                <option value={el.id}>{el.category}</option>
              ))}
            </select>
          </th>
          <th scope="col" style={{ width: "7.5%" }}>
            <select
              class="form-select"
              style={{
                height: "37px",
                marginTop: -45,
                fontSize: 12,
                // marginLeft: -20,
              }}
              id="brandId"
              value={filters.brandId}
              onChange={(e) => onFilterData(e)}
            >
              <option value="-1">Choose Brand</option>
              {brandData.map((el) => (
                <option value={el.id}>{el.brandName}</option>
              ))}
            </select>
          </th>
          <th scope="col" style={{ width: "7.2%" }}>
            <input
              type="text"
              class="form-control"
              placeholder="MRP"
              id="mrp"
              value={filters.mrp}
              onChange={(e) => onFilterData(e)}
              style={{
                height: "37px",
                fontSize: 12,
                // marginLeft: -40,
              }}
            />
          </th>
          <th scope="col" style={{ width: "7.2%" }}>
            <input
              type="text"
              class="form-control"
              placeholder="Sales Price"
              id="salesPrice"
              value={filters.salesPrice}
              onChange={(e) => onFilterData(e)}
              style={{
                height: "37px",
                fontSize: 12,
                // marginLeft: -40,
              }}
            />
          </th>
          <th scope="col" style={{ width: "5%" }}>
            <input
              type="text"
              class="form-control"
              placeholder="In Stock"
              id="qty"
              value={filters.qty}
              onChange={(e) => onFilterData(e)}
              style={{
                height: "37px",
                fontSize: 12,
                // marginLeft: -40,
              }}
            />
          </th>
          <th scope="col" style={{ width: "4.5%" }}>
            <input
              type="text"
              class="form-control"
              placeholder="Committed"
              id="committed"
              value={filters.committed}
              onChange={(e) => onFilterData(e)}
              style={{
                height: "37px",
                fontSize: 12,
                // marginLeft: -40,
              }}
            />
          </th>
          <th scope="col" style={{ width: "5%" }}>
            <input
              type="text"
              class="form-control"
              placeholder="Avail"
              id="avail"
              value={filters.avail}
              onChange={(e) => onFilterData(e)}
              style={{
                height: "37px",
                fontSize: 12,
                // marginLeft: -40,
              }}
            />
          </th>
          <th scope="col" style={{ width: "9%" }}>
            <input
              type="text"
              class="form-control"
              placeholder="SKU"
              id="sku"
              value={filters.sku}
              onChange={(e) => onFilterData(e)}
              style={{
                height: "37px",
                fontSize: 12,
                // marginLeft: -40,
              }}
            />
          </th>
          <th scope="col" style={{ width: "8%" }}>
            <select
              class="form-select"
              style={{
                height: "37px",
                marginTop: -45,
                fontSize: 12,
              }}
              value={filters.appliancesType}
              id="appliancesType"
              onChange={(e) => onFilterData(e)}
            >
              <option value="-1">Choose Type</option>
              {appliancesType.map((el) => (
                <option value={el.id}>{el.description}</option>
              ))}
            </select>
          </th>
          <th scope="col" style={{ width: "4.5%" }}>
            <input
              type="text"
              class="form-control"
              placeholder="GST%"
              id="GSTPer"
              value={filters.GSTPer}
              onChange={(e) => onFilterData(e)}
              style={{
                height: "37px",
                fontSize: 12,
                justifyContent: "left",
              }}
            />
          </th>
        </tr>
      </div>
    );
  };
  return (
    <>
      {/* <Confirm
        open={openConfirmationBox}
        onCancel={handleCancel}
        content="Are you sure, you want to delete the user?"
        header="Confirmation"
        onConfirm={onDelete}
        size="tiny"
        style={{ height: "30%", top: "10%", left: "35%" }}
      /> */}

      <div className="container">
        <div style={{ width: "100%", paddingBottom: 5, height: 35 }}>
          <button
            type="button"
            class="btn btn-primary btn-md"
            onClick={() => onAddNew()}
            style={{ width: 100, float: "left" }}
          >
            Add New
          </button>
          <div class="dropdown" style={{ float: "right" }}>
            <button
              class="btn btn-secondary dropdown-toggle btn btn-danger"
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Import Excel Data
            </button>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              <div style={{ display: "flex", padding: "1%" }}>
                <img
                  src={assets.excelExport}
                  style={{ height: 20, width: 30 }}
                  alt=""
                />
                <li style={{ marginTop: "-2%" }}>
                  {/* <a
                    class="dropdown-item"
                    href="#"
                    onClick={() => importData("Inventory")}
                  >
                    Inventory
                  </a> */}
                  <input
                    type="button"
                    id="inventoryImport"
                    value={"Inventory"}
                    onClick={() => importData("Inventory")}
                    style={{
                      backgroundColor: "white",
                      border: "none",
                      width: 210,
                      textAlign: "left",
                      marginTop: 5,
                    }}
                  />
                </li>
              </div>
              <div
                style={{
                  display: "flex",
                  paddingTop: "3%",
                  paddingBottom: "2%",
                }}
              >
                <img
                  src={assets.excelExport}
                  style={{ height: 20, width: 30 }}
                  alt=""
                />
                <li style={{ marginTop: "-2%" }}>
                  {/* <a
                    class="dropdown-item"
                    href="#"
                    onClick={() => importData("Properties")}
                  >
                    Inventory Properties
                  </a> */}
                  <input
                    type="button"
                    id="inventoryProperties"
                    value={"Inventory Properties"}
                    onClick={() => importData("Properties")}
                    style={{
                      backgroundColor: "white",
                      border: "none",
                      width: 210,
                      textAlign: "left",
                      marginTop: 5,
                    }}
                  />
                </li>
              </div>
              <div style={{ display: "flex", padding: "1%" }}>
                <img
                  src={assets.excelExport}
                  style={{ height: 20, width: 30 }}
                  alt=""
                />
                <li style={{ marginTop: "-2%" }}>
                  <input
                    type="button"
                    id="inventoryImages"
                    value={"Inventory Image"}
                    onClick={() => onOpenConfirmationDialog("ImportImage")}
                    style={{
                      backgroundColor: "white",
                      border: "none",
                      width: 210,
                      textAlign: "left",
                      marginTop: 5,
                    }}
                  />
                </li>
              </div>
              <div style={{ display: "flex", padding: "1%" }}>
                <img
                  src={assets.excelExport}
                  style={{ height: 20, width: 30 }}
                  alt=""
                />
                <li style={{ marginTop: "-2%" }}>
                  <input
                    type="button"
                    id="inventoryThumbnailImport"
                    value={"Thumbnail"}
                    onClick={() => importThumbnail()}
                    style={{
                      backgroundColor: "white",
                      border: "none",
                      width: 210,
                      textAlign: "left",
                      marginTop: 5,
                    }}
                  />
                </li>
              </div>
            </ul>
          </div>
          <div class="form-check form-switch" style={{ width: 480 }}>
            <Popup
              content={"Show All(Active/Inactive both)"}
              trigger={renderShowAllSwitch()}
              size="mini"
              inverted
              position="top center"
              context={contextRef}
            />
          </div>
        </div>
        <div
          class="table-responsive"
          style={{ maxHeight: 515, marginTop: 90, width: "100%" }}
        >
          <table class="table table-striped table-hover table-bordered ">
            <thead>{renderFilterRow()}</thead>
            <tbody>{renderGrid()}</tbody>
          </table>
        </div>
      </div>
      {renderAddEditPopup()}
      {renderImportError()}

      <TransitionablePortal open={open} transition={{ animation, duration }}>
        <Segment id="transationMessage">
          <Header>Information</Header>
          <p>{messageText}</p>
        </Segment>
      </TransitionablePortal>

      <Loader showLoader={showLoader} />
      {renderConfirmationMessageBox()}
      {renderZoomThumbnail()}
    </>
  );
};

export default Inventory;
