import React, { useEffect, useState } from "react";
import assets from "../assets/assets";
import apiHandler from "../commonfunction/data";
import Loader from "../components/Loader";
import { useNavigate } from "react-router-dom";

const ActivityTracker = () => {
  const [globaldata, setData] = useState([{}]);
  const [showLoader, setShowLoader] = useState(true);
  const [loginInfo, setLoginInfo] = useState();
  const [open, showMessage] = useState(false);
  const navigation = useNavigate();

  useEffect(() => {
    // setShowLoader(true);
    const loginData = JSON.parse(
      apiHandler.getDataFromLocalStorage("loginDetails")
    );
    setLoginInfo(loginData);
    if (
      loginData === null ||
      loginData.userName === "" ||
      loginData.password === ""
    ) {
      navigation("/");
    }
    setTimeout(() => {
      apiHandler.getActivityLog().then((data) => {
        //console.log("activitylog", data);
        setData(data);
        setShowLoader(false);
      });
    }, 1000);
  }, [open]);
  const renderGrid = () => {
    // console.log("globaldata", globaldata);
    return globaldata.map((w, counter) => {
      return (
        <tr className="table-info" id={w.id}>
          <td className="col-xs">{counter + 1}</td>
          <td>{w.timestamp}</td>
          <td>{w.userName}</td>
          <td>{w.actionName}</td>
          <td>{w.screenName}</td>
        </tr>
      );
    });
  };
  return (
    <>
      <div class="table-responsive" style={{ maxHeight: 540 }}>
        <table class="table table-striped table-hover table-bordered ">
          <thead>
            <tr className="table-dark">
              <th scope="col-xs">#</th>
              <th scope="col">Date</th>
              <th scope="col">User Name</th>
              <th scope="col">Action</th>
              <th scope="col">Screen Name</th>
            </tr>
          </thead>
          <tbody>{renderGrid()}</tbody>
        </table>
      </div>
      <Loader showLoader={showLoader} />
    </>
  );
};

export default ActivityTracker;
