import React, { useState, useRef } from "react";
// import { Icon } from "semantic-ui-react";
import apiHandler from "../commonfunction/data";
import assets from "../assets/assets";
import { useEffect } from "react";
import moment from "moment";
import "../assets/css/Switch.css";
import ConfirmationBox from "../components/ConfirmationBox";
import Loader from "../components/Loader";
import { useNavigate } from "react-router-dom";
import globalFunction from "../commonfunction/globalFunction";
import {
  TransitionablePortal,
  Segment,
  Header,
  Dropdown,
  Input,
  Popup,
  Icon,
} from "semantic-ui-react";
import _ from "lodash";
const actionButtonStyle = {
  width: 20,
};
const initialData = {
  id: 0,
  brandId: -1,
  offerTitle: "",
  startDate: moment(new Date()).format("MM/DD/yyyy"),
  endDate: moment(new Date()).format("MM/DD/yyyy"),
  offerThumbnail: "",
  isDealOfDay: false,
  isHotDeal: false,
  isStarBrand: false,
  isComboOffer: false,
  offerDiscount: 0,
  userId: -1,
  notificationTitle: "",
  notification: "",
  LinkingFound: 0,
  active: 1,
};
const style = { marginTop: 8, height: 50 };
const SaleOfferSetup = (props) => {
  const [offerData, setData] = useState([{}]);
  const [showValidationMessage, setShowValidationMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [showPopup, setShowHidePopup] = useState(false);
  const [isEdit, setEdit] = useState(false);
  const [popupTitle, setTitle] = useState("Configure offer");
  const [editableData, setEditData] = useState(initialData);
  const [brandData, setBrandData] = useState([{}]);
  const [categoryData, setCategoryData] = useState([{}]);
  const [minDate, setMinDate] = useState(moment().format("yyyy-MM-DD"));
  const [ShowConfirmatonMsgBox, setShowConfirmatonMsgBox] = useState(false);
  const [selectedOfferId, setSelectedOfferId] = useState(0);
  const [showLoader, setShowLoader] = useState(false);
  const [loginInfo, setLoginInfo] = useState();
  const [messageText, setMessage] = useState();
  const [imageData, setImageData] = useState();
  const [open, showMessage] = useState(false);
  const [animation, setAnimation] = useState(assets.transitions[12]);
  const [duration, setduration] = useState(800);
  const [showLinkingPopup, setShowLinkingPopup] = useState(false);
  const [showInventoryPopup, setShowInventoryPopup] = useState(false);
  const [showChildInvPopup, setShowChildInvPopup] = useState(false);
  const [IsComboOffer, setIsComboOffer] = useState(false);
  const [offerDetailData, setOfferDetailData] = useState([{}]);
  const [invData, setInvData] = useState([{}]);
  const [childData, setChildData] = useState([]);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [selectedBrandsChildInv, setSelectedBrandsChildInv] = useState([]);
  const [selectedCategoryChildInv, setSelectedCategoryChildInv] = useState([]);
  const [gblOfferDetailData, setGlobalOfferDetailData] = useState([{}]);
  const [forceRefreshScreen, setforceRefreshScreen] = useState(false);
  const [editSP, setEditSP] = useState(false);
  const [editDisc, setEditDisc] = useState(false);
  const [applyFilter, setApplyFilter] = useState(false);
  const [applyFilterChildInv, setApplyFilterChildInv] = useState(false);
  const [brandOnOffer, setBrandOnOffer] = useState(false);
  const [allOffer, setAllOffer] = useState([{}]);
  const [switchChecked, setSwitchChecked] = useState(false);
  const [parentItemId, setParentItemId] = useState(0);
  const navigation = useNavigate();
  const contextRef = useRef();
  useEffect(() => {
    setShowLoader(true);
    //props.showTitle("Configure offers");
    const loginData = JSON.parse(
      apiHandler.getDataFromLocalStorage("loginDetails")
    );
    setLoginInfo(loginData);
    if (
      loginData === null ||
      loginData.userName === "" ||
      loginData.password === ""
    ) {
      navigation("/");
    }
    setTimeout(() => {
      apiHandler.getBrandData().then((brands) => {
        setBrandData(brands);
        apiHandler.getSalesOffers().then((offers) => {
          const activeOffer = offers.filter((w) => w.active == 1);
          setData(activeOffer);
          setAllOffer(offers);
          setShowLoader(false);
        });
      });
    }, 1000);
  }, [open]);
  useEffect(
    () => {
      let brandKeys = [];
      let categoryKeys = [];
      let keyData = [];
      keyData = brandData.filter((o) => selectedBrands.includes(o.brandName));
      keyData.map((el) => brandKeys.push(el.id));
      keyData = categoryData.filter((o) =>
        selectedCategory.includes(o.category)
      );
      keyData.map((el) => categoryKeys.push(el.id));
      let filteredData = gblOfferDetailData;

      if (brandKeys.length > 0 && categoryKeys.length > 0) {
        filteredData = gblOfferDetailData.filter(
          (el) =>
            brandKeys.includes(el.brandId) &&
            categoryKeys.includes(el.categoryId)
        );
      } else if (brandKeys.length > 0 && categoryKeys.length <= 0) {
        filteredData = gblOfferDetailData.filter((el) =>
          brandKeys.includes(el.brandId)
        );
      } else if (brandKeys.length <= 0 && categoryKeys.length > 0) {
        filteredData = gblOfferDetailData.filter((el) =>
          categoryKeys.includes(el.categoryId)
        );
      }
      if (brandOnOffer && brandOnOffer > 0) {
        filteredData = filteredData.filter((w) => w.brandId == brandOnOffer);
      }
      setOfferDetailData(filteredData);
      setforceRefreshScreen(!forceRefreshScreen);
    },
    [applyFilter],
    [selectedBrands],
    [selectedCategory]
  );
  useEffect(
    () => {
      let brandKeys = [];
      let categoryKeys = [];
      let keyData = [];
      keyData = brandData.filter((o) =>
        selectedBrandsChildInv.includes(o.brandName)
      );
      keyData.map((el) => brandKeys.push(el.id));
      keyData = categoryData.filter((o) =>
        selectedCategoryChildInv.includes(o.category)
      );
      keyData.map((el) => categoryKeys.push(el.id));
      let filteredData = gblOfferDetailData;

      if (brandKeys.length > 0 && categoryKeys.length > 0) {
        filteredData = gblOfferDetailData.filter(
          (el) =>
            brandKeys.includes(el.brandId) &&
            categoryKeys.includes(el.categoryId)
        );
      } else if (brandKeys.length > 0 && categoryKeys.length <= 0) {
        filteredData = gblOfferDetailData.filter((el) =>
          brandKeys.includes(el.brandId)
        );
      } else if (brandKeys.length <= 0 && categoryKeys.length > 0) {
        filteredData = gblOfferDetailData.filter((el) =>
          categoryKeys.includes(el.categoryId)
        );
      }
      if (brandOnOffer && brandOnOffer > 0) {
        filteredData = filteredData.filter((w) => w.brandId == brandOnOffer);
      }
      setInvData(filteredData);
      setforceRefreshScreen(!forceRefreshScreen);
    },
    [applyFilterChildInv],
    [selectedBrands],
    [selectedCategory]
  );
  useEffect(
    () => {
      if (editSP === true) {
        offerDetailData.map((el) => {
          let discPercent = _.round(
            ((el.mrp - el.salesPrice) / (el.mrp === 0 ? 1 : el.mrp)) * 100,
            2
          );
          el.discountPercent =
            discPercent > 0 ? discPercent : el.discountPercent;
        });
      }
      if (editDisc) {
        offerDetailData.map((el) => {
          let SP = _.round(el.mrp - el.mrp * (el.discountPercent * 0.01), 2);
          el.salesPrice = SP > 0 ? SP : el.salesPrice;
        });
      }
      setOfferDetailData(offerDetailData);
      if (editSP === true) {
        setforceRefreshScreen(!forceRefreshScreen);
      }
      if (editDisc === true) setforceRefreshScreen(!forceRefreshScreen);
    },
    [forceRefreshScreen],
    [offerDetailData]
  );
  useEffect(
    () => {
      if (editSP === true) {
        invData.map((el) => {
          let discPercent = _.round(
            ((el.mrp - el.salesPrice) / (el.mrp === 0 ? 1 : el.mrp)) * 100,
            2
          );
          el.discountPercent =
            discPercent > 0 ? discPercent : el.discountPercent;
        });
      }
      if (editDisc) {
        invData.map((el) => {
          let SP = _.round(el.mrp - el.mrp * (el.discountPercent * 0.01), 2);
          el.salesPrice = SP > 0 ? SP : el.salesPrice;
        });
      }
      setInvData(invData);
      if (editSP === true) {
        setforceRefreshScreen(!forceRefreshScreen);
      }
      if (editDisc === true) setforceRefreshScreen(!forceRefreshScreen);
    },
    [forceRefreshScreen],
    [invData]
  );
  // useEffect(() => {
  //   console.log("parentItemId", parentItemId);
  //   const data = invData.filter((w) => w.itemId !== parentItemId);
  //   setInvData(data);
  //   setforceRefreshScreen(!forceRefreshScreen);
  // }, [showChildInvPopup]);
  const ShowConfirmaton = (id) => {
    setMessage("Are you sure you want to delete?");
    setSelectedOfferId(id);
    setShowConfirmatonMsgBox(true);
    setforceRefreshScreen(!forceRefreshScreen);
  };
  const onEdit = (id) => {
    const data = offerData.filter((w) => w.id === id);
    console.log("data", data);
    setEditData(data[0]);
    setEdit(true);
    setIsComboOffer(parseInt(data[0].isComboOffer) == 1 ? true : false);
    showHidePopup(true);
  };
  const onAddNew = () => {
    setEdit(false);
    showHidePopup(true);
    setEditData(initialData);
  };
  const validateInput = () => {
    if (editableData.offerTitle === "" || editableData.offerTitle === null) {
      setErrorMessage("Please enter offer title..");
      setShowValidationMessage(true);
      return false;
    }
    if (editableData.startDate === "" || editableData.startDate === null) {
      setErrorMessage("Please select start Date of the offer..");
      setShowValidationMessage(true);
      return false;
    }
    if (editableData.endDate === "" || editableData.endDate === null) {
      setErrorMessage("Please select end date of the offer..");
      setShowValidationMessage(true);
      return false;
    }
    return true;
  };
  const onSave = () => {
    const data = {
      ...editableData,
      ...{ entryStatus: isEdit ? "update" : "new" },
      ...{ userId: loginInfo.id },
    };
    setShowLoader(true);
    showMessage(false);
    if (!validateInput()) return;
    setTimeout(() => {
      apiHandler.saveSalesOffer(data).then((result) => {
        const file = imageData;
        if (result[0].result === "Success") {
          setMessage("Data Saved Successfully!");
          if (file) {
            reSizeImage(file).then((img) => {
              const fileData = globalFunction.dataURLtoFile(img, file.name);
              let fileSize = file.size / 1024;
              let _file = fileSize > 500 ? fileData : file;
              apiHandler
                .uploadFile(
                  _file,
                  `/salesOffers`,
                  "salesOffers",
                  editableData.id,
                  loginInfo.id
                )
                .then((el) => {
                  if (el[0].status === "Success") {
                    setMessage("File Uploaded Successfully!");
                  } else {
                    setMessage("File upload failed!");
                  }
                });
            });
          }
        } else {
          setMessage("Failed to save data!");
        }
        setShowLoader(false);
        showMessage(true);
        setShowHidePopup(false);
        setTimeout(() => showMessage(false), 1200);
      });
    }, 200);
  };
  const onSaveLinking = () => {
    // let tempData = offerDetailData.filter(
    //   (el) => el.selected == 1 && parseInt(el.parentId) === 0
    //   // parseInt(el.salesOfferId) == selectedOfferId &&
    //   // el.comboOfferParent === 1
    // );
    let tempData = offerDetailData.filter(
      (el) => el.selected == 1
      // parseInt(el.salesOfferId) == selectedOfferId &&
      // el.comboOfferParent === 1
    );
    // let tempChildData = invData.filter(
    //   (w) => parseInt(w.selected) === 1 && parseInt(w.comboOfferParent) == 0
    // );
    // if (childData.length > 0) {
    //   tempChildData = childData;
    // }
    // if (IsComboOffer) {
    //   tempChildData.map((el) => {
    //     tempData.push(el);
    //   });
    // }

    const data = {
      ...{ offerDetailData: tempData },
      ...{ entryStatus: isEdit ? "update" : "new" },
      ...{ userId: loginInfo.id },
      ...{ offerId: selectedOfferId },
    };
    setShowLoader(true, tempData);
    showMessage(false);
    setTimeout(() => {
      apiHandler.saveLinkOfferWithInventory(data).then((result) => {
        const file = imageData;
        if (result[0].result === "Success") {
          setMessage("Data Saved Successfully!");
        } else {
          setMessage("Failed to save data!");
        }
        setShowLoader(false);
        showMessage(true);
        setShowLinkingPopup(false);
        setTimeout(() => showMessage(false), 1200);
      });
    }, 200);
  };
  const onSaveChildItem = () => {
    const tempData = invData.filter((w) => w.selected == 1);

    const data = {
      ...{ offerDetailData: tempData },
      ...{ entryStatus: isEdit ? "update" : "new" },
      ...{ userId: loginInfo.id },
      ...{ offerId: selectedOfferId },
      ...{ parentId: parentItemId },
      ...{ saveChild: 1 },
    };
    setShowLoader(true, tempData);
    showMessage(false);
    setTimeout(() => {
      apiHandler.saveLinkOfferWithInventory(data).then((result) => {
        const file = imageData;
        if (result[0].result === "Success") {
          setMessage("Data Saved Successfully!");
        } else {
          setMessage("Failed to save data!");
        }
        setShowLoader(false);
        showMessage(true);
        setShowLinkingPopup(false);
        setTimeout(() => showMessage(false), 1200);
      });
    }, 200);
  };
  const onCanel = () => {
    setShowConfirmatonMsgBox(false);
    setShowLoader(false);
  };
  const onDelete = () => {
    const prm = { id: selectedOfferId, userId: loginInfo.id };

    setTimeout(() => {
      apiHandler.deleteOffer(prm).then((w) => {
        if (w[0].result === "Success") {
          setMessage("Data Deleted Successfully!");
        } else {
          setMessage("Faild to delete Data!");
        }
        setShowLoader(false);
        setShowConfirmatonMsgBox(false);
        showMessage(true);
        setTimeout(() => {
          showMessage(false);
        }, 1000);
      });
      showHidePopup(false);
    }, 500);
  };
  const showHidePopup = (show) => {
    setShowHidePopup(show);
  };
  const onchange = (e) => {
    setEditData({
      ...editableData,
      [e.target.id]:
        e.target.type !== "checkbox"
          ? e.target.value
          : e.target.checked
          ? 1
          : 0,
    });
    if (e.target.id == "isComboOffer") {
      setIsComboOffer(e.target.checked ? true : false);
    }
  };
  const reSizeImage = async (file) => {
    try {
      const image = await globalFunction.resizeFile(file);
      return image;
    } catch (err) {
      console.log(err);
    }
  };
  const showHideAllOffers = (e) => {
    setSwitchChecked(!switchChecked);
    setforceRefreshScreen(!forceRefreshScreen);
  };
  const setImage = (event) => {
    const img = event.target.files[0];
    setImageData(img);
    var reader = new FileReader();
    var imgBox = document.getElementById("offerThumbnail_img");
    reader.onload = function (event) {
      imgBox.src = event.target.result;
    };
    reader.readAsDataURL(img);
    setEditData({
      ...editableData,
      [event.target.id]: img.name,
    });
  };
  const renderConfirmationMessageBox = () => {
    return (
      <div>
        <ConfirmationBox
          onCancel={() => {
            onCanel();
          }}
          show={ShowConfirmatonMsgBox}
          message={messageText}
          onSuccess={() => onDelete()}
          width="30%"
          id="deleteConfirmaton"
        />
      </div>
    );
  };
  const linkOfferWithInventory = (id, brandId, comboOffer) => {
    const prm = { id: id };

    setSelectedOfferId(id);
    let brandArray = [];
    if (brandId !== "") brandArray.push(brandId);
    const selBrand = brandData.filter((w) => w.id === brandId);
    if (selBrand.length > 0) {
      brandArray.push(selBrand[0].brandName);
    }
    setSelectedBrands(brandArray);
    setSelectedBrandsChildInv(brandArray);
    setIsComboOffer(comboOffer == 1 ? true : false);

    // setforceRefreshScreen(!forceRefreshScreen);
    setTimeout(() => {
      // if (comboOffer && parseInt(comboOffer) !== 0) {
      //   apiHandler.getSalesOffersDetail_ComboParent(prm).then((result) => {
      //     const data =
      //       brandId && brandId > 0
      //         ? result.filter((w) => w.brandId == brandId)
      //         : result;
      //     setOfferDetailData(data);
      //     setGlobalOfferDetailData(result);
      //     apiHandler.getCategory().then((catData) => {
      //       setCategoryData(catData);
      //     });
      //   });
      //   (async () => {
      //     const prm = { offerId: id };
      //     let data = await apiHandler.getSalesOffersDetail_ComboChildByParent(
      //       prm
      //     );

      //     const data2 = data.filter((w) => parseInt(w.selected) == 1);
      //     setChildData(data2);
      //     setInvData(data);
      //     setforceRefreshScreen(!forceRefreshScreen);
      //   })();
      // } else {
      apiHandler.getSalesOfferDetails(prm).then((result) => {
        const data =
          brandId && brandId > 0
            ? result.filter((w) => w.brandId == brandId)
            : result;
        setOfferDetailData(data);
        setGlobalOfferDetailData(result);
        apiHandler.getCategory().then((catData) => {
          setCategoryData(catData);
        });
      });
      setShowLinkingPopup(true);
      // }
      // comboOffer == 1 ? setShowInventoryPopup(true) : setShowLinkingPopup(true);
    }, 100);
  };

  const selectedData = (event, data) => {
    let keys = [];
    const keyData = data.options.filter((o) => data.value.includes(o.value));
    keyData.map((el) => keys.push(el.key));
    if (data.id == "brands") setSelectedBrands(data.value);
    else if (data.id == "category") setSelectedCategory(data.value);
    setApplyFilter(!applyFilter);
  };
  const selectedChildInvData = (event, data) => {
    let keys = [];
    const keyData = data.options.filter((o) => data.value.includes(o.value));
    keyData.map((el) => keys.push(el.key));
    if (data.id == "brandsChild") setSelectedBrandsChildInv(data.value);
    else if (data.id == "categoryChild")
      setSelectedCategoryChildInv(data.value);
    setApplyFilterChildInv(!applyFilterChildInv);
  };
  const renderBrandFilters = () => {
    let data = [];
    const brands =
      brandOnOffer && brandOnOffer > 0
        ? brandData.filter((w) => w.id == brandOnOffer)
        : brandData;
    brands.map((option) => {
      data.push({
        key: option.id,
        text: option.brandName,
        value: option.brandName,
        label: { color: "pink", empty: true, circular: true },
        // image: {
        //   avatar: true,
        //   src: `${assets.IMAGE_URL}/brands/${option.brandImage}`,
        // },
      });
    });
    return (
      <Dropdown
        text="Filter Brands"
        multiple
        // icon={selectedBrands.length > 0 ? null : "filter"}
        onChange={selectedData}
        fluid
        selection
        options={data}
        value={selectedBrands}
        id="brands"
        key={data.key}
      ></Dropdown>
    );
  };
  const renderCategoryFilters = () => {
    let data = [];
    categoryData.map((option) => {
      data.push({
        key: option.id,
        text: option.category,
        value: option.category,
        label: { color: "pink", empty: true, circular: true },
        // image: {
        //   avatar: true,
        //   src: `${assets.IMAGE_URL}/brands/${option.brandImage}`,
        // },
      });
    });
    return (
      <Dropdown
        text="Filter Category"
        multiple
        // icon={selectedCategory.length > 0 ? null : "filter"}
        onChange={selectedData}
        fluid
        selection
        options={data}
        value={selectedCategory}
        id={"category"}
      ></Dropdown>
    );
  };
  const renderBrandFiltersChildInvPopup = () => {
    let data = [];
    const brands =
      brandOnOffer && brandOnOffer > 0
        ? brandData.filter((w) => w.id == brandOnOffer)
        : brandData;
    brands.map((option) => {
      data.push({
        key: option.id,
        text: option.brandName,
        value: option.brandName,
        label: { color: "pink", empty: true, circular: true },
        // image: {
        //   avatar: true,
        //   src: `${assets.IMAGE_URL}/brands/${option.brandImage}`,
        // },
      });
    });
    return (
      <Dropdown
        text="Filter Brands"
        multiple
        // icon={selectedBrands.length > 0 ? null : "filter"}
        onChange={selectedChildInvData}
        fluid
        selection
        options={data}
        value={selectedBrandsChildInv}
        id="brandsChild"
        key={data.key}
      ></Dropdown>
    );
  };
  const renderCategoryFiltersChildInvPopup = () => {
    let data = [];
    categoryData.map((option) => {
      data.push({
        key: option.id,
        text: option.category,
        value: option.category,
        label: { color: "pink", empty: true, circular: true },
        // image: {
        //   avatar: true,
        //   src: `${assets.IMAGE_URL}/brands/${option.brandImage}`,
        // },
      });
    });
    return (
      <Dropdown
        text="Filter Category"
        multiple
        // icon={selectedCategory.length > 0 ? null : "filter"}
        onChange={selectedChildInvData}
        fluid
        selection
        options={data}
        value={selectedCategoryChildInv}
        id={"categoryChild"}
      ></Dropdown>
    );
  };
  const selectAll = (e) => {
    offerDetailData.map(
      (el) => (el.selected = e.target.checked == 1 ? true : false)
    );
    setOfferDetailData(offerDetailData);
    setforceRefreshScreen(!forceRefreshScreen);
  };
  const selectAllChild = (e) => {
    invData.map((el) => (el.selected = e.target.checked == 1 ? true : false));
    setInvData(invData);
    setforceRefreshScreen(!forceRefreshScreen);
  };
  const selectOne = (e) => {
    const id = e.target.id;
    offerDetailData.map((el) => {
      el.selected = el.productId === id ? e.target.checked : el.selected;
    });
    setOfferDetailData(offerDetailData);
    setforceRefreshScreen(!forceRefreshScreen);
  };
  const selectOneChild = (e) => {
    const id = e.target.id;
    invData.map((el) => {
      el.selected = el.productId === id ? e.target.checked : el.selected;
      el.parentId = !e.target.checked && el.productId === id ? 0 : el.parentId;
    });
    setInvData(invData);
    setforceRefreshScreen(!forceRefreshScreen);
  };
  const renderLinkingPopup = () => {
    return (
      <div
        class="modal"
        tabindex="-1"
        style={showLinkingPopup ? { display: "block" } : {}}
        id="dataEntry"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div
              class="modal-header"
              style={{ backgroundColor: "black", color: "white" }}
            >
              <h5 class="modal-title">{popupTitle}</h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => setShowLinkingPopup(false)}
                style={{ backgroundColor: "#ff0000" }}
              ></button>
            </div>
            <div class="modal-body">
              <div class="container">
                <div class="row">
                  <div class="col">{renderBrandFilters()}</div>
                  <div class="col">{renderCategoryFilters()}</div>
                </div>
              </div>
              <div
                style={{
                  overflowX: "scroll",
                  overflowY: "hidden",
                  height: 480,
                }}
              >
                <table class="table table-striped table-hover table-bordered ">
                  <thead>
                    <tr className="table-dark">
                      <th scope="col-xs">
                        <input
                          type="checkbox"
                          id="selectAll"
                          onChange={(e) => selectAll(e)}
                          style={{
                            height: 15,
                            width: 15,
                            border: "solid",
                            alignItems: "center",
                          }}
                        />
                      </th>
                      <th scope="col">Product ID</th>
                      <th scope="col">Product Name</th>
                      <th scope="col">Category</th>
                      <th scope="col">Brand</th>
                      <th scope="col">MRP</th>
                      <th scope="col">Sales Price</th>
                      <th scope="col">Disc(%)</th>
                    </tr>
                  </thead>
                  <tbody>{renderInventoryGrid()}</tbody>
                </table>
              </div>
            </div>
            <div class="modal-footer">
              <div class="row" style={{ width: "100%" }}>
                <div class="col">
                  <button
                    type="button"
                    class="btn btn-primary"
                    onClick={() => {
                      onSaveLinking();
                    }}
                  >
                    Save changes
                  </button>
                </div>
                <div class="col">
                  <button
                    type="button"
                    class="btn btn-secondary"
                    data-bs-dismiss="modal"
                    onClick={() => setShowLinkingPopup(false)}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const renderInventoryPopup = () => {
    return (
      <div
        class="modal"
        tabindex="-1"
        style={showInventoryPopup ? { display: "block" } : {}}
        id="dataEntry2"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div
              class="modal-header"
              style={{ backgroundColor: "black", color: "white" }}
            >
              <h5 class="modal-title">Setup Inventory</h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => setShowInventoryPopup(false)}
                style={{ backgroundColor: "#ff0000" }}
              ></button>
            </div>
            <div class="modal-body">
              <div class="container">
                <div class="row">
                  <div class="col">{renderBrandFilters()}</div>
                  <div class="col">{renderCategoryFilters()}</div>
                </div>
              </div>

              <div
                style={{
                  overflowY: "scroll",
                  overflowX: "hidden",
                  height: 480,
                }}
              >
                <table class="table table-striped table-hover table-bordered table-md">
                  <thead>
                    <tr className="table-dark">
                      <th scope="col-xs">
                        <input
                          type="checkbox"
                          id="selectAll"
                          onChange={(e) => selectAll(e)}
                          style={{
                            height: 15,
                            width: 15,
                            border: "solid",
                            alignItems: "center",
                          }}
                        />
                      </th>
                      <th scope="col">Product ID</th>
                      <th scope="col">Product Name</th>
                      <th scope="col">Category</th>
                      <th scope="col">Brand</th>
                      <th scope="col">MRP</th>
                      <th scope="col">Sales Price</th>
                      <th scope="col">Disc(%)</th>
                      <th scope="col">...</th>
                    </tr>
                  </thead>
                  <tbody>{renderInventoryItemGrid()}</tbody>
                </table>
              </div>
            </div>
            <div class="modal-footer">
              <div class="row" style={{ width: "100%" }}>
                <div class="col">
                  <button
                    type="button"
                    class="btn btn-primary"
                    onClick={() => {
                      onSaveLinking();
                    }}
                    id="btnSaveChildInv"
                  >
                    Save changes
                  </button>
                </div>
                <div class="col">
                  <button
                    type="button"
                    class="btn btn-secondary"
                    data-bs-dismiss="modal"
                    onClick={() => setShowInventoryPopup(false)}
                    id="btnCloseChildInv"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const onCloseChildInvPopup = () => {
    const holdChildData = childData.filter((w) => w.parentId !== parentItemId);
    console.log("holdChildData", holdChildData, childData, parentItemId);
    // const temp1 = childData.filter((w) => w.parentId !== parentItemId);
    const data = invData.filter(
      (w) => parseInt(w.selected) == 1 && parseInt(w.parentId) == parentItemId
    );
    data.map((w) => {
      holdChildData.push(w);
    });
    console.log("holdChildData2", data, invData);
    setChildData(holdChildData);

    setShowChildInvPopup(false);
    setShowInventoryPopup(true);
  };
  const renderChildInventoryPopup = () => {
    return (
      <div
        class="modal"
        tabindex="-1"
        style={showChildInvPopup ? { display: "block", overflow: "auto" } : {}}
        id="dataEntry2"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div
              class="modal-header"
              style={{ backgroundColor: "black", color: "white" }}
            >
              <h5 class="modal-title">Setup Child Inventory</h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => onCloseChildInvPopup()}
                style={{ backgroundColor: "#ff0000" }}
              ></button>
            </div>
            <div class="modal-body">
              <div class="container">
                <div class="row">
                  <div class="col">{renderBrandFiltersChildInvPopup()}</div>
                  <div class="col">{renderCategoryFiltersChildInvPopup()}</div>
                </div>
              </div>
              <div
                style={{
                  overflowY: "scroll",
                  overflowX: "hidden",
                  height: 480,
                }}
              >
                <table class="table table-striped table-hover table-bordered ">
                  <thead>
                    <tr className="table-dark">
                      <th scope="col-xs">
                        <input
                          type="checkbox"
                          id="selectAll"
                          onChange={(e) => selectAllChild(e)}
                          style={{
                            height: 15,
                            width: 15,
                            border: "solid",
                            alignItems: "center",
                          }}
                        />
                      </th>
                      <th scope="col">Product ID</th>
                      <th scope="col">Product Name</th>
                      <th scope="col">Category</th>
                      <th scope="col">Brand</th>
                      <th scope="col">MRP</th>
                      <th scope="col">Sales Price</th>
                      <th scope="col">Disc(%)</th>
                    </tr>
                  </thead>
                  <tbody>{renderInventoryChildGrid()}</tbody>
                </table>
              </div>
            </div>
            <div class="modal-footer">
              <div class="row" style={{ width: "100%" }}>
                <div class="col">
                  {/* <button
                    type="button"
                    class="btn btn-primary"
                    onClick={() => {
                      onSaveChildItem();
                    }}
                    id="btnSaveChildInv"
                  >
                    Save changes
                  </button> */}
                </div>
                <div class="col">
                  <button
                    type="button"
                    class="btn btn-secondary"
                    data-bs-dismiss="modal"
                    onClick={() => onCloseChildInvPopup()}
                    id="btnCloseChildInv"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const onEditSP = (id, textID, spnID) => {
    // const textID = `txt_${id}`;
    // const spnID = `spn_${id}`;
    // let textID = "";
    // let spnID = "";
    // if (IsComboOffer == true) {
    //   textID = `txtChild_${id}`;
    //   spnID = `spnChild_${id}`;
    // } else {
    //   textID = `txt_${id}`;
    //   spnID = `spn_${id}`;
    // }
    console.log("IsComboOffer", IsComboOffer, textID, spnID);
    document.getElementById(textID).style.display = "block";
    document.getElementById(spnID).style.display = "none";
  };
  const hideEditing = (id, textID, spnID) => {
    // const textID = `txt_${id}`;
    // const spnID = `spn_${id}`;
    // let textID = "";
    // let spnID = "";
    // if (IsComboOffer == true) {
    //   textID = `txtChild_${id}`;
    //   spnID = `spnChild_${id}`;
    // } else {
    //   textID = `txt_${id}`;
    //   spnID = `spn_${id}`;
    // }
    console.log("TEST", textID, spnID);
    document.getElementById(textID).style.display = "none";
    document.getElementById(spnID).style.display = "block";
    setEditSP(false);
  };

  const onEditDisc = (id, textID, spnID) => {
    // const textID = `txtDisc_${id}`;
    // const spnID = `spnDisc_${id}`;
    // let textID = "";
    // let spnID = "";
    console.log("id", id, textID, spnID);
    // if (IsComboOffer == true) {
    //   textID = `txtChildDisc_${id}`;
    //   spnID = `spnChildDisc_${id}`;
    // } else {
    //   textID = `txtDisc_${id}`;
    //   spnID = `spnDisc_${id}`;
    // }
    document.getElementById(textID).style.display = "block";
    document.getElementById(spnID).style.display = "none";
  };
  const hideEditingDisc = (id, textID, spnID) => {
    // let textID = `${textName}_${id}`;
    // let spnID = "";
    // if (IsComboOffer == true) {
    //   textID = `txtChildDisc_${id}`;
    //   spnID = `spnChildDisc_${id}`;
    // } else {
    //   textID = `txtDisc_${id}`;
    //   spnID = `spnDisc_${id}`;
    // }

    document.getElementById(textID).style.display = "none";
    document.getElementById(spnID).style.display = "block";
    setEditDisc(false);
  };
  const onDiscChange = (e) => {
    const id = e.target.id.replace("txtDisc_", "");
    offerDetailData.map((el) => {
      el.discountPercent =
        el.productId == id ? e.target.value : el.discountPercent;
      el.selected = el.productId == id ? true : el.selected;
    });
    setOfferDetailData(offerDetailData);
    setEditDisc(true);
    setforceRefreshScreen(!forceRefreshScreen);
  };
  const onSPChange = (e) => {
    console.log("EVENT", e);
    const id = e.target.id.replace("txt_", "");
    offerDetailData.map((el) => {
      el.salesPrice = el.productId == id ? e.target.value : el.salesPrice;
      el.selected = el.productId == id ? true : el.selected;
    });
    setOfferDetailData(offerDetailData);
    setEditSP(true);
    setforceRefreshScreen(!forceRefreshScreen);
  };
  const onInvDiscChange = (e) => {
    const id = e.target.id.replace("txtInvDisc_", "");
    offerDetailData.map((el) => {
      el.discountPercent =
        el.productId == id ? e.target.value : el.discountPercent;
      el.selected = el.productId == id ? true : el.selected;
    });
    setOfferDetailData(offerDetailData);
    setEditDisc(true);
    setforceRefreshScreen(!forceRefreshScreen);
  };
  const onInvSPChange = (e) => {
    console.log("EVENT", e);
    const id = e.target.id.replace("txtInv_", "");
    offerDetailData.map((el) => {
      el.salesPrice = el.productId == id ? e.target.value : el.salesPrice;
      el.selected = el.productId == id ? true : el.selected;
    });
    setOfferDetailData(offerDetailData);
    setEditSP(true);
    setforceRefreshScreen(!forceRefreshScreen);
  };
  const onInvChildDiscChange = (e) => {
    const id = e.target.id.replace("txtChildDisc_", "");
    invData.map((el) => {
      el.discountPercent =
        el.productId == id ? e.target.value : el.discountPercent;
      el.selected = el.productId == id ? true : el.selected;
      el.parentId = parentItemId;
    });
    setInvData(invData);
    setEditDisc(true);
    setforceRefreshScreen(!forceRefreshScreen);
  };
  const onInvChildSPChange = (e) => {
    console.log("EVENT", e);
    const id = e.target.id.replace("txtChild_", "");
    invData.map((el) => {
      el.salesPrice = el.productId == id ? e.target.value : el.salesPrice;
      el.selected = el.productId == id ? 1 : el.selected;
      el.parentId = parentItemId;
    });
    setInvData(invData);
    setEditSP(true);
    setforceRefreshScreen(!forceRefreshScreen);
  };
  const ShowAll = (e) => {
    setSwitchChecked(!switchChecked);
    if (e.target.checked) {
      setData(allOffer);
    } else {
      const activeOfferOnly = allOffer.filter((w) => w.active == 1);
      setData(activeOfferOnly);
    }
  };
  const onOpenChildInvPopup = (id) => {
    console.log("id", id, selectedOfferId);
    setTimeout(() => {
      offerDetailData.map((w) => {
        w.comboOfferParent = w.itemId == id ? 1 : w.comboOfferParent;
      });
      setParentItemId(id);
      setforceRefreshScreen(!forceRefreshScreen);
    }, 200);
    const prm = { offerId: selectedOfferId, parentId: id };
    (async () => {
      let data = await apiHandler.getSalesOffersDetail_ComboChildByParent(prm);
      data = data.filter((w) => w.itemId !== id);
      console.log("data", data);

      data.map((el) => {
        let xData = childData.filter(
          (w) => parseInt(w.itemId) == el.itemId && parseInt(w.parentId) == id
        );
        console.log("xDataWWWW", xData, childData, id);
        if (xData.length > 0) {
          console.log("xData", xData, el.itemId, xData[0].itemId);
          el.selected = xData[0].selected;
          el.salesPrice = xData[0].salesPrice;
          el.discountPercent = xData[0].discountPercent;
        }
        // else {
        //   el.selected = false;
        //   el.salesPrice = 0;
        //   el.discountPercent = 0;
        // }
      });
      console.log("data", data);
      setInvData(data);
    })();
    // console.log("invData", invData);
    // // const t = invData.filter((w) => parseInt(w.parentId) == parentItemId);
    // // //const t = invData.some(w=>w.parentId==parentItemId)
    // // invData.map((el) => {
    // //   let fData = t.filter((w) => w.itemId == el.itemId);
    // //   if (fData.length > 0) {
    // //     el.selected = fData[0].selected;
    // //     el.salesPrice = fData[0].salesPrice;
    // //     el.discountPercent = fData[0].discountPercent;
    // //   }
    // // });
    // // setInvData(t);
    const data = offerDetailData.filter((w) => w.itemId === id);
    if (parseFloat(data[0].salesPrice) == 0) {
      alert("Please enter sales price of item to proceed");
      return;
    }
    // setTimeout(() => {
    //   onSaveLinking();
    // }, 500);

    setforceRefreshScreen(!forceRefreshScreen);
    setShowInventoryPopup(false);
    setShowChildInvPopup(true);
  };
  const renderInventoryGrid = () => {
    return offerDetailData.map((w) => {
      return (
        <tr className="table-info" id={`tr_${w.id ? w.id : "-1"}`}>
          <td className="col-xs" style={actionButtonStyle}>
            <input
              checked={w.selected == 0 ? false : true}
              id={w.productId}
              onChange={(e) => selectOne(e)}
              type="checkbox"
              style={{
                height: 15,
                width: 15,
                border: "solid",
                alignItems: "center",
              }}
            />
          </td>
          <td>{w.productId}</td>
          <td>{w.productName}</td>
          <td>{w.category}</td>
          <td>{w.brandName}</td>
          <td>{w.mrp}</td>
          <td
            onDoubleClick={() =>
              onEditSP(w.productId, `txt_${w.productId}`, `spn_${w.productId}`)
            }
            id={`td_${w.productId}`}
          >
            <input
              type="text"
              value={w.salesPrice}
              id={`txt_${w.productId}`}
              style={{ display: "none", height: 34, width: 85, margin: -7 }}
              onBlur={() =>
                hideEditing(
                  w.productId,
                  `txt_${w.productId}`,
                  `spn_${w.productId}`
                )
              }
              onChange={(e) => onSPChange(e)}
            />
            <span id={`spn_${w.productId}`}>{w.salesPrice}</span>
          </td>
          <td
            id={`tdDisc_${w.productId}`}
            onDoubleClick={() =>
              onEditDisc(
                w.productId,
                `txtDisc_${w.productId}`,
                `spnDisc_${w.productId}`
              )
            }
          >
            <input
              type="text"
              value={w.discountPercent}
              id={`txtDisc_${w.productId}`}
              style={{ display: "none", height: 34, width: 85, margin: -7 }}
              onBlur={() =>
                hideEditingDisc(
                  w.productId,
                  `txtDisc_${w.productId}`,
                  `spnDisc_${w.productId}`
                )
              }
              onChange={(e) => onDiscChange(e)}
            />
            <span id={`spnDisc_${w.productId}`}>{w.discountPercent}</span>
          </td>
        </tr>
      );
    });
  };
  const renderInventoryItemGrid = () => {
    return offerDetailData.map((w) => {
      return (
        <tr className="table-info" id={`tr_${w.id ? w.id : "-1"}`}>
          <td className="col-xs" style={actionButtonStyle}>
            <input
              checked={w.selected == 0 ? false : true}
              id={w.productId}
              onChange={(e) => selectOne(e)}
              type="checkbox"
              style={{
                height: 15,
                width: 15,
                border: "solid",
                alignItems: "center",
              }}
            />
          </td>
          <td>{w.productId}</td>
          <td>{w.productName}</td>
          <td>{w.category}</td>
          <td>{w.brandName}</td>
          <td>{w.mrp}</td>
          <td
            onDoubleClick={() =>
              onEditSP(
                w.productId,
                `txtInv_${w.productId}`,
                `spnInv_${w.productId}`
              )
            }
            id={`tdInv_${w.productId}`}
          >
            <input
              type="text"
              value={w.salesPrice}
              id={`txtInv_${w.productId}`}
              style={{ display: "none", height: 34, width: 85, margin: -7 }}
              onBlur={() =>
                hideEditing(
                  w.productId,
                  `txtInv_${w.productId}`,
                  `spnInv_${w.productId}`
                )
              }
              onChange={(e) => onInvSPChange(e)}
            />
            <span id={`spnInv_${w.productId}`}>{w.salesPrice}</span>
          </td>
          <td
            id={`tdInvDisc_${w.productId}`}
            onDoubleClick={() =>
              onEditDisc(
                w.productId,
                `txtInvDisc_${w.productId}`,
                `spnInvDisc_${w.productId}`
              )
            }
          >
            <input
              type="text"
              value={w.discountPercent}
              id={`txtInvDisc_${w.productId}`}
              style={{ display: "none", height: 34, width: 85, margin: -7 }}
              onBlur={() =>
                hideEditingDisc(
                  w.productId,
                  `txtInvDisc_${w.productId}`,
                  `spnInvDisc_${w.productId}`
                )
              }
              onChange={(e) => onInvDiscChange(e)}
            />
            <span id={`spnInvDisc_${w.productId}`}>{w.discountPercent}</span>
          </td>
          <td>
            <Icon name="edit" onClick={() => onOpenChildInvPopup(w.itemId)} />
          </td>
        </tr>
      );
    });
  };
  const renderInventoryChildGrid = () => {
    return invData.map((w) => {
      return (
        <tr className="table-info" id={`tr_${w.id ? w.id : "-1"}`}>
          <td className="col-xs" style={actionButtonStyle}>
            <input
              checked={w.selected == 0 ? false : true}
              id={w.productId}
              onChange={(e) => selectOneChild(e)}
              type="checkbox"
              style={{
                height: 15,
                width: 15,
                border: "solid",
                alignItems: "center",
              }}
            />
          </td>
          <td>{w.productId}</td>
          <td>{w.productName}</td>
          <td>{w.category}</td>
          <td>{w.brandName}</td>
          <td>{w.mrp}</td>
          <td
            onDoubleClick={() =>
              onEditSP(
                w.productId,
                `txtChild_${w.productId}`,
                `spnChild_${w.productId}`
              )
            }
            id={`tdChild_${w.productId}`}
          >
            <input
              type="text"
              value={w.salesPrice}
              id={`txtChild_${w.productId}`}
              style={{ display: "none", height: 34, width: 85, margin: -7 }}
              onBlur={() =>
                hideEditing(
                  w.productId,
                  `txtChild_${w.productId}`,
                  `spnChild_${w.productId}`
                )
              }
              onChange={(e) => onInvChildSPChange(e)}
            />
            <span id={`spnChild_${w.productId}`}>{w.salesPrice}</span>
          </td>
          <td
            id={`tdchildDisc_${w.productId}`}
            onDoubleClick={() =>
              onEditDisc(
                w.productId,
                `txtChildDisc_${w.productId}`,
                `spnChildDisc_${w.productId}`
              )
            }
          >
            <input
              type="text"
              value={w.discountPercent}
              id={`txtChildDisc_${w.productId}`}
              style={{ display: "none", height: 34, width: 85, margin: -7 }}
              onBlur={() =>
                hideEditingDisc(
                  w.productId,
                  `txtChildDisc_${w.productId}`,
                  `spnChildDisc_${w.productId}`
                )
              }
              onChange={(e) => onInvChildDiscChange(e)}
            />
            <span id={`spnChildDisc_${w.productId}`}>{w.discountPercent}</span>
          </td>
        </tr>
      );
    });
  };
  const renderGrid = () => {
    return offerData.map((w) => {
      return (
        <tr className="table-info" id={w.id}>
          <td className="col-xs" style={actionButtonStyle}>
            <Icon className="trash" onClick={() => ShowConfirmaton(w.id)} />
          </td>
          <td className="col-xs" style={actionButtonStyle}>
            <Icon className="edit" onClick={(e) => onEdit(w.id)} id={w.id} />
          </td>
          <td>{w.offerTitle}</td>
          <td>{w.startDate}</td>
          <td>{w.endDate}</td>
          <td>
            <img
              src={`${assets.IMAGE_URL}/salesOffers/${w.offerThumbnail}`}
              style={{ height: 25 }}
              srcSet={`${assets.IMAGE_URL}/salesOffers/${w.offerThumbnail} 1x`}
            />
          </td>
          <td>{w.isDealOfDay == 1 ? "Yes" : "No"}</td>
          <td>{w.isStarBrand == 1 ? "Yes" : "No"}</td>
          <td>{w.isHotDeal == 1 ? "Yes" : "No"}</td>
          <td>{w.isComboOffer == 1 ? "Yes" : "No"}</td>
          <td>{w.notificationTitle}</td>
          <td style={{ width: 3 }}>
            <div
              style={{ display: "flex" }}
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Double click to change value"
            >
              <Icon
                name="linkify"
                style={
                  w.LinkingFound > 0 ? { color: "green" } : { color: "red" }
                }
                onClick={() =>
                  linkOfferWithInventory(w.id, w.brandId, w.isComboOffer)
                }
              />
              <span class="badge bg-secondary">{w.LinkingFound}</span>
            </div>
          </td>
        </tr>
      );
    });
  };
  const renderPopupBody = () => {
    return (
      <>
        <div class="row">
          <div class="col" style={{ marginLeft: -80 }}>
            <div class="form-check form-switch">
              <label
                class="form-check-label"
                for="flexSwitchCheckDefault"
                style={{ marginLeft: -780, marginTop: 8 }}
              >
                <strong>Active</strong>
              </label>
              <input
                class="form-check-input"
                type="checkbox"
                role="switch"
                id="active"
                style={{ marginLeft: 45 }}
                checked={editableData.active == 1 ? true : false}
                onChange={(e) => onchange(e)}
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="form-floating">
              <input
                type="text"
                class="form-control"
                placeholder="offerTitle"
                aria-label="offerTitle"
                value={editableData.offerTitle}
                id="offerTitle"
                onChange={(e) => onchange(e)}
                required
              />
              <label for="offerTitle">Enter Offer Title</label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="form-floating">
              <select
                class="form-select"
                style={{ height: 50 }}
                value={editableData.brandId}
                id="brandId"
                onChange={(e) => onchange(e)}
              >
                <option value="-1">Choose Brand</option>
                {brandData.map((el) => (
                  <option value={el.id}>{el.brandName}</option>
                ))}
              </select>
              <label for="brandId">Select Brand</label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="form-floating">
              <input
                type="date"
                class="form-control"
                placeholder="Start Date"
                aria-label="startDate"
                id="startDate"
                style={style}
                value={editableData.startDate}
                min={minDate}
                onChange={(e) => onchange(e)}
                required
              />
              <label for="startDate">Start Date</label>
            </div>
          </div>
          <div className="col form-floating">
            <input
              type="date"
              class="form-control"
              placeholder="End Date"
              aria-label="endDate"
              id="endDate"
              style={style}
              min={minDate}
              value={editableData.endDate}
              onChange={(e) => onchange(e)}
              required
            />
            <label for="endDate" style={{ paddingTop: 25, paddingLeft: 22 }}>
              End Date
            </label>
          </div>
        </div>
        <div className="row" style={{ marginTop: 15, paddingBottom: 2 }}>
          <table class="table table-striped table-hover ">
            <tbody>
              <td>
                <div class="form-check form-switch">
                  <label
                    class="form-check-label"
                    for="flexSwitchCheckDefault"
                    style={{ marginTop: 5 }}
                  >
                    Deal of the day
                  </label>
                  <input
                    class="form-check-input"
                    type="checkbox"
                    role="switch"
                    id="isDealOfDay"
                    style={{ marginLeft: 10 }}
                    checked={editableData.isDealOfDay == 1 ? true : false}
                    onChange={(e) => onchange(e)}
                  />
                </div>
              </td>
              <td>
                <div class="form-check form-switch">
                  <label
                    class="form-check-label"
                    for="flexSwitchCheckDefault"
                    style={{ marginTop: 5 }}
                  >
                    Hot Deal
                  </label>
                  <input
                    class="form-check-input"
                    type="checkbox"
                    role="switch"
                    id="isHotDeal"
                    style={{ marginLeft: 55 }}
                    checked={editableData.isHotDeal == 1 ? true : false}
                    onChange={(e) => onchange(e)}
                  />
                </div>
              </td>
              <td>
                <div class="form-check form-switch">
                  <label
                    class="form-check-label"
                    for="flexSwitchCheckDefault"
                    style={{ marginTop: 5 }}
                  >
                    Star Brand
                  </label>
                  <input
                    class="form-check-input"
                    type="checkbox"
                    role="switch"
                    id="isStarBrand"
                    style={{ marginLeft: 45 }}
                    checked={editableData.isStarBrand == 1 ? true : false}
                    onChange={(e) => onchange(e)}
                  />
                </div>
              </td>
              <td>
                <div class="form-check form-switch">
                  <label
                    class="form-check-label"
                    for="flexSwitchCheckDefault"
                    style={{ marginTop: 5 }}
                  >
                    Combo Offer
                  </label>
                  <input
                    class="form-check-input"
                    type="checkbox"
                    role="switch"
                    id="isComboOffer"
                    style={{ marginLeft: 45 }}
                    checked={editableData.isComboOffer == 1 ? true : false}
                    onChange={(e) => onchange(e)}
                  />
                </div>
              </td>
            </tbody>
          </table>
        </div>
        <div class="row">
          {IsComboOffer ? (
            <div class="col">
              <div class="form-floating">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Offer Discount"
                  aria-label="offerDiscount"
                  value={editableData.offerDiscount}
                  id="offerDiscount"
                  onChange={(e) => onchange(e)}
                  required
                />
                <label for="lblofferDiscount">Offer Amount</label>
              </div>
            </div>
          ) : null}
          <div class="col">
            <div class="form-floating">
              <input
                type="text"
                class="form-control"
                placeholder="Notification Title"
                aria-label="notificationTitle"
                value={editableData.notificationTitle}
                id="notificationTitle"
                onChange={(e) => onchange(e)}
                required
              />
              <label for="lblNotificationTitle">Notification Title</label>
            </div>
          </div>
          <div class="col">
            <div class="form-floating">
              <input
                type="text"
                class="form-control"
                placeholder="Notification"
                aria-label="notification"
                value={editableData.notification}
                id="notification"
                onChange={(e) => onchange(e)}
                required
              />
              <label for="lblNotification">Notification</label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="input-group mb-3">
              <input
                type="file"
                class="form-control"
                id="offerThumbnail"
                onChange={(e) => setImage(e)}
                style={{ marginTop: 8 }}
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <img
              src={`${assets.IMAGE_URL}/salesOffers/${editableData.offerThumbnail}`}
              class="img-thumbnail float-start"
              style={{ height: 180 }}
              id="offerThumbnail_img"
              srcSet={`${assets.IMAGE_URL}/salesOffers/${editableData.offerThumbnail} 1x`}
            />
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div
              style={
                showValidationMessage === true
                  ? { display: "block", color: "red" }
                  : { color: "red", display: "none" }
              }
            >
              {errorMessage}
            </div>
          </div>
        </div>
      </>
    );
  };
  const renderAddEditPopup = () => {
    return (
      <div
        class="modal"
        tabindex="-1"
        style={showPopup ? { display: "block" } : {}}
        id="dataEntry"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div
              class="modal-header"
              style={{ backgroundColor: "black", color: "white" }}
            >
              <h5 class="modal-title">{popupTitle}</h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => showHidePopup(false)}
                style={{ backgroundColor: "#ff0000" }}
              ></button>
            </div>
            <div class="modal-body">{renderPopupBody()}</div>
            <div class="modal-footer">
              <div class="row" style={{ width: "100%" }}>
                <div class="col">
                  <button
                    type="button"
                    class="btn btn-primary"
                    onClick={() => {
                      onSave();
                    }}
                  >
                    Save changes
                  </button>
                </div>
                <div class="col">
                  <button
                    type="button"
                    class="btn btn-secondary"
                    data-bs-dismiss="modal"
                    onClick={() => setShowHidePopup(false)}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const renderShowAllSwitch = () => {
    return (
      <div>
        <input
          type="checkbox"
          role="switch"
          id="active"
          style={{ marginLeft: 10 }}
          onChange={(e) => ShowAll(e)}
          class="form-check-input"
          checked={switchChecked}
          ref={contextRef}
        />
        <label className="lblShowAll">Show All(Active/Inactive both)</label>
      </div>
    );
  };
  return (
    <>
      <div className="container">
        <div style={{ width: "100%", paddingBottom: 5, height: 35 }}>
          <button
            type="button"
            class="btn btn-primary btn-md"
            onClick={() => onAddNew()}
            style={{ width: 100, float: "left" }}
          >
            Add New
          </button>
          <div class="form-check form-switch" style={{ width: 480 }}>
            <Popup
              content="Show All(Active/Inactive both)"
              trigger={renderShowAllSwitch()}
              inverted
              context={contextRef}
              size="mini"
            />
          </div>
        </div>

        {renderAddEditPopup()}
        <div class="table-responsive" style={{ maxHeight: 500 }}>
          <table class="table table-striped table-hover table-bordered ">
            <thead>
              <tr className="table-dark">
                <th scope="col-xs">#</th>
                <th scope="col-xs">#</th>
                <th scope="col">Title</th>
                <th scope="col">Start Date</th>
                <th scope="col">End Date</th>
                <th scope="col">Thumbnail</th>
                <th scope="col">Deal of Day</th>
                <th scope="col">Start Brand</th>
                <th scope="col">Hot Deal</th>
                <th scope="col">Combo Offer</th>
                <th scope="col">Notification Title</th>
                <th scope="col-xs" style={{ width: 3 }}>
                  #
                </th>
              </tr>
            </thead>
            <tbody>{renderGrid()}</tbody>
          </table>
        </div>
      </div>
      {renderLinkingPopup()}
      {renderInventoryPopup()}
      {renderChildInventoryPopup()}
      <TransitionablePortal open={open} transition={{ animation, duration }}>
        <Segment
          // style={{
          //   left: "85%",
          //   position: "fixed",
          //   top: "20%",
          //   zIndex: 1000,
          //   backgroundColor: "green",
          //   color: "white",
          //   width: 350,
          // }}
          id="transationMessage"
        >
          <Header>Information</Header>
          <p>{messageText}</p>
        </Segment>
      </TransitionablePortal>
      <Loader showLoader={showLoader} />
      {renderConfirmationMessageBox()}
    </>
  );
};

export default SaleOfferSetup;
