import React, { useEffect, useState } from "react";
import moment from "moment";
import apiHandler from "../commonfunction/data";

const ReportHeader = (props) => {
  const [companyInfo, setCompanyInfo] = useState([]);
  useEffect(() => {
    (async () => {
      const data = await apiHandler.getCompanyInfo();
      console.log("data", data);
      setCompanyInfo(data ? data[0] : {});
    })();
  }, []);
  return (
    <>
      <div class="container">
        <table class="table-responsive" style={{ width: "98%" }}>
          <tr>
            <td>
              <h1
                style={{
                  textAlign: "left",
                  width: 500,
                }}
                id="header_title"
              >
                {props.title}
              </h1>
            </td>
            <td></td>
            <td>
              <h1
                style={{ float: "right" }}
                id="header_companyName"
              >{`${companyInfo.companyName}`}</h1>
            </td>
          </tr>
          <tr>
            <td>
              <i
                style={{
                  textAlign: "left",
                  fontSize: 10,
                  float: "left",
                }}
                id="header_dateRange"
              >
                {`Date Range: ${moment(props.startDate).format("DD/MM/YYYY")} -
          ${moment(props.endDate).format("DD/MM/YYYY")}`}
              </i>
            </td>
            <td></td>
            <td>
              <i
                style={{ fontSize: 12, float: "right" }}
                id="header_address1"
              >{`${companyInfo.address} ${companyInfo.city} `}</i>
            </td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td>
              <i
                style={{ fontSize: 12, float: "right" }}
                id="header_address2"
              >{`${companyInfo.state}, ${companyInfo.zip}`}</i>
            </td>
          </tr>
        </table>
      </div>
    </>
  );
};

export default ReportHeader;
