import React, { useEffect, useState, useRef } from "react";
import _ from "lodash";
//import LoginContext from "../context/LoginContext";
import { Dimmer, Icon } from "semantic-ui-react";
import apiHandler from "../commonfunction/data";
import Loader from "../components/Loader";
import {
  TransitionablePortal,
  Segment,
  Header,
  Popup,
} from "semantic-ui-react";
import { useNavigate } from "react-router-dom";
import assets from "../assets/assets";
import moment from "moment";
import globalFunction from "../commonfunction/globalFunction";

const initialData = {
  id: 0,
  userId: "",
  OrderDate: "",
  ownerName: "",
  address: "",
  city: "",
  state: "",
  subTotal: "",
  discount: "",
  deliveryCharges: "",
  gSTAmount: "",
  lpAmount: "",
  netAmount: "",
  statusUpdateOn: "",
  lpEarned: "",
  logisticsName: "",
  TrackingNo: "",
  productName: "",
  category: "",
  brandName: "",
  qty: "0",
  sgst: "0",
  cgst: "0",
  igst: "0",
  statusId: -1,
  orderNumber: "",
  invoiceDocument: "",
};
const filters = {
  orderNumber: "",
  ownerName: "",
  state: "",
  userId: "",
  OrderDate: "",
  statusId: -1,
  netAmount: "",
};
const Orders = (props) => {
  const [showPopup, setShowHidePopup] = useState(false);
  const [isEdit, setEdit] = useState(false);
  const [globaldata, setData] = useState([{}]);
  const [editableData, setEditData] = useState(initialData);
  const [popupTitle, setTitle] = useState("Category");
  const [open, showMessage] = useState(false);
  const [showAlert, showAlertMessage] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [animation, setAnimation] = useState(assets.transitions[12]);
  const [duration, setduration] = useState(800);
  const [messageText, setMessage] = useState();
  const [openConfirmationBox, setOpenConfirmationBox] = useState(false);
  const [showValidationMessage, setShowValidationMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [loginInfo, setLoginInfo] = useState();
  const [selectedId, setSeletedId] = useState();
  const [OrderHeader, setOrderHeader] = useState([{}]);
  const [OrderDetail, setOrderDetail] = useState([{}]);
  const [OrderStatusList, setOrderStatusList] = useState([{}]);
  const [orderList, setOrderList] = useState([]);
  const [userList, setUserList] = useState([]);
  const [orderStatus, setOrderStatus] = useState(-1);
  const [allStatus, setAllStatus] = useState([{}]);
  const [buttonCaption, setButtonCaption] = useState("Update Status");
  const [forceRefreshScreen, setforceRefreshScreen] = useState(false);
  const [filterData, setFilterData] = useState(filters);
  const [applyFilter, setApplyFilter] = useState(false);
  const [showLoaderOnUpload, setShowLoaderOnUpload] = useState(false);

  const navigation = useNavigate();
  const contextRef = useRef();

  const actionButtonStyle = {
    borderWidth: 0,
    paddingTop: 18,
    width: 10,
  };
  const columnStyle = {
    borderWidth: 0,
    color: "white",
    // fontSize: 15,
    paddingTop: 20,
  };
  useEffect(() => {
    setButtonCaption("Loading Data...");
    props.showTitle("Orders");
    props.hideSideBar(false);
    const loginData = JSON.parse(
      apiHandler.getDataFromLocalStorage("loginDetails")
    );
    setLoginInfo(loginData);
    if (
      loginData === null ||
      loginData.userName === "" ||
      loginData.password === ""
    ) {
      navigation("/");
    }
    setShowLoader(true);
    setOrderStatus(-1);
    setTimeout(() => {
      apiHandler.getOrders().then((result) => {
        console.log("result", result);
        setData(result);
        let ordHeader = _.uniqBy(result, (obj) => obj.id);
        ordHeader = ordHeader.filter((w) => w.statusId < 5);
        setOrderHeader(ordHeader);
        setOrderDetail(result);
        apiHandler.getOrderStatus().then((status) => {
          const data = status.filter((w) => w.id !== "1");
          setOrderStatusList(data);
          setAllStatus(status);
          setShowLoader(false);
          setButtonCaption("Update Status");
        });
      });
    }, 1000);
  }, [open, forceRefreshScreen]);
  useEffect(() => {
    const title = isEdit ? "Update Order" : "New Order";
    setTitle(title);
  }, [isEdit]);
  useEffect(() => {
    console.log("UseEffect");
  }, [orderStatus]);
  useEffect(() => {
    console.log("filterData", filterData);
    let searchData = globaldata;
    if (filterData.orderNumber !== "") {
      console.log("STEP1");
      searchData = searchData.filter(
        (w) =>
          w["orderNumber"].toLowerCase().indexOf(filterData.orderNumber) !== -1
      );
    }
    if (filterData.ownerName !== "") {
      searchData = searchData.filter(
        (w) =>
          w["ownerName"]
            .toLowerCase()
            .indexOf(filterData.ownerName.toLowerCase()) !== -1
      );
    }
    if (filterData.state !== "") {
      searchData = searchData.filter(
        (w) =>
          w["state"].toLowerCase().indexOf(filterData.state.toLowerCase()) !==
          -1
      );
    }
    if (filterData.userId !== "") {
      searchData = searchData.filter(
        (w) => w["userId"].toLowerCase().indexOf(filterData.userId) !== -1
      );
    }
    if (filterData.OrderDate !== "") {
      searchData = searchData.filter(
        (w) =>
          moment(w["OrderDate"])
            .format("DD/MM/YYYY")
            .indexOf(moment(filterData.OrderDate).format("DD/MM/YYYY")) !== -1
      );
    }
    if (parseInt(filterData.netAmount) > -1) {
      searchData = searchData.filter(
        (w) => parseFloat(w["netAmount"]) === parseFloat(filterData.netAmount)
      );
    }
    if (parseInt(filterData.deliveryCharges) > -1) {
      searchData = searchData.filter(
        (w) =>
          parseFloat(w["deliveryCharges"]) ===
          parseFloat(filterData.deliveryCharges)
      );
    }
    if (parseInt(filterData.statusId) > -1) {
      searchData = searchData.filter(
        (w) => parseInt(w["statusId"]) === parseInt(filterData.statusId)
      );
    }

    console.log(
      "FILTERD DATA",
      searchData,
      moment(filterData.OrderDate).format("DD/MM/YYYY")
    );
    setOrderHeader(searchData);
  }, [applyFilter]);
  const showHidePopup = (show) => {
    setShowHidePopup(show);
  };
  const updateOrderStatus = () => {
    if (orderList.length <= 0) {
      //alert("Please select order to update status");
      setMessage("Please select order to update status...");
      showAlertMessage(true);
      return;
    }
    if (orderStatus !== null && orderStatus == -1) {
      //alert("Please select correct status");
      setMessage("Please select status from status dropdown...");
      showAlertMessage(true);
      return;
    }
    const validateOrders = OrderHeader.filter(
      (w) => orderList.includes(w.id) && orderStatus - w.statusId > 1
    );
    if (validateOrders.length > 0) {
      setMessage(
        "Invalid status selected, there are some orders which does not have valid for the selected status...."
      );
      showAlertMessage(true);
      return;
    }
    setButtonCaption("Updating Status...");
    const prm = {
      ...{ orderIds: orderList },
      ...{ userId: loginInfo.id },
      ...{ statusId: orderStatus },
      ...{ isEdit: 0 },
      ...{ logisticsName: "" },
      ...{ trackingNo: "" },
    };
    setShowLoader(true);
    setTimeout(() => {
      apiHandler.updateOrderStatus(prm).then((w) => {
        if (w[0].result === "Success") {
          userList.map((w) => {
            let mobileNumber = w.mobile;
            let status = allStatus.filter((w) => w.id === orderStatus);
            const currentStatus = status ? status[0].description : "";
            const smsTextVar1 = `Your Order ${w.orderNumber} has been ${currentStatus}!, `;
            const smsTextVar2 = "Stay tuned!";
            globalFunction.sendSMS(2, mobileNumber, smsTextVar1, smsTextVar2);
          });

          setShowLoader(false);
        }
      });
      setforceRefreshScreen(!forceRefreshScreen);
    }, 500);
  };
  const onEdit = (selectedId) => {
    const data = globaldata.filter((w) => w.id === selectedId)[0];
    setEdit(true);
    showHidePopup(true);
    setEditData(data);
  };
  const onSave = () => {
    let ids = [];
    ids.push(editableData.id);
    const data = {
      ...{ orderIds: ids },
      ...{ userId: loginInfo.id },
      ...{ statusId: editableData.statusId },
      ...{ isEdit: 1 },
      ...{ logisticsName: editableData.logisticsName },
      ...{ trackingNo: editableData.TrackingNo },
    };
    if (data === undefined) {
      setMessage("Please enter data before save.");
      showMessage(true);
      setTimeout(() => showMessage(false), 1000);
      return;
    } else {
      setMessage("");
    }
    setShowLoader(true);

    setTimeout(() => {
      apiHandler.updateOrderStatus(data).then((w) => {
        if (w[0].result === "Success") {
          setMessage("Data Saved Successfully!");
        } else {
          setMessage("Faild to Save Data!");
        }
        setShowLoader(false);
        showMessage(true);
        setTimeout(() => showMessage(false), 1000);
      });
      showHidePopup(false);
    }, 500);
  };

  // const validateInput = () => {
  //   if (editableData.category === "" || editableData.category === null) {
  //     setErrorMessage("Category cannot be blank..");
  //     setShowValidationMessage(true);
  //     return false;
  //   }
  //   if (imgSize > 2097152) {
  //     setErrorMessage("Upload file size cannot be greater then 2MB!");
  //     setShowValidationMessage(true);
  //     return false;
  //   }
  // };
  // const ShowConfirmaton = (selectedCatoryId) => {
  //   setOpenConfirmationBox(true);
  //   setSeletedId(selectedCatoryId);
  // };

  const onchange = (e) => {
    console.log("onChanges", e);
    setEditData({
      ...editableData,
      [e.target.id]: e.target.value,
    });
  };
  const uploadInvoice = () => {
    let input = document.createElement("input");
    input.type = "file";
    input.multiple = true;
    input.accept = ".pdf";
    input.title = "Select Invoice to upload..";
    console.log("input", input);
    input.onchange = async (_) => {
      let files = Array.from(input.files);
      if (files.length > 0) {
        console.log("files", files);
        //if (prm === "InventoryImages") {
        setTimeout(() => {
          uploadPDF(files);
          // uploadImageBatch(files).then((importResult) => {
          //   if (importResult.status === "Success") {
          //     showMessage(true);
          //     setMessage("Images Imported Successfully!");
          //   } else {
          //     showMessage(true);
          //     setMessage("Failed to Imported Images!");
          //   }
          // });
          // setTimeout(() => {
          //   showMessage(false);
          //   setShowConfirmationPopup(false);
          // }, 1000);
        }, 500);
      }
    };
    input.click();
  };
  const uploadPDF = (files) => {
    return new Promise(function (resolve, reject) {
      const importResult = [];
      Array.from(files).forEach((file) => {
        // let fileSize = file.size / 1024;
        if (file !== undefined) {
          setTimeout(() => {
            apiHandler
              .uploadFile(
                file,
                "invoice",
                "invoice",
                editableData.id,
                loginInfo.id
              )
              .then((el) => {
                if (el[0].status === "Success") {
                  setMessage("Invoice Uploaded Successfully!");
                } else {
                  setMessage("File upload failed!");
                }
                // setShowHidePopup(false);
                showMessage(true);
                setTimeout(() => {
                  showMessage(false);
                }, 1500);
              });
          }, 1000);
        }
      });
      // const errorList = importResult.filter((err) => err.status !== "Success");
      // if (errorList && errorList.length > 0) {
      //   setImportError(errorList);
      //   setShowErrorPopUp(true);
      // }
      return resolve({ status: "Success" });
    });
  };
  const handleDismiss = () => {
    showAlertMessage(false);
  };
  const onFilterData = (e) => {
    const fld = e.target.id;
    const val = e.target.value;
    let data = filterData;
    data[fld] = val;
    setFilterData(data);
    setApplyFilter(!applyFilter);
    console.log("filter", fld, val, filterData);
  };
  const renderAlertMessage = () => {
    return (
      <>
        {showAlert ? (
          <div
            class="alert alert-primary alert-dismissible fade show"
            role="alert"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="currentColor"
              class="bi bi-info-triangle-fill flex-shrink-0 me-2"
              viewBox="0 0 16 16"
              role="img"
              aria-label="Info:"
              style={{ float: "left" }}
            >
              <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" />
            </svg>
            <strong>{messageText}</strong>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="alert"
              aria-label="Close"
              onClick={() => handleDismiss()}
            ></button>
          </div>
        ) : null}
      </>
    );
  };
  const renderOrderGrid = () => {
    return (
      <>
        <div id="grid">
          {OrderHeader.map((ord) => (
            <div class="accordion" id={`accordion_${ord.id}`}>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingOne">
                  <button
                    class="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#collapseOne_${ord.id}`}
                    aria-expanded="true"
                    aria-controls={`collapseOne_${ord.id}`}
                    style={{
                      backgroundColor: "#EAECEE",
                      color: "white",
                      fontWeight: "bold",
                    }}
                    id={`btn_${ord.id}`}
                  >
                    <table
                      class="table table-striped table-hover table-bordered "
                      style={{ height: 10 }}
                    >
                      <tbody>{renderHeaderGrid(ord)}</tbody>
                    </table>
                  </button>
                </h2>
                <div
                  id={`collapseOne_${ord.id}`}
                  class="accordion-collapse collapse"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample"
                >
                  <div
                    class="accordion-body"
                    // style={{ backgroundColor: "#F5F5F5" }}
                  >
                    <div
                      class="row row-cols-5"
                      style={{
                        fontWeight: "bold",
                        borderBottom: "solid",
                        borderWidth: 1,
                        height: 25,
                      }}
                    >
                      <div class="col">Item Name</div>
                      <div class="col">Category</div>
                      <div class="col">Brand</div>
                      <div class="col">Qty</div>
                      <div class="col">Amount</div>
                    </div>
                    {OrderDetail.filter((w) => w.id === ord.id).map(
                      (bodyData) => (
                        <div
                          id={`row_${bodyData.id}`}
                          style={{
                            borderBottom: "solid",
                            borderWidth: 0,
                          }}
                        >
                          <div class="row">
                            <div
                              class="col"
                              style={{ borderWidth: 1, borderColor: "black" }}
                            >
                              <label>{bodyData.productName}</label>
                            </div>
                            <div class="col">
                              <label>{bodyData.category}</label>
                            </div>
                            <div class="col">
                              <label>{bodyData.brandName}</label>
                            </div>
                            <div class="col">
                              <label>{bodyData.qty}</label>
                            </div>
                            <div class="col">
                              <label>{bodyData.netAmount}</label>
                            </div>
                          </div>
                        </div>
                      )
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </>
    );
  };
  const renderHeaderGrid = (ord) => {
    return (
      <tr
        //className="table-info"
        id={ord.id}
        style={{
          border: "none",
          // color: "white",
          backgroundColor: "#EAECEE",
          height: 10,
        }}
      >
        <td
          className="col-xs"
          style={{ borderWidth: 0, paddingTop: 20, width: 10, color: "black" }}
        >
          <input
            type="checkbox"
            value=""
            id="flexCheckDefault"
            onChange={(e) =>
              selectOrder(e, ord.id, ord.userId, ord.orderNumber)
            }
            style={{ width: 20, height: 20 }}
          />
        </td>
        <td className="col-xs" style={actionButtonStyle}>
          <Icon
            className="edit"
            onClick={(e) => onEdit(ord.id)}
            id={ord.id}
            size="large"
          />
        </td>
        <td
          style={{
            borderWidth: 0,
            width: 20,
            paddingTop: 20,
          }}
        >
          {ord.orderNumber}
        </td>
        <td
          style={{
            borderWidth: 0,
            width: 200,
            paddingTop: 20,
          }}
        >
          {ord.ownerName}
        </td>
        <td
          style={{
            borderWidth: 0,
            width: 150,
            paddingTop: 20,
          }}
        >
          {ord.state}
        </td>
        <td
          style={{
            borderWidth: 0,
            width: 150,
            paddingTop: 20,
          }}
        >
          {ord.userId}
        </td>
        <td
          style={{
            borderWidth: 0,
            width: 150,
            paddingTop: 20,
          }}
        >
          {moment(ord.OrderDate).format("DD/MM/YYYY")}
        </td>
        <td
          style={{
            borderWidth: 0,
            width: 100,
            paddingTop: 20,
          }}
        >
          {ord.netAmount}
        </td>
        <td
          style={{
            borderWidth: 0,
            width: 100,
            paddingTop: 20,
          }}
        >
          {ord.deliveryCharges}
        </td>
        <td
          style={{
            borderWidth: 0,
            width: 150,
            paddingTop: 20,
          }}
        >
          {ord.OrderStatus}
        </td>
      </tr>
    );
  };
  const renderPopupBody = () => {
    return (
      <>
        <div class="row">
          <div class="col">
            <Icon
              name="cloud upload"
              style={{ float: "right" }}
              onClick={() => uploadInvoice()}
              size="large"
            />
            <span
              style={{
                float: "right",
                paddingRight: 10,
                paddingTop: 2,
                color: "blue",
                fontWeight: "bold",
              }}
            >
              {editableData.invoiceDocument
                ? editableData.invoiceDocument
                : "Upload Invoice"}
            </span>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div className="col form-floating">
              <input
                type="text"
                class="form-control"
                aria-label="Order#"
                value={editableData.orderNumber}
                id="id"
                disabled
              />
              <label for="orderStatus" style={{ marginTop: 2 }}>
                Order #
              </label>
            </div>
          </div>
          <div class="col">
            <div className="col form-floating">
              <input
                type="text"
                class="form-control"
                aria-label="Order Date"
                value={moment(editableData.OrderDate).format("DD/MM/YYYY")}
                id="OrderDate"
                disabled
              />
              <label for="orderStatus" style={{ marginTop: 2 }}>
                Order Date
              </label>
            </div>
          </div>
          <div className="col form-floating">
            <select
              class="form-select"
              style={{ marginTop: 8, height: 55 }}
              value={editableData.statusId}
              id="statusId"
              onChange={(e) => onchange(e)}
            >
              <option value="-1">Choose Status</option>
              {allStatus.map((el) => (
                <option value={el.id}>{el.description}</option>
              ))}
            </select>
            <label for="orderStatus" style={{ marginTop: 4, paddingLeft: 20 }}>
              Select Status
            </label>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div className="col form-floating">
              <input
                type="text"
                class="form-control"
                aria-label="Customer Name"
                value={editableData.ownerName}
                id="ownerName"
                disabled
              />
              <label for="ownerName" style={{ marginTop: 2 }}>
                Customer Name
              </label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div className="col form-floating">
              <textarea
                type="text"
                class="form-control"
                aria-label="Address"
                value={editableData.address}
                id="address"
                disabled
                rows="3"
              />
              <label for="address" style={{ marginTop: 2 }}>
                Address
              </label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div className="col form-floating">
              <input
                type="text"
                class="form-control"
                aria-label="City"
                value={editableData.city}
                id="city"
                disabled
              />
              <label for="City" style={{ marginTop: 2 }}>
                City
              </label>
            </div>
          </div>
          <div class="col">
            <div className="col form-floating">
              <input
                type="text"
                class="form-control"
                aria-label="State"
                value={editableData.state}
                id="state"
                disabled
              />
              <label for="state" style={{ marginTop: 2 }}>
                State
              </label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div className="col form-floating">
              <input
                type="text"
                class="form-control"
                aria-label="State"
                value={editableData.userId}
                id="userId"
                disabled
              />
              <label for="state" style={{ marginTop: 2 }}>
                Mobile#
              </label>
            </div>
          </div>
          <div class="col">
            <div className="col form-floating">
              <input
                type="text"
                class="form-control"
                aria-label="logisticsName"
                value={editableData.logisticsName}
                id="logisticsName"
                onChange={(e) => onchange(e)}
              />
              <label for="logisticsName" style={{ marginTop: 2 }}>
                Logistics Name
              </label>
            </div>
          </div>
          <div class="col">
            <div className="col form-floating">
              <input
                type="text"
                class="form-control"
                aria-label="TrackingNo"
                value={editableData.trackingNo}
                id="TrackingNo"
                onChange={(e) => onchange(e)}
              />
              <label for="TrackingNo" style={{ marginTop: 2 }}>
                Tracking Number
              </label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div className="col form-floating">
              <input
                type="text"
                class="form-control"
                aria-label="SubTotal"
                value={editableData.subTotal}
                id="SubTotal"
                disabled
              />
              <label for="SubTotal" style={{ marginTop: 2 }}>
                SubTotal
              </label>
            </div>
          </div>
          <div class="col" style={{ marginLeft: -7 }}>
            <div className="col form-floating">
              <input
                type="text"
                class="form-control"
                aria-label="discount"
                value={editableData.discount}
                id="discount"
                disabled
              />
              <label for="discount" style={{ marginTop: 2 }}>
                Discount
              </label>
            </div>
          </div>
          <div class="col" style={{ marginLeft: -7 }}>
            <div className="col form-floating">
              <input
                type="text"
                class="form-control"
                aria-label="deliveryCharges"
                value={editableData.deliveryCharges}
                id="deliveryCharges"
                disabled
              />
              <label for="deliveryCharges" style={{ marginTop: 2 }}>
                Freight
              </label>
            </div>
          </div>
          <div class="col" style={{ marginLeft: -7 }}>
            <div className="col form-floating">
              <input
                type="text"
                class="form-control"
                aria-label="GSTAmount"
                value={editableData.gSTAmount}
                id="GSTAmount"
                disabled
              />
              <label for="GSTAmount" style={{ marginTop: 2 }}>
                GST Amount
              </label>
            </div>
          </div>
          <div class="col" style={{ marginLeft: -7 }}>
            <div className="col form-floating">
              <input
                type="text"
                class="form-control"
                aria-label="lpAmount"
                value={editableData.lpAmount}
                id="lpAmount"
                disabled
              />
              <label for="lpAmount" style={{ marginTop: 2 }}>
                Loyalty Discount
              </label>
            </div>
          </div>
          <div class="col" style={{ marginLeft: -7 }}>
            <div className="col form-floating">
              <input
                type="text"
                class="form-control"
                aria-label="netAmount"
                value={editableData.netAmount}
                id="netAmount"
                disabled
              />
              <label for="netAmount" style={{ marginTop: 2 }}>
                Order Total
              </label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div
              style={
                showValidationMessage === true
                  ? { display: "block", color: "red" }
                  : { color: "red", display: "none" }
              }
            >
              {errorMessage}
            </div>
          </div>
        </div>
      </>
    );
  };
  const renderAddEditPopup = () => {
    return (
      <div
        class="modal"
        tabindex="-1"
        style={showPopup ? { display: "block" } : {}}
        id="dataEntry"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div
              class="modal-header"
              style={{ backgroundColor: "black", color: "white" }}
            >
              <h5 class="modal-title">{popupTitle}</h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => showHidePopup(false)}
                style={{ backgroundColor: "#ff0000" }}
              ></button>
            </div>
            <div class="modal-body">{renderPopupBody()}</div>
            <div class="modal-footer">
              <div class="row" style={{ width: "100%" }}>
                <div class="col">
                  <button
                    type="button"
                    class="btn btn-primary"
                    onClick={() => {
                      onSave();
                    }}
                  >
                    Save changes
                  </button>
                </div>
                <div class="col">
                  <button
                    type="button"
                    class="btn btn-secondary"
                    data-bs-dismiss="modal"
                    onClick={() => setShowHidePopup(false)}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const selectOrder = (e, id, mobile, ordNumber) => {
    //setOrderList;
    let orders = [];
    let users = [];
    orders = orderList;
    users = userList;
    console.log("ordNumber", ordNumber);
    if (e.target.checked) {
      orders.push(id);
      users.push({ orderNumber: ordNumber, mobile: mobile });
    } else {
      orders = orders.filter((w) => w !== id);
      users = users.filter((w) => w !== mobile);
    }
    setUserList(users);
    setOrderList(orders);
  };
  const onStatusChange = (e) => {
    setOrderStatus(e.target.value);
  };
  const showAllOrders = (e) => {
    if (e.target.checked) {
      const headerData = globaldata;
      setOrderHeader(headerData);
    } else {
      const headerData = globaldata.filter((w) => w.statusId < 5);
      setOrderHeader(headerData);
    }

    //setforceRefreshScreen(!forceRefreshScreen);
  };
  const renderFilterRow = () => {
    return (
      <div
        id="filterRow"
        class="container"
        style={{
          position: "fixed",
          top: 225,
          marginLeft: 1,
          backgroundColor: "black",
          width: "84.5%",
        }}
      >
        <div style={{ marginLeft: 40 }}>
          <table>
            <tr>
              <th scope="col-xs" style={{ width: 10 }}>
                <div style={{ marginTop: -35 }}></div>
              </th>
              <th scope="col-xs" style={{ width: 10 }}>
                <div style={{ marginTop: -35, width: 10 }}></div>
              </th>
              <th scope="col">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Order Number"
                  id="orderNumber"
                  value={filters.orderNumber}
                  onChange={(e) => onFilterData(e)}
                  style={{
                    height: "37px",
                    fontSize: 12,
                    width: 150,
                  }}
                />
              </th>
              <th scope="col">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Customer"
                  id="ownerName"
                  value={filters.ownerName}
                  onChange={(e) => onFilterData(e)}
                  style={{
                    height: "37px",
                    fontSize: 12,
                    width: 215,
                  }}
                />
              </th>
              <th scope="col">
                <input
                  type="text"
                  class="form-control"
                  placeholder="State"
                  id="state"
                  value={filters.state}
                  onChange={(e) => onFilterData(e)}
                  style={{
                    height: "37px",
                    fontSize: 12,
                    width: 150,
                  }}
                />
              </th>
              <th scope="col">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Mobile"
                  id="userId"
                  value={filters.userId}
                  onChange={(e) => onFilterData(e)}
                  style={{
                    height: "37px",
                    fontSize: 12,
                    width: 150,
                  }}
                />
              </th>
              <th scope="col">
                <input
                  type="date"
                  class="form-control"
                  id="OrderDate"
                  value={filters.OrderDate}
                  onChange={(e) => onFilterData(e)}
                  style={{
                    height: "37px",
                    fontSize: 12,
                    width: 150,
                  }}
                />
              </th>
              <th scope="col">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Order Total"
                  id="netAmount"
                  value={filters.netAmount}
                  onChange={(e) => onFilterData(e)}
                  style={{
                    height: "37px",
                    fontSize: 12,
                    width: 150,
                  }}
                />
              </th>
              <th scope="col">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Fright"
                  id="deliveryCharges"
                  value={filters.deliveryCharges}
                  onChange={(e) => onFilterData(e)}
                  style={{
                    height: "37px",
                    fontSize: 12,
                    width: 100,
                  }}
                />
              </th>
              <th scope="col">
                <select
                  class="form-select"
                  style={{ height: "37px", fontSize: 12, width: 150 }}
                  value={filters.statusId}
                  id="statusId"
                  onChange={(e) => onFilterData(e)}
                >
                  <option value="-1">Choose Status</option>
                  {OrderStatusList.map((el) => (
                    <option value={el.id}>{el.description}</option>
                  ))}
                </select>
              </th>
            </tr>
          </table>
        </div>
      </div>
    );
  };
  const exportDataToExcel = () => {
    console.log("OrderHeader", OrderHeader);
    let header = [
      "id",
      "User",
      "Order Date",
      "User Name",
      "Address",
      "City",
      "State",
      "Total",
      "Discount",
      "Delivery Charges",
      "GST Amount",
      "loyalty Point Amount",
      "Net Amount",
      "status Update On",
      "loyalty Point Earned",
      "Logistics Name",
      "Tracking No",
      "Product Name",
      "Category",
      "Brand Name",
      "qty",
      "sGST",
      "cGST",
      "iGST",
      "Order Status",
      "status Id",
      "Order Number",
      "Invoice Document",
    ];
    globalFunction.exportJSONDataToExcel(
      OrderHeader,
      "Order List",
      header,
      false
    );
  };
  const renderIncludeDeliveredOrders = () => {
    return (
      <div>
        <input
          type="checkbox"
          role="switch"
          id="active"
          style={{ marginLeft: 10, marginTop: -20 }}
          onChange={(e) => showAllOrders(e)}
          class="form-check-input"
          ref={contextRef}
        />
      </div>
    );
  };
  return (
    <>
      {renderAlertMessage()}
      <div className="container">
        <div style={{ float: "right" }}>
          <button
            type="button"
            class="btn btn-success btn-md"
            onClick={() => updateOrderStatus()}
            style={{ width: 160, marginTop: -12, height: 45, marginRight: 10 }}
          >
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
              style={
                showLoader
                  ? { display: "block", paddingRight: 5, float: "left" }
                  : { display: "none", paddingRight: 5 }
              }
            ></span>
            <span
              style={
                !showLoader
                  ? { display: "block", paddingRight: 5 }
                  : { display: "none", paddingRight: 5 }
              }
            ></span>
            {buttonCaption ? buttonCaption : "Update Status"}
          </button>
        </div>
        <div style={{ marginLeft: 600 }}>
          <Popup
            trigger={
              <Icon
                name="file excel"
                size="large"
                onClick={() => exportDataToExcel()}
              />
            }
            content="Export Data in excel"
            inverted
          />
        </div>
        <div
          style={{
            width: "15%",
            paddingBottom: 5,
            height: 35,
            float: "right",
            marginTop: -30,
            paddingRight: 5,
          }}
        >
          <div className="col form-floating">
            <select
              class="form-select"
              style={{ height: 50, marginTop: -5 }}
              value={orderStatus}
              id="orderStatus"
              onChange={onStatusChange}
            >
              <option value="-1">Choose Status</option>
              {OrderStatusList.map((el) => (
                <option value={el.id}>{el.description}</option>
              ))}
            </select>
            <label for="orderStatus" style={{ marginTop: -5 }}>
              Select Status
            </label>
          </div>
        </div>

        <div
          class="form-check form-switch"
          style={{ width: 480, marginLeft: -30 }}
        >
          <Popup
            inverted
            trigger={renderIncludeDeliveredOrders()}
            context={contextRef}
            content="Include Delivered Orders"
          />
        </div>
        {renderFilterRow()}
        <div
          class="table-responsive"
          style={{ marginTop: 103, maxHeight: 430, width: "100%" }}
        >
          {renderOrderGrid()}
        </div>
      </div>
      {renderAddEditPopup()}
      <TransitionablePortal open={open} transition={{ animation, duration }}>
        <Segment id="transationMessage">
          <Header>Information</Header>
          <p>{messageText}</p>
        </Segment>
      </TransitionablePortal>
      <Loader showLoader={showLoader} />
    </>
  );
};

export default Orders;
