import FileResizer from "react-image-file-resizer";
import assets from "../assets/assets";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import _ from "lodash";
import apiData from "./data";
import queryString from "query-string";
import { counter } from "@fortawesome/fontawesome-svg-core";

let rows;
const resizeFile = (file) =>
  new Promise((resolve) => {
    const imgSize = file.size / 1024;
    FileResizer.imageFileResizer(
      file,
      imgSize > 500 ? assets.ImageWidth : imgSize,
      imgSize > 500 ? assets.ImageHeight : imgSize,
      file.type,
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      "base64"
    );
  });
const dataURLtoFile = (dataurl, filename) => {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
};
const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "INR",

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});
const formatNumber = (num) => {
  return formatter.format(parseFloat(num));
};
const exportJSONDataToExcel = (json, fileName, excelHeader, onlyHeader) => {
  var data = json;
  const header = excelHeader;
  const fileFormat = "xlsx";
  const fileType = "xlsx";
  const ws = XLSX.utils.book_new();
  XLSX.utils.sheet_add_aoa(ws, [header]);
  XLSX.utils.sheet_add_json(ws, onlyHeader && onlyHeader === true ? [] : data, {
    origin: "A2",
    skipHeader: true,
  });
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  const excelBuffer = XLSX.write(wb, {
    bookType: fileType,
    type: "array",
    cellStyles: true,
  });
  const finalData = new Blob([excelBuffer], { type: fileFormat });
  saveAs(finalData, `${fileName}.xlsx`);
};
function importDataFromExcel(file) {
  rows = [];

  return new Promise(function (resolve, reject) {
    const reader = new FileReader();
    reader.onload = (event) => {
      const wb = XLSX.read(event.target.result);
      const sheets = wb.SheetNames;
      if (sheets.length) {
        const data = XLSX.utils.sheet_to_json(wb.Sheets[sheets[0]]);
        return resolve(data);
      }
    };
    reader.readAsArrayBuffer(file);
  });
}
const sendSMS = async (templateId, mobile, Var1, Var2) => {
  let smsUrl = "";
  let smsUser = "";
  let smsPassword = "";
  let senderId = "";
  let smsTemplate = "";
  let data;
  var header = {
    "Content-Type": "application/json",
  };
  await apiData.getConfigurations().then((config) => {
    data = config.filter((w) => w.configItem == "SMS_URL");
    smsUrl = data[0]["strConfigValue"];
    data = config.filter((w) => w.configItem == "SMS_USER");
    smsUser = data[0]["strConfigValue"];
    data = config.filter((w) => w.configItem == "SMS_PASSWORD");
    smsPassword = data[0]["strConfigValue"];
    data = config.filter((w) => w.configItem == "SMS_SENDERID");
    senderId = data[0]["strConfigValue"];
    data = config.filter((w) => w.configItem == `SMS_TEMPLATE${templateId}`);
    smsTemplate = data[0]["strConfigValue"];
    const message = buildMessage(smsTemplate, Var1, Var2);

    smsUrl = smsUrl
      .replace("#user_name", smsUser)
      .replace("#user_password", smsPassword)
      .replace("#mobileNos", mobile)
      .replace("#sender_id", senderId)
      .replace("#message", message);
    fetch(smsUrl, {
      method: "GET",
      headers: header,
    })
      .then((text) => {
        alert("SMS Sent successfully!");
      })
      .catch(function (error) {
        console.log("request failed", error);
      });
  });
};
const buildMessage = (smsTemplate, Var1, Var2) => {
  var data = smsTemplate.trim().split(" ");
  var smsText = [];
  let counter = 0;
  data.map((w) => {
    if (w.includes("{#var}")) {
      counter++;
      smsText.push(w.replace("{#var}", counter === 1 ? Var1 : Var2));
    } else {
      smsText.push(w);
    }
  });
  return smsText.join(" ");
};
export default {
  resizeFile,
  dataURLtoFile,
  exportJSONDataToExcel,
  importDataFromExcel,
  sendSMS,
  formatNumber,
};
