import React, { useEffect, useState } from "react";
import assets from "../assets/assets";
import apiHandler from "../commonfunction/data";
import Loader from "../components/Loader";
import { TransitionablePortal, Segment, Header, Icon } from "semantic-ui-react";
import ConfirmationBox from "../components/ConfirmationBox";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const actionButtonStyle = {
  width: 20,
};
const style = { marginTop: 8, height: 50 };
const dateFormat = "DD-MM-yyyy";
const initialData = {
  id: 0,
  startDate: moment(new Date()).format(dateFormat),
  endDate: moment(new Date()).format(dateFormat),
  minOrderValue: 0,
  maxOrderValue: 0,
  loyaltyPoints: 0,
  LoyaltyPointConversionRate: 0,
};
const LoyaltyPointConfig = (props) => {
  const [showPopup, setShowHidePopup] = useState(false);
  const [isEdit, setEdit] = useState(false);
  const [globaldata, setData] = useState([{}]);
  const [editableData, setEditData] = useState([{}]);
  const [popupTitle, setTitle] = useState("Loyalty Offer Setup");
  const [showLoader, setShowLoader] = useState(false);
  const [animation, setAnimation] = useState(assets.transitions[12]);
  const [duration, setduration] = useState(800);
  const [messageText, setMessage] = useState();
  const [ShowConfirmatonMsgBox, setShowConfirmatonMsgBox] = useState(false);
  const [open, showMessage] = useState(false);
  const [minDate, setMinDate] = useState(moment().format("yyyy-MM-DD"));
  const [showValidationMessage, setShowValidationMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [loginInfo, setLoginInfo] = useState();
  const navigation = useNavigate();
  const [selectedLPOfferId, setSelectedLPOfferId] = useState(0);

  useEffect(() => {
    setShowLoader(true);
    const loginData = JSON.parse(
      apiHandler.getDataFromLocalStorage("loginDetails")
    );
    setLoginInfo(loginData);
    if (
      loginData === null ||
      loginData.userName === "" ||
      loginData.password === ""
    ) {
      navigation("/");
    }
    setTimeout(() => {
      apiHandler.getLPOffers().then((data) => {
        setData(data);
        setShowLoader(false);
      });
    }, 1000);
  }, [open]);
  const ShowConfirmaton = (id) => {
    setSelectedLPOfferId(id);
    setMessage("Are you sure you want to delete the selected offer?");
    setShowConfirmatonMsgBox(true);
  };

  const onEdit = (id) => {
    const data = globaldata.filter((w) => w.id === id);
    setEditData(data[0]);
    setEdit(true);
    showHidePopup(true);
  };

  const onAddNew = () => {
    setEdit(false);
    showHidePopup(true);
    setEditData(initialData);
  };
  const onSave = () => {
    const data = {
      ...editableData,
      ...{ entryStatus: isEdit ? "update" : "new" },
      ...{ userId: loginInfo.id },
    };
    setShowLoader(true);
    showMessage(false);
    setTimeout(() => {
      apiHandler.saveLPOfferData(data).then((result) => {
        if (result[0].result === "Success") {
          setMessage("Data Saved Successfully!");
        } else {
          setMessage("Failed to save data!");
        }
        setShowLoader(false);
        showMessage(true);
        setShowHidePopup(false);
        setTimeout(() => showMessage(false), 1200);
      });
    }, 200);
  };
  const renderGridLoyaltyPointConfig = () => {
    return globaldata.map((w) => {
      return (
        <tr className="table-info" id={w.id}>
          <td className="col-xs" style={actionButtonStyle}>
            <Icon className="trash" onClick={() => ShowConfirmaton(w.id)} />
          </td>
          <td className="col-xs" style={actionButtonStyle}>
            <Icon className="edit" onClick={(e) => onEdit(w.id)} id={w.id} />
          </td>
          <td>{w.startDate}</td>
          <td>{w.endDate}</td>
          <td>{w.minOrderValue}</td>
          <td>{w.maxOrderValue}</td>
          <td>{w.loyaltyPoints}</td>
        </tr>
      );
    });
  };
  const onchange = (e) => {
    setEditData({
      ...editableData,
      [e.target.id]: e.target.value,
    });
  };
  const showHidePopup = (show) => {
    setShowHidePopup(show);
  };
  const renderPopupBody = () => {
    return (
      <>
        <div class="row" style={{ marginBottom: 10 }}>
          <div class="col">
            <div class="form-floating">
              <input
                type="date"
                class="form-control date-input"
                aria-label="startDate"
                id="startDate"
                style={style}
                value={editableData.startDate}
                min={minDate}
                onChange={(e) => onchange(e)}
                dateFormat={dateFormat}
                required
              />
              <label for="startDate">Start Date</label>
            </div>
          </div>
          <div className="col form-floating">
            <input
              type="date"
              class="form-control"
              placeholder={dateFormat}
              aria-label="endDate"
              id="endDate"
              style={style}
              min={minDate}
              value={editableData.endDate}
              onChange={(e) => onchange(e)}
              required
            />
            <label for="endDate" style={{ paddingTop: 25, paddingLeft: 22 }}>
              End Date
            </label>
          </div>
        </div>
        <div class="row" style={{ marginBottom: 10 }}>
          <div class="col">
            <div class="form-floating">
              <input
                type="number"
                class="form-control"
                placeholder="Min Order Value"
                aria-label="minOrderValue"
                value={editableData.minOrderValue}
                id="minOrderValue"
                onChange={(e) => onchange(e)}
                required
              />
              <label for="lblminOrderValue">Minimum Order Value</label>
            </div>
          </div>
          <div class="col">
            <div class="form-floating">
              <input
                type="number"
                class="form-control"
                placeholder="Min Order Value"
                aria-label="maxOrderValue"
                value={editableData.maxOrderValue}
                id="maxOrderValue"
                onChange={(e) => onchange(e)}
                required
              />
              <label for="lblmaxOrderValue">Maximum Order Value</label>
            </div>
          </div>
        </div>
        <div className="row">
          <div class="col">
            <div class="form-floating">
              <input
                type="number"
                class="form-control"
                placeholder="Loyalty Points"
                aria-label="loyaltyPoints"
                value={editableData.loyaltyPoints}
                id="loyaltyPoints"
                onChange={(e) => onchange(e)}
                required
              />
              <label for="lblloyaltyPoints">Loyalty Points</label>
            </div>
          </div>
          <div class="col">
            <div class="form-floating">
              <input
                type="number"
                class="form-control"
                placeholder="Loyalty Points"
                aria-label="LPConversionRate"
                value={editableData.LoyaltyPointConversionRate}
                id="LoyaltyPointConversionRate"
                onChange={(e) => onchange(e)}
                required
              />
              <label for="lblLPConversionRate">Conversion Rate</label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div
              style={
                showValidationMessage === true
                  ? { display: "block", color: "red" }
                  : { color: "red", display: "none" }
              }
            >
              {errorMessage}
            </div>
          </div>
        </div>
      </>
    );
  };
  const onCancel = () => {
    setShowConfirmatonMsgBox(false);
  };
  const onDelete = () => {
    const prm = { id: selectedLPOfferId, userId: loginInfo.id };
    console.log("prm", prm);
    setTimeout(() => {
      apiHandler.deleteLPOffer(prm).then((w) => {
        if (w[0].result === "Success") {
          setMessage("Data Deleted Successfully!");
        } else {
          setMessage("Faild to delete Data!");
        }
        setShowLoader(false);
        setShowConfirmatonMsgBox(false);
        showMessage(true);
        setTimeout(() => {
          showMessage(false);
        }, 1000);
      });
      showHidePopup(false);
    }, 500);
  };
  const renderConfirmationMessageBox = () => {
    return (
      <div>
        <ConfirmationBox
          onCancel={() => {
            onCancel();
          }}
          show={ShowConfirmatonMsgBox}
          message={messageText}
          onSuccess={() => onDelete()}
          width="30%"
          id="deleteConfirmaton"
        />
      </div>
    );
  };
  const renderAddEditPopup = () => {
    return (
      <div
        class="modal"
        tabindex="-1"
        style={
          showPopup
            ? {
                display: "block",
                width: "50%",
                position: "absolute",
                top: "5%",
                left: "25%",
              }
            : {}
        }
        id="dataEntry"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div
              class="modal-header"
              style={{ backgroundColor: "black", color: "white" }}
            >
              <h5 class="modal-title">{popupTitle}</h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => showHidePopup(false)}
                style={{ backgroundColor: "#ff0000" }}
              ></button>
            </div>
            <div class="modal-body">{renderPopupBody()}</div>
            <div class="modal-footer">
              <div class="row" style={{ width: "100%" }}>
                <div class="col">
                  <button
                    type="button"
                    class="btn btn-primary"
                    onClick={() => {
                      onSave();
                    }}
                  >
                    Save changes
                  </button>
                </div>
                <div class="col">
                  <button
                    type="button"
                    class="btn btn-secondary"
                    data-bs-dismiss="modal"
                    onClick={() => setShowHidePopup(false)}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <>
      <div className="container">
        <div style={{ display: "flex" }}>
          <div style={{ width: 100, paddingBottom: 5 }}>
            <button
              type="button"
              class="btn btn-primary btn-md"
              onClick={() => onAddNew()}
            >
              Add New
            </button>
          </div>
          <div style={{ marginLeft: "80%" }}>
            <input
              type="number"
              class="form-control"
              placeholder="Conversion Rate"
              aria-label="conversionRate"
              value={editableData.LoyaltyPointConversionRate}
              id="LPConversionRate"
              readOnly
            />
          </div>
        </div>
        {renderAddEditPopup()}
        <table class="table table-striped table-hover table-bordered ">
          <thead>
            <tr className="table-dark">
              <th scope="col-xs">#</th>
              <th scope="col-xs">#</th>
              <th scope="col">Start Date</th>
              <th scope="col">End Date</th>
              <th scope="col">Min Order Value</th>
              <th scope="col">Max Order Value</th>
              <th scope="col">Loyalty Points</th>
            </tr>
          </thead>
          <tbody>{renderGridLoyaltyPointConfig()}</tbody>
        </table>
      </div>
      <TransitionablePortal open={open} transition={{ animation, duration }}>
        <Segment
          // style={{
          //   left: "85%",
          //   position: "fixed",
          //   top: "20%",
          //   zIndex: 1000,
          //   backgroundColor: "green",
          //   color: "white",
          //   width: 350,
          // }}
          id="transationMessage"
        >
          <Header>Information</Header>
          <p>{messageText}</p>
        </Segment>
      </TransitionablePortal>
      <Loader showLoader={showLoader} />
      {renderConfirmationMessageBox()}
    </>
  );
};

export default LoyaltyPointConfig;
