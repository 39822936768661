import React, { useEffect, useState } from "react";
import { Button, Icon } from "semantic-ui-react";
import apiHandler from "../commonfunction/data";
import Loader from "../components/Loader";
import {
  Confirm,
  TransitionablePortal,
  Segment,
  Header,
} from "semantic-ui-react";
import assets from "../assets/assets";
import excelExport from "../commonfunction/globalFunction";
const initialData = {
  propName: "",
  propDescription: "",
  propSection: "",
  showProperty: 0,
  categoryId: 0,
  category: "",
  showPropertyInFilter: 0,
};
const HighLightAndSpecsConfig = (props) => {
  const [showPopup, setShowHidePopup] = useState(false);
  const [isEdit, setEdit] = useState(false);
  const [globaldata, setData] = useState([{}]);
  const [editableData, setEditData] = useState([{}]);
  const [popupTitle, setTitle] = useState("Inventory Property Config");
  const [open, showMessage] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [animation, setAnimation] = useState(assets.transitions[12]);
  const [duration, setduration] = useState(800);
  const [messageText, setMessage] = useState();
  const [openConfirmationBox, setOpenConfirmationBox] = useState(false);
  const [showValidationMessage, setShowValidationMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [selectedId, setSeletedId] = useState();
  const [loginInfo, setLoginInfo] = useState();
  const [categoryData, setCategoryData] = useState([{}]);

  const actionButtonStyle = {
    width: 20,
  };
  useEffect(() => {
    setShowLoader(true);
    const loginData = JSON.parse(
      apiHandler.getDataFromLocalStorage("loginDetails")
    );
    setLoginInfo(loginData);
    setTimeout(() => {
      apiHandler.getInvPropertyConfigData().then((result) => {
        setData(result);
        apiHandler.getCategory().then((catData) => {
          setCategoryData(catData);
          setShowLoader(false);
        });
      });
    }, 1000);
  }, [open]);
  useEffect(() => {
    const title = isEdit ? "Edit Inventory Property" : "New Inventory Property";
    setTitle(title);
  }, [isEdit]);

  const showHidePopup = (show) => {
    setShowHidePopup(show);
  };
  const onAddNew = () => {
    setEdit(false);
    showHidePopup(true);
    setShowValidationMessage(false);
    setEditData(initialData);
  };
  const onEdit = (selectedId) => {
    const data = globaldata.filter((w) => w.id === selectedId)[0];
    setEdit(true);
    showHidePopup(true);
    setEditData(data);
  };
  const onSave = () => {
    console.log("loginInfo@@", loginInfo);
    if (validateInput() === false) return;
    const data = {
      ...editableData,
      ...{ entryStatus: isEdit ? "update" : "new" },
      ...{ userId: loginInfo.id },
    };
    if (data === undefined) {
      setMessage("Please enter data before save.");
      showMessage(true);
      setTimeout(() => showMessage(false), 1000);
      return;
    } else {
      setMessage("");
    }
    setShowLoader(true);
    setTimeout(() => {
      apiHandler.saveInvPropConfiguration(data).then((w) => {
        if (w[0].result === "Success") {
          setMessage("Data Saved Successfully!");
        } else {
          setMessage("Faild to Save Data!");
        }
        setShowLoader(false);
        showMessage(true);
        setTimeout(() => showMessage(false), 1000);
      });

      showHidePopup(false);
    }, 500);
  };
  const validateInput = () => {
    if (editableData.propName === "" || editableData.propName === null) {
      setErrorMessage("Property cannot be blank..");
      setShowValidationMessage(true);
      return false;
    }
    if (
      parseInt(editableData.categoryId) <= 0 ||
      editableData.categoryId === null
    ) {
      setErrorMessage("Please select category..");
      setShowValidationMessage(true);
      return false;
    }
  };
  const ShowConfirmaton = (idSelected) => {
    setOpenConfirmationBox(true);
    setSeletedId(idSelected);
  };

  const onDelete = () => {
    const prm = { id: selectedId, userId: loginInfo.id };
    setTimeout(() => {
      apiHandler.deletePropertyConfig(prm).then((w) => {
        if (w[0].result === "Success") {
          setMessage("Data Deleted Successfully!");
        } else {
          setMessage("Faild to delete Data!");
        }
        setShowLoader(false);
        setOpenConfirmationBox(false);
        showMessage(true);
        setTimeout(() => {
          showMessage(false);
        }, 1000);
      });
      showHidePopup(false);
    }, 500);
  };
  const onchange = (e) => {
    setEditData({
      ...editableData,
      [e.target.id]:
        e.target.type === "checkbox"
          ? e.target.checked === true
            ? 1
            : 0
          : e.target.value,
    });
  };
  const handleCancel = () => {
    setOpenConfirmationBox(false);
  };
  const renderGrid = () => {
    return globaldata.map((w) => {
      return (
        <tr className="table-info" id={w.id}>
          <td className="col-xs" style={actionButtonStyle}>
            <Icon className="trash" onClick={() => ShowConfirmaton(w.id)} />
          </td>
          <td className="col-xs" style={actionButtonStyle}>
            <Icon className="edit" onClick={(e) => onEdit(w.id)} id={w.id} />
          </td>
          <td>{w.propName}</td>
          <td>{w.propDescription}</td>
          <td>
            {parseInt(w.propSection) === 1 ? "Heighlights" : "Specifications"}
          </td>
          <td>{w.category}</td>
          <td>{w.showProperty == 1 ? "Yes" : "No"}</td>
          <td>{w.showPropertyInFilter == 1 ? "Yes" : "No"}</td>
        </tr>
      );
    });
  };
  const renderPopupBody = () => {
    return (
      <>
        <div class="row">
          <div class="col">
            <input
              type="text"
              class="form-control"
              placeholder="Property Name"
              aria-label="PropertyName"
              value={editableData.propName}
              id="propName"
              onChange={(e) => onchange(e)}
              required
            />
          </div>
        </div>
        <div class="row">
          <div class="col">
            <input
              type="text"
              class="form-control"
              placeholder="Description"
              aria-label="Description"
              id="propDescription"
              value={editableData.propDescription}
              onChange={(e) => onchange(e)}
            />
          </div>
        </div>

        <div class="row">
          <div class="col">
            <div class="col">
              <select
                class="form-select"
                style={{ marginTop: 8, height: 46, marginBottom: 10 }}
                value={editableData.propSection}
                id="propSection"
                onChange={(e) => onchange(e)}
              >
                <option value="-1">Choose Option</option>
                <option value="1">Heighlights</option>
                <option value="2">Specifications</option>
              </select>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div className="col form-floating">
              <select
                class="form-select"
                style={{ marginTop: 4, height: 50 }}
                value={editableData.categoryId}
                id="categoryId"
                onChange={(e) => onchange(e)}
              >
                <option value="-1">Choose Category</option>
                {categoryData.map((el) => (
                  <option value={el.id}>{el.category}</option>
                ))}
              </select>
              <label for="categoryId" style={{ paddingTop: 6 }}>
                Choose Category
              </label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="form-check form-switch">
              <input
                class="form-check-input"
                type="checkbox"
                role="switch"
                id="showProperty"
                checked={
                  parseInt(editableData.showProperty) === 1 ? true : false
                }
                onChange={(e) => onchange(e)}
                style={{ marginTop: 10, float: "right" }}
              />
            </div>
          </div>
          <div class="col">
            <label style={{ marginTop: 12, float: "left" }}>
              Show Property
            </label>
          </div>
          <div class="col">
            <div class="form-check form-switch">
              <input
                class="form-check-input"
                type="checkbox"
                role="switch"
                id="showPropertyInFilter"
                checked={
                  parseInt(editableData.showPropertyInFilter) === 1
                    ? true
                    : false
                }
                onChange={(e) => onchange(e)}
                style={{ marginTop: 10, float: "right" }}
              />
            </div>
          </div>
          <div class="col">
            <label style={{ marginTop: 12, float: "left" }}>
              Show Property As Filter
            </label>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div
              style={
                showValidationMessage === true
                  ? { display: "block", color: "red" }
                  : { color: "red", display: "none" }
              }
            >
              {errorMessage}
            </div>
          </div>
        </div>
      </>
    );
  };
  const renderAddEditPopup = () => {
    return (
      <div
        class="modal"
        tabindex="-1"
        style={showPopup ? { display: "block" } : {}}
        id="dataEntry"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">{popupTitle}</h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => showHidePopup(false)}
              ></button>
            </div>
            <div class="modal-body">{renderPopupBody()}</div>
            <div class="modal-footer">
              <div class="row" style={{ width: "100%" }}>
                <div class="col">
                  <button
                    type="button"
                    class="btn btn-primary"
                    onClick={() => {
                      onSave();
                    }}
                  >
                    Save changes
                  </button>
                </div>
                <div class="col">
                  <button
                    type="button"
                    class="btn btn-secondary"
                    data-bs-dismiss="modal"
                    onClick={() => setShowHidePopup(false)}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const exportDataToExcel = (prm) => {
    if (prm === "Properties") {
      apiHandler.getInvPropertyConfigData().then((data) => {
        const exportData = data;
        let header = ["productId"];
        exportData.map((el) => {
          header.push(el.propName);
        });
        excelExport.exportJSONDataToExcel(
          exportData,
          "Inventory Property Header",
          header,
          true
        );
      });
    } else if (prm === "Inventory") {
      let header = [
        "productId",
        "productName",
        "productDescription",
        "category",
        "brandName",
        "mrp",
        "salesPrice",
        "sku",
        "urlLiveLink",
        "qty",
        "GSTPer",
        "hsncode",
        "appliancesType",
      ];
      excelExport.exportJSONDataToExcel([], "Inventory", header, true);
    } else if (prm === "InventoryImages") {
      let header = ["productId", "ImageUrl"];
      excelExport.exportJSONDataToExcel([], "Inventory Images", header, true);
    }
  };
  return (
    <>
      <Confirm
        open={openConfirmationBox}
        onCancel={handleCancel}
        content="Are you sure, you want to delete the Property?"
        header="Confirmation"
        onConfirm={onDelete}
        size="tiny"
        style={{ height: "30%", top: "10%", left: "35%" }}
      />
      <div className="container">
        <div style={{ width: "100%", paddingBottom: 5, height: 35 }}>
          <button
            type="button"
            class="btn btn-primary btn-md"
            onClick={() => onAddNew()}
            style={{ width: 100, float: "left" }}
          >
            Add New
          </button>

          <div class="dropdown" style={{ float: "right" }}>
            <button
              class="btn btn-secondary dropdown-toggle btn btn-danger"
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Export Excel Header
            </button>
            <ul
              class="dropdown-menu"
              aria-labelledby="dropdownMenuButton1"
              style={{ width: "250px" }}
            >
              <div
                style={{
                  display: "flex",
                  paddingTop: "3%",
                  paddingBottom: "2%",
                }}
              >
                <img
                  src={assets.excelExport}
                  style={{ height: 20, width: 30 }}
                />
                <li>
                  <input
                    type="button"
                    id="propHeader"
                    value={"Inventory Properties Header"}
                    onClick={() => exportDataToExcel("Properties")}
                    style={{
                      backgroundColor: "white",
                      border: "none",
                      width: 210,
                      textAlign: "left",
                    }}
                  />
                </li>
              </div>
              <div style={{ display: "flex", padding: "1%" }}>
                <img
                  src={assets.excelExport}
                  style={{ height: 20, width: 30 }}
                />
                <li style={{ marginTop: "-1%" }}>
                  <input
                    type="button"
                    id="invHeader"
                    value={"Inventory Header"}
                    onClick={() => exportDataToExcel("Inventory")}
                    style={{
                      backgroundColor: "white",
                      border: "none",
                      width: 210,
                      textAlign: "left",
                    }}
                  />
                </li>
              </div>
            </ul>
          </div>
        </div>
        <div class="table-responsive" style={{ maxHeight: 500 }}>
          <table
            class="table table-striped table-hover table-bordered"
            style={{ position: "sticky", top: 0 }}
          >
            <thead>
              <tr className="table-dark">
                <th scope="col-xs">#</th>
                <th scope="col-xs">#</th>
                <th scope="col">Property Name</th>
                <th scope="col">Description</th>
                <th scope="col">Property Section</th>
                <th scope="col">Category</th>
                <th scope="col">Show</th>
                <th scope="col">Show As Filter</th>
              </tr>
            </thead>
            <tbody>{renderGrid()}</tbody>
          </table>
        </div>
      </div>
      {renderAddEditPopup()}
      <TransitionablePortal open={open} transition={{ animation, duration }}>
        <Segment
          // style={{
          //   left: "85%",
          //   position: "fixed",
          //   top: "20%",
          //   zIndex: 1000,
          //   backgroundColor: "green",
          //   color: "white",
          //   width: 350,
          // }}
          id="transationMessage"
        >
          <Header>Information</Header>
          <p>{messageText}</p>
        </Segment>
      </TransitionablePortal>
      <Loader showLoader={showLoader} />
    </>
  );
};

export default HighLightAndSpecsConfig;
