import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import apiHandler from "../commonfunction/data";
import HighLightAndSpecsConfig from "../components/HighLightAndSpecsConfig";
import SaleOfferSetup from "./SaleOfferSetup";
import LoyaltyPointConfig from "../components/LoyaltyPointConfig";
import ActivityTracker from "./ActivityTracker";
import AreaSetup from "../components/AreaSetup";

const Configuration = (props) => {
  const [loginInfo, setLoginInfo] = useState();
  const [showLoader, setShowLoader] = useState(false);
  const [globaldata, setData] = useState([{}]);
  const navigation = useNavigate();

  useEffect(() => {
    props.showTitle("Configuration");
    props.hideSideBar(false);
    const loginData = JSON.parse(
      apiHandler.getDataFromLocalStorage("loginDetails")
    );
    setLoginInfo(loginData);
    if (
      loginData === null ||
      loginData.userName === "" ||
      loginData.password === ""
    ) {
      navigation("/");
    }
    setShowLoader(true);
    setTimeout(() => {
      apiHandler.getCategory().then((result) => {
        setData(result);
        setShowLoader(false);
      });
    }, 1000);
  }, []);

  return (
    <>
      <ul class="nav nav-pills" id="myTab" role="tablist">
        <li class="nav-item" role="presentation">
          <button
            class="nav-link active"
            id="home-tab"
            data-bs-toggle="tab"
            data-bs-target="#home-tab-pane"
            type="button"
            role="tab"
            aria-controls="home-tab-pane"
            aria-selected="true"
          >
            Heighlights & Specifications
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button
            class="nav-link"
            id="salesOffer-tab"
            data-bs-toggle="tab"
            data-bs-target="#salesOffer-tab-pane"
            type="button"
            role="tab"
            aria-controls="salesOffer-tab-pane"
            aria-selected="false"
          >
            Configure Sales Offer
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button
            class="nav-link"
            id="LP-tab"
            data-bs-toggle="tab"
            data-bs-target="#LP-tab-pane"
            type="button"
            role="tab"
            aria-controls="LP-tab-pane"
            aria-selected="false"
          >
            Loyalty Point Configuration
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button
            class="nav-link"
            id="Log-tab"
            data-bs-toggle="tab"
            data-bs-target="#Log-tab-pane"
            type="button"
            role="tab"
            aria-controls="Log-tab-pane"
            aria-selected="false"
          >
            Activity Log
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button
            class="nav-link"
            id="areaSetup-tab"
            data-bs-toggle="tab"
            data-bs-target="#areaSetup-tab-pane"
            type="button"
            role="tab"
            aria-controls="areaSetup-tab-pane"
            aria-selected="false"
          >
            Area Setup
          </button>
        </li>
      </ul>
      <div class="tab-content" id="myTabContent">
        <div
          class="tab-pane fade show active"
          id="home-tab-pane"
          role="tabpanel"
          aria-labelledby="home-tab"
          tabindex="0"
        >
          {<HighLightAndSpecsConfig title="Inventory Property Config" />}
        </div>
        <div
          class="tab-pane fade"
          id="salesOffer-tab-pane"
          role="tabpanel"
          aria-labelledby="salesOffer-tab"
          tabindex="0"
        >
          {<SaleOfferSetup title="Configure Sales Offer" />}
        </div>
        <div
          class="tab-pane fade"
          id="LP-tab-pane"
          role="tabpanel"
          aria-labelledby="LP-tab"
          tabindex="0"
        >
          {<LoyaltyPointConfig title="Loyalty Pont Configuration" />}
        </div>
        <div
          class="tab-pane fade"
          id="Log-tab-pane"
          role="tabpanel"
          aria-labelledby="Log-tab"
          tabindex="0"
        >
          {<ActivityTracker title="Activity Log" />}
        </div>
        <div
          class="tab-pane fade"
          id="areaSetup-tab-pane"
          role="tabpanel"
          aria-labelledby="areaSetup-tab"
          tabindex="0"
        >
          {<AreaSetup title="Area Setup" />}
        </div>
      </div>
    </>
  );
};

export default Configuration;
