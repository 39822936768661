import React, { useEffect, useState, useContext } from "react";
import apiHandler from "../commonfunction/data";
import Loader from "../components/Loader";
import assets from "../assets/assets";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import LoginContext from "../context/LoginContext";
import { Icon } from "semantic-ui-react";
import DateFilter from "../components/DateFilter";
import moment from "moment";

const ReportsManager = (props) => {
  const [open, showMessage] = useState(false);
  const loginAuth = useContext(LoginContext);
  const [loginInfo, setLoginInfo] = useState();
  const navigation = useNavigate();
  const [showLoader, setShowLoader] = useState(false);
  const [reportData, setReportData] = useState([{}]);
  const [reportList, setReportList] = useState([{}]);
  const [showDateFilter, setShowDateFilter] = useState(false);
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [selectedReport, setSelectedReport] = useState([]);
  const [forceRefresh, setForceRefresh] = useState(false);
  const [runReport, setRunReport] = useState(false);
  useEffect(() => {
    props.showTitle("Reports Manager");
    props.hideSideBar(false);
    const loginData = JSON.parse(
      apiHandler.getDataFromLocalStorage("loginDetails")
    );
    setLoginInfo(loginData);
    if (
      loginData === null ||
      loginData.userName === "" ||
      loginData.password === ""
    ) {
      navigation("/");
    }
    setShowLoader(true);
    setTimeout(() => {
      apiHandler.getReportsManagerData().then((reports) => {
        setReportData(reports);
        const data = _.uniqBy(reports, (obj) => obj.id);
        setReportList(data);
      });
      setShowLoader(false);
    }, 500);
  }, [open]);
  useEffect(() => {
    console.log("test");
  }, [showDateFilter]);
  useEffect(() => {
    console.log("selectedReportXX", selectedReport);
    if (runReport)
      onSuccess(
        moment(new Date()).format("MM/DD/YYYY"),
        moment(new Date()).format("MM/DD/YYYY")
      );
  }, [forceRefresh]);
  const onRunReport = (report) => {
    console.log("report", report);
    setSelectedReport(report);

    if (parseInt(report.hasDateFilter) === 1) {
      setShowDateFilter(true);
    } else {
      setTimeout(() => {
        setRunReport(true);
        setForceRefresh(!forceRefresh);
      }, 1000);
    }
  };
  const onHideDateFilter = () => {
    setShowDateFilter(false);
  };
  const onSuccess = (fromDt, toDt) => {
    setShowDateFilter(false);
    console.log(
      "selectedReport.reportName",
      selectedReport.reportName,
      selectedReport
    );
    navigation(`../reports/${selectedReport.reportName}`, {
      state: { reportConfig: selectedReport, startDate: fromDt, endDate: toDt },
    });
  };
  const renderGrid = () => {
    return reportList.map((w, index) => {
      return (
        <tr className="table-info" id={w.id}>
          <td className="col-xs">{index + 1}</td>
          <td className="col-xs">
            <i style={{ cursor: "pointer" }} onClick={() => onRunReport(w)}>
              <Icon name="play" />
            </i>
          </td>
          <td style={{ width: "30%" }}>{w.reportTitle}</td>
          <td style={{ width: "50%" }}>{w.reportDesc}</td>
          <td style={{ width: "20%" }}>{w.reportCategory}</td>
        </tr>
      );
    });
  };
  return (
    <>
      <div>
        <div class="table-responsive" style={{ maxHeight: 500 }}>
          <table class="table table-striped table-hover table-bordered ">
            <thead>
              <tr className="table-dark">
                <th scope="col-xs">#</th>
                <th style={{ width: 2 }}>#</th>
                <th scope="col">Report Name</th>
                <th scope="col">Report Description</th>
                <th scope="col">Category</th>
              </tr>
            </thead>
            <tbody>{renderGrid()}</tbody>
          </table>
        </div>
      </div>
      <Loader showLoader={showLoader} />
      <DateFilter
        show={showDateFilter}
        onHideDateFilter={onHideDateFilter}
        width="35%"
        onSuccess={onSuccess}
      />
    </>
  );
};

export default ReportsManager;
