import React, { useEffect, useState, useContext } from "react";
import { Header, Icon, Menu, Segment, Sidebar } from "semantic-ui-react";
import {
  BrowserRouter,
  Route,
  Routes,
  NavLink,
  HashRouter,
  Router,
} from "react-router-dom";
import Login from "../pages/Login";
import Home from "../pages/Home";
import ManageUsers from "../pages/ManageUsers";
import assets from "../assets/assets";
import LoginContext from "../context/LoginContext";
import data from "../commonfunction/data";
import Category from "../pages/Category";
import BrandMaster from "../pages/BrandMaster";
import Configuration from "../pages/Configuration";
import Inventory from "../pages/Inventory";
import Carousel from "../pages/Carousel";
import Orders from "../pages/Orders";
import ReportsManager from "../pages/ReportsManager";
import DailySalesDetails from "../reports/DailySalesDetails";
import CartLog from "../reports/CartLog";
import LoyaltyPointSummary from "../reports/LoyaltyPointSummary";
//import SaleOfferSetup from "../pages/SaleOfferSetup";

const AppBar = () => {
  const [visible, setVisible] = useState(false);
  const [showHide, setShowHide] = useState(true);
  const [windowHeight] = useState(window.innerHeight);
  const [title, setTitle] = useState();
  const loginContxt = useContext(LoginContext);
  const iconStyle = {
    height: 30,
    marginRight: 10,
    marginTop: -10,
  };
  const MenuStyle = {
    textAlign: "left",
    paddingLeft: "30px",
  };
  const menuTextStyle = {
    paddingLeft: 10,
    fontSize: 17,
    marginTop: 50,
  };
  useEffect(() => {
    console.log("user", loginContxt.auth.firstName);
  }, []);
  const showHideSideBar = (bool) => {
    setShowHide(bool);
  };
  const showTitle = (props) => {
    setTitle(props);
  };
  const logout = () => {
    const initialState = {
      userName: "",
      password: "",
      isAuthonticated: false,
      role: -1,
      firstName: "",
      lastName: "",
    };
    loginContxt.updateAuth(initialState);
    setShowHide(true);
    setVisible(false);
    data.deleteDataFromLocalStorage("loginDetails");
  };
  return (
    <>
      {/* <BrowserRouter> */}
      <Sidebar.Pushable
        as={Segment}
        style={{ height: "100vh", overflow: "hidden" }}
      >
        <Header>
          <nav
            class="navbar navbar-dark bg-dark"
            style={
              showHide
                ? { display: "block", height: 120 }
                : { display: "none", height: 0 }
            }
          >
            <div class="container-fluid">
              <img
                src={assets.Logo}
                style={{
                  width: 70,
                  height: 80,
                  backgroundColor: "white",
                  verticalAlign: "center",
                  marginTop: 15,
                  marginLeft: 10,
                }}
                class="rounded"
              />
              <button
                class="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarToggleExternalContent"
                aria-controls="navbarToggleExternalContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
                onClick={() => setVisible(!visible)}
                style={{ width: 50, alignItems: "self-end" }}
              >
                <span class="navbar-toggler-icon"></span>
              </button>
            </div>
          </nav>
          <div
            style={
              showHide
                ? {
                    width: "100%",
                    backgroundColor: "#778899",
                    height: 40,
                    paddingTop: 10,
                    display: "block",
                    color: "#e60000",
                    fontSize: 22,
                  }
                : {
                    width: "100%",
                    backgroundColor: "#778899",
                    height: 40,
                    paddingTop: 10,
                    display: "none",
                    color: "#e60000",
                    fontSize: 22,
                  }
            }
          >
            {title}
          </div>
        </Header>
        <div style={showHide ? { display: "block" } : { display: "none" }}>
          <Sidebar
            as={Menu}
            animation="overlay"
            icon="labeled"
            inverted
            onHide={() => setVisible(false)}
            vertical
            visible={visible}
            width="wide"
          >
            <div style={{ color: "white" }}>
              <img
                src={assets.AvtarLogo}
                style={{ height: 180, paddingTop: 15 }}
              />
              <br />
              <span>
                Welcome {loginContxt.auth.firstName} {loginContxt.auth.lastName}
              </span>
            </div>
            <hr style={{ color: "white" }} />
            <Menu.Item as="a" style={MenuStyle}>
              <NavLink to="/Home">
                <img src={assets.home} style={iconStyle} />
                <span style={menuTextStyle}>Home</span>
              </NavLink>
            </Menu.Item>
            <Menu.Item as="a" style={MenuStyle}>
              <NavLink to="/ManageUsers">
                <img src={assets.management} style={iconStyle} />
                <span style={menuTextStyle}>User Management</span>
              </NavLink>
            </Menu.Item>
            <Menu.Item as="a" style={MenuStyle}>
              <NavLink to="Category">
                <img
                  src={assets.categories}
                  style={{ height: 30, marginRight: 10 }}
                />
                <span style={menuTextStyle}>Category</span>
              </NavLink>
            </Menu.Item>
            <Menu.Item as="a" style={MenuStyle}>
              <NavLink to="/BrandMaster">
                <img src={assets.brand} style={iconStyle} />
                <span style={menuTextStyle}>Brands</span>
              </NavLink>
            </Menu.Item>
            <Menu.Item as="a" style={MenuStyle}>
              <NavLink to="/Caraousel">
                <img src={assets.carousel} style={iconStyle} />
                <span style={menuTextStyle}>Caraousel</span>
              </NavLink>
            </Menu.Item>
            <Menu.Item as="a" style={MenuStyle}>
              <NavLink to="/Inventory">
                <img src={assets.stock} style={iconStyle} />
                <span style={menuTextStyle}>Inventory</span>
              </NavLink>
            </Menu.Item>
            <Menu.Item as="a" style={MenuStyle}>
              <NavLink to="/Orders">
                <img src={assets.orders} style={iconStyle} />
                <span style={menuTextStyle}>Orders</span>
              </NavLink>
            </Menu.Item>
            <Menu.Item as="a" style={MenuStyle}>
              <NavLink to="/Configuration">
                <img src={assets.config} style={iconStyle} />
                <span style={menuTextStyle}>Configuration</span>
              </NavLink>
            </Menu.Item>
            <Menu.Item as="a" style={MenuStyle}>
              <NavLink to="/ReportsManager">
                <img src={assets.reporting} style={iconStyle} />
                <span style={menuTextStyle}>Reports Manager</span>
              </NavLink>
            </Menu.Item>
            <Menu.Item as="a" style={MenuStyle}>
              <NavLink to="" onClick={logout}>
                <img src={assets.logout} style={iconStyle} />
                <span style={menuTextStyle}>Logout</span>
              </NavLink>
            </Menu.Item>
          </Sidebar>
        </div>
        <Sidebar.Pusher>
          <Routes>
            <Route
              path=""
              element={<Login showHideSideBar={showHideSideBar} />}
            />
            <Route
              exact
              path="/Home"
              element={<Home showTitle={showTitle} hideSideBar={setVisible} />}
            />
            <Route
              exact
              path="/ManageUsers"
              element={
                <ManageUsers showTitle={showTitle} hideSideBar={setVisible} />
              }
            />
            <Route
              exact
              path="/Category"
              element={
                <Category showTitle={showTitle} hideSideBar={setVisible} />
              }
            />
            <Route
              exact
              path="/BrandMaster"
              element={
                <BrandMaster showTitle={showTitle} hideSideBar={setVisible} />
              }
            />
            <Route
              exact
              path="/Inventory"
              element={
                <Inventory showTitle={showTitle} hideSideBar={setVisible} />
              }
            />
            <Route
              exact
              path="/Orders"
              element={
                <Orders showTitle={showTitle} hideSideBar={setVisible} />
              }
            />
            <Route
              exact
              path="/Caraousel"
              element={
                <Carousel showTitle={showTitle} hideSideBar={setVisible} />
              }
            />
            <Route
              exact
              path="/Configuration"
              element={
                <Configuration showTitle={showTitle} hideSideBar={setVisible} />
              }
            />
            <Route
              exact
              path="/ReportsManager"
              element={
                <ReportsManager
                  showTitle={showTitle}
                  hideSideBar={setVisible}
                />
              }
            />
            <Route
              exact
              path="/reports/DailySalesDetail"
              element={<DailySalesDetails showTitle={showTitle} />}
            />
            <Route
              exact
              path="/reports/CartLog"
              element={<CartLog showTitle={showTitle} />}
            />
            <Route
              exact
              path="/reports/LoyaltyPointSummary"
              element={<LoyaltyPointSummary showTitle={showTitle} />}
            />
          </Routes>
        </Sidebar.Pusher>
      </Sidebar.Pushable>
      {/* </BrowserRouter> */}
    </>
    //   </Grid.Column>
    // </Grid>
  );
};

export default AppBar;
