import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "../pages/Login";
import Home from "../pages/Home";
import ManageUsers from "../pages/ManageUsers";

function Routing() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="" element={<Login />} />
        <Route exact path="/Home" element={<Home />} />
        <Route exact path="/ManageUsers" element={<ManageUsers />} />
      </Routes>
    </BrowserRouter>
  );
}

export default Routing;
