import React, { useEffect, useState } from "react";
import { Dropdown } from "semantic-ui-react";
// import { DatePicker } from "@mui/x-date-pickers/DatePicker";
// import { LocalizationProvider } from "@mui/x-date-pickers";
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import dayjs, { Dayjs } from "dayjs";
import moment from "moment";

const options = [
  {
    key: 1,
    text: "All Dates",
    fromDate: "01/01/2000",
    toDate: "31/12/2099",
    value: 1,
  },
  {
    key: 2,
    text: "Today",
    fromDate: moment().format("DD/MM/YYYY"),
    toDate: moment().format("DD/MM/YYYY"),
    value: 2,
  },
  {
    key: 3,
    text: "This Year",
    fromDate: `01/01/${moment().year()}`,
    toDate: `31/12/${moment().year()}`,
    value: 3,
  },
  {
    key: 4,
    text: "This Month",
    fromDate: moment().startOf("month").format("DD/MM/YYYY"),
    toDate: moment().format("DD/MM/YYYY"),
    value: 4,
  },
  {
    key: 5,
    text: "This Week",
    fromDate: moment().startOf("week").format("DD/MM/YYYY"),
    toDate: moment().endOf("week").format("DD/MM/YYYY"),
    value: 5,
  },
  {
    key: 6,
    text: "Last Year",
    fromDate: `01/01/${moment().year() - 1}`,
    toDate: `31/12/${moment().year() - 1}`,
    value: 6,
  },
  {
    key: 7,
    text: "Last Month",
    fromDate: moment(`01/${moment().month() - 1}/${moment().year()}`).format(
      "DD/MM/YYYY"
    ),
    toDate: moment()
      .add("days", moment().date() * -1)
      .format("DD/MM/YYYY"),
    value: 7,
  },
  {
    key: 8,
    text: "Last Week",
    fromDate: moment().startOf("week").subtract(7, "days").format("DD/MM/YYYY"),
    toDate: moment().endOf("week").subtract(7, "days").format("DD/MM/YYYY"),
    value: 8,
  },
  {
    key: 9,
    text: "Year To Date",
    fromDate: `01/01/${moment().year()}`,
    toDate: moment().format("DD/MM/YYYY"),
    value: 9,
  },
  {
    key: 10,
    text: "Month To Date",
    fromDate: `01/${moment().month()}/${moment().year()}`,
    toDate: moment().format("DD/MM/YYYY"),
    value: 10,
  },
  {
    key: 11,
    text: "Week To Date",
    fromDate: moment().startOf("week").format("DD/MM/YYYY"),
    toDate: moment().format("DD/MM/YYYY"),
    value: 11,
  },
];
var formatDt = "DD-MM-YYYY";
const DateFilter = (props) => {
  const [fromDate, setFromDate] = useState(moment());
  const [toDate, setToDate] = useState(moment());
  const [selectedOption, setSelectedOption] = useState(4);
  const [refreshScreen, setRefreshScreen] = useState(false);
  useEffect(() => {
    console.log(
      "DateRange",
      moment(fromDate, formatDt).format("YYYY-MM-DD"),
      moment(toDate, formatDt).format("YYYY-MM-DD"),
      selectedOption
    );
    const data = options.filter(
      (w) => parseInt(w.key) === parseInt(selectedOption)
    );
    setFromDate(moment(data[0].fromDate, formatDt).format("YYYY-MM-DD"));
    setToDate(moment(data[0].toDate, formatDt).format("YYYY-MM-DD"));
  }, [selectedOption, refreshScreen]);

  const onHideDateFilter = () => {
    props.onHideDateFilter();
  };
  const onOptionChange = (e, data) => {
    setSelectedOption(data.value);
    setRefreshScreen(!refreshScreen);
  };
  const onChangeDate = (e) => {
    console.log("DATE", e.target.value);
    if (e.target.id === "fromDate") {
      setFromDate(moment(e.target.value).format("YYYY-MM-DD"));
    } else {
      setToDate(moment(e.target.value).format("YYYY-MM-DD"));
    }
  };
  const onRunReport = () => {
    props.onSuccess(fromDate, toDate);
  };
  return (
    <div
      class="modal"
      tabindex="-1"
      style={
        props.show
          ? {
              display: "block",
              width: props.width,
              position: "absolute",
              top: "15%",
              left: "30%",
            }
          : { display: "none" }
      }
      //   id={props.id ? props.id : "confirmationMsg"}
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div
            class="modal-header"
            style={{ backgroundColor: "black", color: "white" }}
          >
            <h5 class="modal-title">Date Filter</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              style={{ backgroundColor: "#ff0000" }}
              onClick={() => onHideDateFilter()}
            ></button>
          </div>
          <div class="modal-body">
            <div>
              <Dropdown
                options={options}
                search
                fluid
                selection
                openOnFocus
                style={{ float: "left", width: 508 }}
                placeholder="Select Dates"
                value={selectedOption}
                onChange={onOptionChange}
                key={options.key}
              />
            </div>
            <div class="row" style={{ paddingTop: 15 }}>
              <div class="col" style={{ paddingTop: 5 }}>
                <label>From Date: </label>
              </div>
              <div class="col">
                <input
                  id="fromDate"
                  class="form-control"
                  type="date"
                  style={{ float: "left" }}
                  value={moment(fromDate).format("YYYY-MM-DD")}
                  onChange={(e) => onChangeDate(e)}
                />
              </div>
              <div class="col" style={{ paddingTop: 5 }}>
                <label>To Date: </label>
              </div>
              <div class="col">
                <input
                  id="toDate"
                  class="form-control"
                  type="date"
                  value={moment(toDate).format("YYYY-MM-DD")}
                  onChange={(e) => onChangeDate(e)}
                />
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
              style={{ width: "20%" }}
              onClick={() => onHideDateFilter()}
            >
              Close
            </button>
            <button
              type="button"
              class="btn btn-success"
              onClick={() => onRunReport()}
              style={{ width: "20%" }}
            >
              Run
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DateFilter;
