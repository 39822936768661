import React, { useEffect, useState } from "react";
import assets from "../assets/assets";
import apiHandler from "../commonfunction/data";
import Loader from "../components/Loader";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import {
  Dropdown,
  TransitionablePortal,
  Segment,
  Header,
  Checkbox,
  Icon,
} from "semantic-ui-react";

const AreaSetup = () => {
  const [globaldata, setData] = useState([{}]);
  const [area, setArea] = useState([]);
  const [selectedArea, setSelectedArea] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const [loginInfo, setLoginInfo] = useState();
  const [open, showMessage] = useState(false);
  const [selectedState, setSelectedState] = useState([]);
  const [selectedCity, setSelectedCity] = useState([]);
  const [selectedPostOffice, setSelectedPostOffice] = useState([]);
  const [applyFilter, setApplyFilter] = useState(false);
  const [distCityState, setDistCityState] = useState([]);
  const [defaultStateSel, setDefaultStateSel] = useState([]);
  const [animation, setAnimation] = useState(assets.transitions[12]);
  const [duration, setduration] = useState(800);
  const [messageText, setMessageText] = useState();
  const [refreshScreen, setRefreshScreen] = useState(false);
  const [showProgress, setShowProgress] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [editableData, setEditData] = useState([{}]);
  const [showValidationMessage, setShowValidationMessage] = useState(false);
  const [popupTitle, setTitle] = useState("Add/Update Area details");
  const [isEdit, setEdit] = useState(false);
  const navigation = useNavigate();

  const initialData = {
    id: 0,
    PostOfficeName: "",
    Pincode: "",
    City: "",
    District: "",
    state: "",
  };
  useEffect(() => {
    setShowLoader(true);
    const loginData = JSON.parse(
      apiHandler.getDataFromLocalStorage("loginDetails")
    );
    setLoginInfo(loginData);
    if (
      loginData === null ||
      loginData.userName === "" ||
      loginData.password === ""
    ) {
      navigation("/");
    }
    let areaData = [];
    (async () => {
      let defaultState = [];
      let defaultCity = [];
      defaultState.push("Uttar Pradesh");
      setDefaultStateSel(defaultState);
      const prm = {
        state: selectedState.length > 0 ? selectedState : defaultState,
        city: selectedCity.length > 0 ? selectedCity : defaultCity,
        PostOfficeName: selectedPostOffice.length > 0 ? selectedPostOffice : [],
      };
      let st = [];
      st.push(defaultState);
      setSelectedState(selectedState.length > 0 ? selectedState : defaultState);
      const prm1 = {};
      const cityState = await apiHandler.getDistinctCityState(prm1);
      setDistCityState(cityState);
      areaData = await apiHandler.getAreas(prm);
      setArea(areaData);
      setSelectedArea(areaData);
      showMessage(false);
      setTimeout(() => {
        setShowLoader(false);
      }, 1000);
    })();
  }, [open, applyFilter]);
  useEffect(() => {
    console.log("DD");
  }, [refreshScreen]);
  const onAddNew = () => {
    setEdit(false);
    setShowPopup(true);
    setShowValidationMessage(false);
    setEditData(initialData);
  };
  const onEdit = (selectedId) => {
    const data = area.filter((w) => w.id === selectedId)[0];
    setEdit(true);
    setShowPopup(true);
    setEditData(data);
  };
  const onUpdateChanges = () => {
    const data = {
      ...editableData,
      ...{ entryStatus: isEdit ? "update" : "new" },
      ...{ userId: loginInfo.id },
    };
    if (data === undefined) {
      setMessageText("Please enter data before save.");
      showMessage(true);
      setTimeout(() => showMessage(false), 1000);
      return;
    } else {
      setMessageText("");
    }
    setShowLoader(true);
    setTimeout(() => {
      // apiHandler.saveInvPropConfiguration(data).then((w) => {
      //   if (w[0].result === "Success") {
      //     setMessage("Data Saved Successfully!");
      //   } else {
      //     setMessage("Faild to Save Data!");
      //   }
      //   setShowLoader(false);
      //   showMessage(true);
      //   setTimeout(() => showMessage(false), 1000);
      // });
      (async () => {
        const result = await apiHandler.saveAreaData(data);
        if (result == "success") {
          setMessageText("Data Saved Successfully!");
        } else {
          setMessageText("Faild to Save Data!");
        }
        setShowLoader(false);
        showMessage(true);
        setTimeout(() => showMessage(false), 1000);
      })();

      setShowPopup(false);
    }, 500);
  };
  const renderStateFilters = () => {
    let data = [];
    const state = _.uniqBy(distCityState, (o) => o.state);
    state.map((option) => {
      data.push({
        key: option.id,
        text: option.state,
        value: option.state,
        label: { color: "pink", empty: true, circular: true },
      });
    });
    return (
      <Dropdown
        text="Filter State"
        multiple
        // icon={selectedState.length > 0 ? null : "filter"}
        onChange={selectedData}
        fluid
        selection
        search
        options={data}
        value={selectedState}
        id="states"
        key={data.key}
        defaultValue={defaultStateSel}
      ></Dropdown>
    );
  };
  const renderCityFilters = () => {
    let data = [];
    const cityState = distCityState.filter((w) =>
      selectedState.includes(w.state)
    );
    const city = _.uniqBy(cityState, (o) => o.City);
    city.map((option) => {
      data.push({
        key: option.id,
        text: option.City,
        value: option.City,
        label: { color: "pink", empty: true, circular: true },
      });
    });
    return (
      <Dropdown
        text="Filter City"
        multiple
        //icon={selectedCity.length > 0 ? null : "filter"}
        onChange={selectedData}
        fluid
        selection
        search
        options={data}
        value={selectedCity}
        id="city"
        key={data.key}
      ></Dropdown>
    );
  };
  const renderPostOfficeFilters = () => {
    let data = [];
    let cityState = area.filter((w) => selectedState.includes(w.state));
    if (selectedCity.length > 0) {
      cityState = cityState.filter((w) => selectedCity.includes(w.City));
    }
    const postOffice = _.uniqBy(cityState, (o) => o.PostOfficeName);
    postOffice.map((option) => {
      data.push({
        key: option.id,
        text: option.PostOfficeName,
        value: option.PostOfficeName,
        label: { color: "pink", empty: true, circular: true },
      });
    });
    return (
      <Dropdown
        text="Filter Post Office"
        multiple
        // icon={selectedPostOffice.length > 0 ? null : "filter"}
        onChange={selectedData}
        fluid
        selection
        search
        options={data}
        value={selectedPostOffice}
        id="postOffice"
        key={data.key}
      ></Dropdown>
    );
  };
  const onSelectionChange = (id, checked) => {
    let data = selectedArea;
    console.log("data", data);
    data.some((w) => {
      if (parseInt(w.id) === parseInt(id)) {
        w.selected = checked.checked === true ? 1 : 0;
        return true;
      }
    });

    setSelectedArea(data);
    setRefreshScreen(!refreshScreen);
  };
  const selectedData = (event, data) => {
    let keys = [];
    if (data.id === "states") {
      if (data.value.length === 0) {
        setMessageText("You mush select the state to view the data");
        showMessage(true);
        return;
      }
    }
    const keyData = data.options.filter((o) => data.value.includes(o.value));
    keyData.map((el) => keys.push(el.key));

    if (data.id === "states") setSelectedState(data.value);
    else if (data.id === "city") setSelectedCity(data.value);
    else if (data.id === "postOffice") setSelectedPostOffice(data.value);
    setApplyFilter(!applyFilter);
  };

  const renderGrid = () => {
    const data = selectedArea;
    return data.map((w, counter) => {
      return (
        <tr className="table-info" id={w.id}>
          <td className="col-xs" style={{ width: 20 }}>
            <Icon className="edit" onClick={(e) => onEdit(w.id)} id={w.id} />
          </td>
          <td className="col-xs">
            <Checkbox
              onChange={(e, data) => onSelectionChange(w.id, data)}
              checked={parseInt(w.selected) === 1 ? true : false}
            />
          </td>
          <td style={{ width: 350 }}>{w.PostOfficeName}</td>
          <td>{w.state}</td>
          <td>{w.City}</td>
          <td>{w.District}</td>
          <td>{w.Pincode}</td>
        </tr>
      );
    });
  };
  const onSave = () => {
    setShowProgress(true);
    const data = [];
    const selArea = selectedArea.filter((w) => parseInt(w.selected) === 1);
    selArea.map((w) => {
      data.push(w.id);
    });
    const obj = {
      ...{ ids: data, state: selectedState, city: selectedCity },
    };
    (async () => {
      const result = await apiHandler.setAreaDeliverable(obj);
      console.log("result", result);
      if (result[0].result === "Success") {
        console.log("result", result);
        setMessageText("Data saved successfully");
        showMessage(true);
      }
    })();
    setTimeout(() => {
      setShowProgress(false);
      showMessage(false);
    }, 800);
  };
  const onchange = (e) => {
    setEditData({
      ...editableData,
      [e.target.id]:
        e.target.type === "checkbox"
          ? e.target.checked === true
            ? 1
            : 0
          : e.target.value,
    });
  };
  const renderPopupBody = () => {
    return (
      <>
        <div class="row">
          <div class="col">
            <input
              type="text"
              class="form-control"
              placeholder="PostOffice Name"
              aria-label="PropertyName"
              value={editableData.PostOfficeName}
              id="PostOfficeName"
              onChange={(e) => onchange(e)}
              required
            />
          </div>
        </div>
        <div class="row">
          <div class="col">
            <input
              type="text"
              class="form-control"
              placeholder="State"
              aria-label="state"
              id="state"
              value={editableData.state}
              onChange={(e) => onchange(e)}
            />
          </div>
        </div>
        <div class="row">
          <div class="col">
            <input
              type="text"
              class="form-control"
              placeholder="City"
              aria-label="city"
              id="City"
              value={editableData.City}
              onChange={(e) => onchange(e)}
            />
          </div>
        </div>
        <div class="row">
          <div class="col">
            <input
              type="text"
              class="form-control"
              placeholder="District"
              aria-label="District"
              id="District"
              value={editableData.District}
              onChange={(e) => onchange(e)}
            />
          </div>
        </div>
        <div class="row">
          <div class="col">
            <input
              type="text"
              class="form-control"
              placeholder="Pincode"
              aria-label="Pincode"
              id="Pincode"
              value={editableData.Pincode}
              onChange={(e) => onchange(e)}
            />
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div
              style={
                showValidationMessage === true
                  ? { display: "block", color: "red" }
                  : { color: "red", display: "none" }
              }
            >
              {errorMessage}
            </div>
          </div>
        </div>
      </>
    );
  };
  const renderAddEditPopup = () => {
    return (
      <div
        class="modal"
        tabindex="-1"
        style={showPopup ? { display: "block" } : {}}
        id="dataEntry"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">{popupTitle}</h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => setShowPopup(false)}
              ></button>
            </div>
            <div class="modal-body">{renderPopupBody()}</div>
            <div class="modal-footer">
              <div class="row" style={{ width: "100%" }}>
                <div class="col">
                  <button
                    type="button"
                    class="btn btn-primary"
                    onClick={() => {
                      onUpdateChanges();
                    }}
                  >
                    Save changes
                  </button>
                </div>
                <div class="col">
                  <button
                    type="button"
                    class="btn btn-secondary"
                    data-bs-dismiss="modal"
                    onClick={() => setShowPopup(false)}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <>
      <div class="container">
        <div class="row rowClass">
          <div class="col td-area">{renderStateFilters()}</div>
          <div class="col td-area">{renderCityFilters()}</div>
          <div class="col td-area">{renderPostOfficeFilters()}</div>
          <div class="col">
            <button
              type="button"
              class="btn btn-primary btn-md"
              onClick={() => onSave()}
              style={{ width: 120, float: "left", height: 38 }}
            >
              <span
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
                style={
                  showProgress
                    ? { display: "block", paddingRight: 5, float: "left" }
                    : { display: "none", paddingRight: 5 }
                }
              ></span>
              <span
                style={
                  !showProgress
                    ? { display: "block", paddingRight: 5 }
                    : { display: "none", paddingRight: 5 }
                }
              ></span>
              Save Changes
            </button>
            <div class="col">
              <button
                type="button"
                class="btn btn-success btn-md"
                style={{
                  width: 150,
                  float: "left",
                  height: 38,
                  marginLeft: 5,
                }}
                onClick={() => onAddNew()}
              >
                Add Area
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="table-responsive" style={{ maxHeight: 540 }}>
        <table class="table table-striped table-hover table-bordered ">
          <thead>
            <tr className="table-dark">
              <th scope="col-xs">#</th>
              <th scope="col-xs">#</th>
              <th scope="col">Post Office</th>
              <th scope="col">State</th>
              <th scope="col">City</th>
              <th scope="col">District</th>
              <th scope="col">Pincode</th>
            </tr>
          </thead>
          <tbody>{renderGrid()}</tbody>
        </table>
      </div>
      <Loader showLoader={showLoader} />
      <TransitionablePortal open={open} transition={{ animation, duration }}>
        <Segment
          // style={{
          //   left: "85%",
          //   position: "fixed",
          //   top: "20%",
          //   zIndex: 1000,
          //   backgroundColor: "green",
          //   color: "white",
          //   width: 350,
          // }}
          id="transationMessage"
        >
          <Header>Information</Header>
          <p>{messageText}</p>
        </Segment>
      </TransitionablePortal>
      {renderAddEditPopup()}
    </>
  );
};

export default AreaSetup;
