import React, { useContext, useEffect, useState, useRef } from "react";
import LoginContext from "../context/LoginContext";
import { Icon } from "semantic-ui-react";
import apiHandler from "../commonfunction/data";
import Loader from "../components/Loader";
import {
  Confirm,
  TransitionablePortal,
  Segment,
  Header,
} from "semantic-ui-react";
import { useNavigate } from "react-router-dom";
import assets from "../assets/assets";

// const transitions = [
//   "browse",
//   "browse right",
//   "drop",
//   "fade",
//   "fade up",
//   "fade down",
//   "fade left",
//   "fade right",
//   "fly up",
//   "fly down",
//   "fly left",
//   "fly right",
//   "horizontal flip",
//   "vertical flip",
//   "scale",
//   "slide up",
//   "slide down",
//   "slide left",
//   "slide right",
//   "swing up",
//   "swing down",
//   "swing left",
//   "swing right",
//   "zoom",
// ];
const initialData = {
  firstName: "",
  lastName: "",
  address: "",
  city: "",
  state: "",
  zip: "",
  mobile: "",
  email: "",
  password: "",
  role: -1,
  active: false,
};

const ManageUsers = (props) => {
  const loginContext = useContext(LoginContext);
  const [showPopup, setShowHidePopup] = useState(false);
  //const [isAddNew, setAddNew] = useState(false);
  const [isEdit, setEdit] = useState(false);
  const [globaldata, setData] = useState([{}]);
  const [editableData, setEditData] = useState(initialData);
  const [popupTitle, setTitle] = useState("New User Registration");
  const [open, showMessage] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [animation, setAnimation] = useState(assets.transitions[12]);
  const [duration, setduration] = useState(800);
  const [messageText, setMessage] = useState();
  const [openConfirmationBox, setOpenConfirmationBox] = useState(false);
  const [userId, setUserId] = useState(0);
  const [showValidationMessage, setShowValidationMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const loginAuth = useContext(LoginContext);
  const [loginInfo, setLoginInfo] = useState();
  const navigation = useNavigate();
  const actionButtonStyle = {
    width: 20,
  };
  const hideColumn = { display: "none" };
  useEffect(() => {
    props.showTitle("Manage Users");
    props.hideSideBar(false);
    const loginData = JSON.parse(
      apiHandler.getDataFromLocalStorage("loginDetails")
    );
    setLoginInfo(loginData);
    if (
      loginData === null ||
      loginData.userName === "" ||
      loginData.password === ""
    ) {
      navigation("/");
    }

    setShowLoader(true);
    setTimeout(() => {
      apiHandler.getUserData().then((result) => {
        console.log("result", result);
        setData(result);
        setShowLoader(false);
      });
    }, 1000);
  }, [open]);
  useEffect(() => {
    const title = isEdit ? "Edit User Details" : "New User Registration";
    setTitle(title);
  }, [isEdit]);

  const showHidePopup = (show) => {
    setShowHidePopup(show);
  };
  const onAddNew = () => {
    setEdit(false);
    showHidePopup(true);
    setShowValidationMessage(false);
    setEditData(initialData);
  };
  const onEdit = (selectedId) => {
    const data = globaldata.filter((w) => w.id === selectedId)[0];
    setEdit(true);
    showHidePopup(true);
    setEditData(data);
  };
  const onSave = () => {
    editableData.active = editableData.active === false ? 0 : 1;
    if (validateInput() === false) return;
    const data = {
      ...editableData,
      ...{ entryStatus: isEdit ? "update" : "new" },
      ...{ userId: loginInfo.id },
    };
    if (data === undefined) {
      setMessage("Please enter data before save.");
      showMessage(true);
      setTimeout(() => showMessage(false), 1000);
      return;
    } else {
      setMessage("");
    }
    setShowLoader(true);
    console.log("data", data);
    setTimeout(() => {
      apiHandler.saveUser(data).then((w) => {
        console.log("result", w);
        if (w[0].result === "Success") {
          setMessage("Data Saved Successfully!");
        } else {
          setMessage("Faild to Save Data!");
        }
        setShowLoader(false);
        showMessage(true);
        setTimeout(() => showMessage(false), 1000);
      });
      showHidePopup(false);
    }, 500);
  };
  const validateInput = () => {
    if (editableData.firstName === "" || editableData.firstName === null) {
      setErrorMessage("First name cannot be blank..");
      setShowValidationMessage(true);
      return false;
    }
    if (editableData.address === "" || editableData.address === null) {
      setErrorMessage("Address cannot be blank..");
      setShowValidationMessage(true);
      return false;
    }
    if (editableData.mobile === "" || editableData.mobile === null) {
      setErrorMessage("Mobile cannot be blank..");
      setShowValidationMessage(true);
      return false;
    }
    if (editableData.email === "" || editableData.email === null) {
      setErrorMessage("Email cannot be blank..");
      setShowValidationMessage(true);
      return false;
    }
    if (editableData.password === "" || editableData.password === null) {
      setErrorMessage("Password cannot be blank..");
      setShowValidationMessage(true);
      return false;
    }
    if (editableData.role === -1 || editableData.password === 0) {
      setErrorMessage("Please select role of user..");
      setShowValidationMessage(true);
      return false;
    }
  };
  const ShowConfirmaton = (userID) => {
    setOpenConfirmationBox(true);
    setUserId(userID);
  };
  const onDelete = () => {
    console.log("onDelete", userId);
    const prm = { id: userId, userId: loginInfo.id };
    setTimeout(() => {
      apiHandler.deleteUser(prm).then((w) => {
        if (w[0].result === "Success") {
          setMessage("Data Deleted Successfully!");
        } else {
          setMessage("Faild to delete Data!");
        }
        setShowLoader(false);
        setOpenConfirmationBox(false);
        showMessage(true);
        setTimeout(() => {
          showMessage(false);
        }, 1000);
      });
      showHidePopup(false);
    }, 500);
  };
  const onchange = (e) => {
    setEditData({
      ...editableData,
      [e.target.id]:
        e.target.type === "checkbox"
          ? e.target.checked
            ? 1
            : 0
          : e.target.value,
    });
  };
  const handleCancel = () => {
    setOpenConfirmationBox(false);
  };
  const renderGrid = () => {
    return globaldata.map((w) => {
      return (
        <tr className="table-info" id={w.id}>
          <td className="col-xs" style={actionButtonStyle}>
            <Icon className="trash" onClick={() => ShowConfirmaton(w.id)} />
          </td>
          <td className="col-xs" style={actionButtonStyle}>
            <Icon className="edit" onClick={(e) => onEdit(w.id)} id={w.id} />
          </td>
          <td>{w.firstName}</td>
          <td>{w.lastName}</td>
          <td>{w.address}</td>
          <td>{w.city}</td>
          <td>{w.state}</td>
          <td>{w.zip}</td>
          <td>{w.mobile}</td>
          <td>{w.email}</td>
          <td style={hideColumn}>{w.active}</td>
          <td>
            {w.role === "0" || w.role === "-1"
              ? "unassigned"
              : w.role === "1"
              ? "Admin"
              : "User"}
          </td>
        </tr>
      );
    });
  };
  const renderPopupBody = () => {
    return (
      <>
        <div class="row">
          <div class="col">
            <input
              type="text"
              class="form-control"
              placeholder="First name"
              aria-label="First name"
              value={editableData.firstName}
              id="firstName"
              onChange={(e) => onchange(e)}
              required
            />
          </div>
          <div class="col">
            <input
              type="text"
              class="form-control"
              placeholder="Last name"
              aria-label="Last name"
              id="lastName"
              value={editableData.lastName}
              onChange={(e) => onchange(e)}
            />
          </div>
        </div>
        <div class="row">
          <div class="col">
            <input
              type="text"
              class="form-control"
              id="address"
              placeholder="Address"
              value={editableData.address}
              onChange={(e) => onchange(e)}
            />
          </div>
        </div>
        <div class="row">
          <div class="col">
            <input
              type="text"
              class="form-control"
              id="city"
              placeholder="City"
              value={editableData.city}
              onChange={(e) => onchange(e)}
            />
          </div>
          <div class="col">
            <input
              type="text"
              class="form-control"
              id="state"
              placeholder="State"
              value={editableData.state}
              onChange={(e) => onchange(e)}
            />
          </div>
          <div class="col">
            <input
              type="text"
              class="form-control"
              id="zip"
              placeholder="Zip"
              value={editableData.zip}
              onChange={(e) => onchange(e)}
            />
          </div>
        </div>
        <div class="row">
          <div class="col">
            <input
              type="text"
              class="form-control"
              id="mobile"
              placeholder="Mobile"
              value={editableData.mobile}
              onChange={(e) => onchange(e)}
            />
          </div>
          <div class="col">
            <input
              type="text"
              class="form-control"
              id="email"
              placeholder="Email"
              value={editableData.email}
              onChange={(e) => onchange(e)}
            />
          </div>
        </div>
        <div class="row">
          <div class="col">
            <input
              type="password"
              class="form-control"
              id="password"
              placeholder="Password"
              value={editableData.password}
              onChange={(e) => onchange(e)}
            />
          </div>
          <div class="col">
            <select
              class="form-select"
              style={{ marginTop: 8, height: 46 }}
              value={editableData.role}
              id="role"
              onChange={(e) => onchange(e)}
            >
              <option value="-1">Choose Role</option>
              <option value="1">Admin</option>
              <option value="2">User</option>
            </select>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="form-check form-switch">
              <label class="form-check-label" for="flexSwitchCheckDefault">
                <input
                  class="form-check-input"
                  type="checkbox"
                  role="switch"
                  id="active"
                  checked={parseInt(editableData.active) === 1 ? true : false}
                  onChange={(e) => onchange(e)}
                />
                <span style={{ marginLeft: 10, marginTop: 25 }}>Active</span>
              </label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div
              style={
                showValidationMessage === true
                  ? { display: "block", color: "red" }
                  : { color: "red", display: "none" }
              }
            >
              {errorMessage}
            </div>
          </div>
        </div>
      </>
    );
  };
  const renderAddEditPopup = () => {
    return (
      <div
        class="modal"
        tabindex="-1"
        style={showPopup ? { display: "block" } : {}}
        id="dataEntry"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">{popupTitle}</h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => showHidePopup(false)}
              ></button>
            </div>
            <div class="modal-body">{renderPopupBody()}</div>
            <div class="modal-footer">
              <div class="row" style={{ width: "100%" }}>
                <div class="col">
                  <button
                    type="button"
                    class="btn btn-primary"
                    onClick={() => {
                      onSave();
                    }}
                  >
                    Save changes
                  </button>
                </div>
                <div class="col">
                  <button
                    type="button"
                    class="btn btn-secondary"
                    data-bs-dismiss="modal"
                    onClick={() => setShowHidePopup(false)}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <>
      <Confirm
        open={openConfirmationBox}
        onCancel={handleCancel}
        content="Are you sure, you want to delete the user?"
        header="Confirmation"
        onConfirm={onDelete}
        size="tiny"
        style={{ height: "30%", top: "10%", left: "35%" }}
      />
      <div className="container">
        <div style={{ width: 100, paddingBottom: 5 }}>
          <button
            type="button"
            class="btn btn-primary btn-md"
            onClick={() => onAddNew()}
          >
            Add New
          </button>
        </div>
        <div class="table-responsive" style={{ maxHeight: 500 }}>
          <table class="table table-striped table-hover table-bordered ">
            <thead>
              <tr className="table-dark">
                <th scope="col-xs">#</th>
                <th scope="col-xs">#</th>
                <th scope="col">First Name</th>
                <th scope="col">Last Name</th>
                <th scope="col">Address</th>
                <th scope="col">City</th>
                <th scope="col">State</th>
                <th scope="col">Zip</th>
                <th scope="col">Mobile</th>
                <th scope="col">Email</th>
                <th scope="col" style={hideColumn}>
                  Active
                </th>
                <th scope="col">Role</th>
              </tr>
            </thead>
            <tbody>{renderGrid()}</tbody>
          </table>
        </div>
      </div>
      {renderAddEditPopup()}
      <TransitionablePortal open={open} transition={{ animation, duration }}>
        <Segment
          // style={{
          //   left: "85%",
          //   position: "fixed",
          //   top: "20%",
          //   zIndex: 1000,
          //   backgroundColor: "green",
          //   color: "white",
          //   width: 350,
          // }}
          id="transationMessage"
        >
          <Header>Information</Header>
          <p>{messageText}</p>
        </Segment>
      </TransitionablePortal>
      <Loader showLoader={showLoader} />
    </>
  );
};

export default ManageUsers;
