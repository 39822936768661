import React, { useEffect, useState } from "react";
import ReportHeader from "../components/ReportHeader";
import { useLocation } from "react-router-dom";
import apiHandler from "../commonfunction/data";
import moment from "moment";
import _ from "lodash";
import globalFunction from "../commonfunction/globalFunction";
//import { html2pdf } from "html2pdf.js";

const CartLog = (props) => {
  const location = useLocation();
  const [reportConfig, setReportConfig] = useState(location.state.reportConfig);
  const [startDate, setStartDate] = useState(location.state.startDate);
  const [endDate, setEndDate] = useState(location.state.endDate);
  const [reportData, setReportDate] = useState([]);
  const [totalRow, setTotalRow] = useState({
    mobile: "",
  });
  useEffect(() => {
    props.showTitle();
    (async () => {
      const prm = {
        procName: reportConfig.dataSource,
        fromDate: startDate,
        toDate: endDate,
      };

      const result = await apiHandler.getReportData(prm);
      console.log("prm", result);
      setReportDate(result);
    })();
  }, []);
  useEffect(() => {
    console.log("totalRow", totalRow);
  }, [totalRow]);

  const renderGridRow = (filter) => {
    const data = reportData;

    return data.map((el) => {
      return (
        <>
          <tr>
            <td style={{ textAlign: "left" }}>{el.firstName}</td>
            <td style={{ textAlign: "left" }}>{el.lastName}</td>
            <td style={{ textAlign: "left" }}>{el.address}</td>
            <td style={{ textAlign: "left" }}>{el.city}</td>
            <td style={{ textAlign: "left" }}>{el.state}</td>
            <td style={{ textAlign: "left" }}>{el.zip}</td>
            <td style={{ textAlign: "left" }}>{el.mobile}</td>

            {/* <td style={{ textAlign: "right" }}>
              {globalFunction.formatNumber(el.subTotal)}
            </td> */}
          </tr>
          {renderItemsOnOrder(el["mobile"])}
        </>
      );
    });
  };
  const renderItemsOnOrder = (userId) => {
    const data = _.unionBy(reportData, (w) => w.mobile === userId);
    return data.map((el) => {
      return (
        <tr>
          <td></td>
          <td></td>
          <td></td>
          <td style={{ fontSize: 10, textAlign: "left" }}>
            <i>Product: {el["productName"]}</i>
          </td>
          <td style={{ fontSize: 10 }}>
            <i>Quantity: {el["qty"]}</i>
          </td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
      );
    });
  };
  const renderGridDetails = () => {
    const data = _.unionBy(reportData, (w) => w["mobile"]);

    const orderData = _.unionBy(reportData, (w) => w.mobile);
    return orderData.map((grpRow) => {
      return <>{renderGridRow("")}</>;
    });
  };
  const renderGrid = () => {
    return (
      <table class="table table-striped" width="98%">
        <thead style={{ backgroundColor: "#E5E7E9" }}>
          <tr style={{ height: 40 }}>
            <th style={{ textAlign: "left" }}>
              <strong>First Name</strong>
            </th>
            <th style={{ textAlign: "left" }}>
              <strong>Last Name</strong>
            </th>
            <th style={{ textAlign: "left" }}>
              <strong>Address</strong>
            </th>
            <th style={{ textAlign: "left" }}>
              <strong>City</strong>
            </th>
            <th style={{ textAlign: "left" }}>
              <strong>State</strong>
            </th>
            <th style={{ textAlign: "left" }}>
              <strong>Zip</strong>
            </th>
            <th style={{ textAlign: "left" }}>
              <strong>Mobile</strong>
            </th>
          </tr>
        </thead>
        <tbody>{renderGridDetails()}</tbody>
      </table>
    );
  };
  const renderReport = () => {
    return (
      <div id="reportContainer">
        <ReportHeader
          title={reportConfig.reportTitle}
          startDate={startDate}
          endDate={endDate}
        />
        {renderGrid()}
      </div>
    );
  };
  const onPrintReport = () => {
    let originalContents = document.getElementById("reportContainer").outerHTML;
    setTimeout(() => {
      document.getElementById("header_title").style.height = "10px";
      document.getElementById("header_companyName").style.height = "10px";
    }, 500);

    let printContents = document.getElementById("reportContainer").outerHTML;
    var printWindow = window.open("", "PRINT");

    setTimeout(() => {
      // Needed for large documents
      printWindow.document.body.style.margin = "0 0";
      printWindow.document.write(document.head.innerHTML);
      printWindow.document.write("<body>");
      printWindow.document.write(printContents);
      printWindow.document.write("</body>");

      printWindow.document.close(); // necessary for IE >= 10
      printWindow.focus(); // necessary for IE >= 10*/
      printWindow.print();
      printWindow.close();
      document.getElementById("reportContainer").innerHTML = originalContents;
    }, 500);
  };
  // const onExportToPDF = () => {
  //   //let printContents = document.getElementById("reportContainer").outerHTML;
  //   var element = document.getElementById("reportContainer");
  //   var opt = {
  //     margin: 1,
  //     filename: "myfile.pdf",
  //     image: { type: "jpeg", quality: 0.98 },
  //     html2canvas: { scale: 2 },
  //     jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
  //   };

  //   // New Promise-based usage:
  //   var worker = html2pdf().from(element).save();

  //   // Old monolithic-style usage:
  //   //html2pdf(element, opt);
  // };
  return (
    <>
      <div class="container">
        <div class="row">
          <div class="col">
            <button
              type="button"
              class="btn btn-primary btn-md"
              style={{ width: 100, float: "left" }}
              onClick={() => onPrintReport()}
            >
              Print Report
            </button>
          </div>
          {/* <div class="col">
            <button
              type="button"
              class="btn btn-primary btn-md"
              style={{ width: 100, float: "left" }}
              onClick={() => onExportToPDF()}
            >
              Export To PDF
            </button>
          </div> */}
        </div>
        <div className="table-responsive" style={{ maxHeight: 500 }}>
          {renderReport()}
        </div>
      </div>
    </>
  );
};

export default CartLog;
