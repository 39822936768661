import { createContext, useState } from "react";
import LoginContext from "../context/LoginContext";

const AuthContext = ({ children }) => {
  const initialState = {
    userName: "",
    password: "",
    isAuthonticated: false,
    role: -1,
    firstName: "",
    lastName: "",
  };
  const [auth, setAuth] = useState(initialState);
  const updateAuth = (prm) => {
    console.log("prm", prm);
    setAuth(prm);
  };
  return (
    <LoginContext.Provider value={{ auth, updateAuth }}>
      {children}
    </LoginContext.Provider>
  );
};

export default AuthContext;
