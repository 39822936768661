import "./App.css";
import Routing from "./components/routes";
import AppBar from "./components/AppBar";
function App() {
  return (
    <div className="App">
      <AppBar />
      {/* <Routing /> */}
    </div>
  );
}

export default App;
