import React, { useEffect, useState } from "react";
//import LoginContext from "../context/LoginContext";
import { Icon } from "semantic-ui-react";
import apiHandler from "../commonfunction/data";
import Loader from "../components/Loader";
import {
  Confirm,
  TransitionablePortal,
  Segment,
  Header,
} from "semantic-ui-react";
import { useNavigate } from "react-router-dom";
import assets from "../assets/assets";

const transitions = [
  "browse",
  "browse right",
  "drop",
  "fade",
  "fade up",
  "fade down",
  "fade left",
  "fade right",
  "fly up",
  "fly down",
  "fly left",
  "fly right",
  "horizontal flip",
  "vertical flip",
  "scale",
  "slide up",
  "slide down",
  "slide left",
  "slide right",
  "swing up",
  "swing down",
  "swing left",
  "swing right",
  "zoom",
];
const initialData = {
  brandName: "",
  brandDescription: "",
  brandImage: "",
};

const BrandMaster = (props) => {
  const [showPopup, setShowHidePopup] = useState(false);
  const [isEdit, setEdit] = useState(false);
  const [globaldata, setData] = useState([{}]);
  const [editableData, setEditData] = useState(initialData);
  const [popupTitle, setTitle] = useState("Brand Master");
  const [open, showMessage] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [animation, setAnimation] = useState(transitions[12]);
  const [duration, setduration] = useState(800);
  const [messageText, setMessage] = useState();
  const [openConfirmationBox, setOpenConfirmationBox] = useState(false);
  const [showValidationMessage, setShowValidationMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [imgData, setImageState] = useState();
  const [imgSize, setImageSize] = useState();
  const [loginInfo, setLoginInfo] = useState();
  const [selectedId, setSeletedId] = useState();

  const navigation = useNavigate();

  const actionButtonStyle = {
    width: 20,
  };
  useEffect(() => {
    props.showTitle("Brand Master");
    props.hideSideBar(false);
    const loginData = JSON.parse(
      apiHandler.getDataFromLocalStorage("loginDetails")
    );
    setLoginInfo(loginData);
    if (
      loginData === null ||
      loginData.userName === "" ||
      loginData.password === ""
    ) {
      navigation("/");
    }

    setShowLoader(true);
    setTimeout(() => {
      apiHandler.getBrandData().then((result) => {
        setData(result);
        setShowLoader(false);
      });
    }, 1000);
  }, [open]);
  useEffect(() => {
    const title = isEdit ? "Edit Brand Details" : "Add New Brand";
    setTitle(title);
  }, [isEdit]);

  const showHidePopup = (show) => {
    setShowHidePopup(show);
  };
  const onAddNew = () => {
    setEdit(false);
    showHidePopup(true);
    setShowValidationMessage(false);
    setEditData(initialData);
  };
  const onEdit = (selectedId) => {
    const data = globaldata.filter((w) => w.id === selectedId)[0];
    setEdit(true);
    showHidePopup(true);
    setEditData(data);
  };
  const onSave = () => {
    if (validateInput() === false) return;
    const data = {
      ...editableData,
      ...{ entryStatus: isEdit ? "update" : "new" },
      ...{ userId: loginInfo.id },
    };
    if (data === undefined) {
      setMessage("Please enter data before save.");
      showMessage(true);
      setTimeout(() => showMessage(false), 1000);
      return;
    } else {
      setMessage("");
    }
    setShowLoader(true);

    setTimeout(() => {
      apiHandler.saveBranch(data).then((w) => {
        if (w[0].result === "Success") {
          apiHandler.uploadFile(imgData, "brands").then((imgResult) => {
            setMessage("Data Saved Successfully!");
          });
        } else {
          setMessage("Faild to Save Data!");
        }
        setShowLoader(false);
        showMessage(true);
        setTimeout(() => showMessage(false), 1000);
      });
      // apiHandler.uploadFile(imgData, "brands").then((imgResult) => {
      //   if (imgResult[0].result === "Success") {
      //     apiHandler.saveBranch(data).then((w) => {
      //       if (w[0].result === "Success") {
      //         setMessage("Data Saved Successfully!");
      //       } else {
      //         setMessage("Faild to Save Data!");
      //       }
      //       setShowLoader(false);
      //       showMessage(true);
      //       setTimeout(() => showMessage(false), 1000);
      //     });
      //   }
      // });

      showHidePopup(false);
    }, 500);
  };
  const validateInput = () => {
    console.log("editableData", editableData);
    if (editableData.branchName === "" || editableData.branchName === null) {
      setErrorMessage("Branch Name cannot be blank..");
      setShowValidationMessage(true);
      return false;
    }
    if (imgSize > 2097152) {
      setErrorMessage("Upload file size cannot be greater then 2MB!");
      setShowValidationMessage(true);
      return false;
    }
  };
  const ShowConfirmaton = (selectedBranchId) => {
    setOpenConfirmationBox(true);
    setSeletedId(selectedBranchId);
  };
  const setImage = (e) => {
    const img = e.target.files[0];
    var reader = new FileReader();
    var imgBox = document.getElementById("brand_img");
    reader.onload = function (e) {
      imgBox.src = e.target.result;
    };
    reader.readAsDataURL(img);

    setImageSize(img.size);
    setImageState(img);
    setEditData({
      ...editableData,
      [e.target.id]: img.name,
    });
  };
  const onDelete = () => {
    const prm = { id: selectedId, userId: loginInfo.id };
    console.log("prm", prm);
    setTimeout(() => {
      apiHandler.deleteBranch(prm).then((w) => {
        if (w[0].result === "Success") {
          setMessage("Data Deleted Successfully!");
        } else {
          setMessage("Faild to delete Data!");
        }
        setShowLoader(false);
        setOpenConfirmationBox(false);
        showMessage(true);
        setTimeout(() => {
          showMessage(false);
        }, 1000);
      });
      showHidePopup(false);
    }, 500);
  };
  const onchange = (e) => {
    console.log("onchange", e.target.id, e.target.value);
    setEditData({
      ...editableData,
      [e.target.id]: e.target.value,
    });
  };
  const handleCancel = () => {
    setOpenConfirmationBox(false);
  };
  const renderGrid = () => {
    return globaldata.map((w) => {
      return (
        <tr className="table-info" id={w.id}>
          <td className="col-xs" style={actionButtonStyle}>
            <Icon className="trash" onClick={() => ShowConfirmaton(w.id)} />
          </td>
          <td className="col-xs" style={actionButtonStyle}>
            <Icon className="edit" onClick={(e) => onEdit(w.id)} id={w.id} />
          </td>
          <td>{w.brandName}</td>
          <td>{w.brandDescription}</td>
          <td>
            <img
              src={
                w.brandImage !== undefined
                  ? `${assets.IMAGE_URL}/brands/${w.brandImage}`
                  : ""
              }
              srcSet={
                w.brandImage !== undefined
                  ? `${assets.IMAGE_URL}/brands/${w.brandImage} 1x`
                  : ""
              }
              style={{ height: 25 }}
            />
          </td>
        </tr>
      );
    });
  };
  const renderPopupBody = () => {
    return (
      <>
        <div class="row">
          <div class="col">
            <input
              type="text"
              class="form-control"
              placeholder="Brand Name"
              aria-label="Brand Name"
              value={editableData.brandName}
              id="brandName"
              onChange={(e) => onchange(e)}
              required
            />
          </div>
        </div>
        <div class="row">
          <div class="col">
            <input
              type="text"
              class="form-control"
              placeholder="Description"
              aria-label="Description"
              id="brandDescription"
              value={editableData.brandDescription}
              onChange={(e) => onchange(e)}
            />
          </div>
        </div>

        <div class="row">
          <div class="col">
            <div class="input-group mb-3">
              <input
                type="file"
                class="form-control"
                id="brandImage"
                onChange={(e) => setImage(e)}
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <img
              src={`${assets.IMAGE_URL}/brands/${editableData.brandImage}`}
              class="img-thumbnail float-start"
              alt="..."
              style={{ height: 180 }}
              id="brand_img"
              srcSet={`${assets.IMAGE_URL}/brands/${editableData.brandImage} 1x`}
            />
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div
              style={
                showValidationMessage === true
                  ? { display: "block", color: "red" }
                  : { color: "red", display: "none" }
              }
            >
              {errorMessage}
            </div>
          </div>
        </div>
      </>
    );
  };
  const renderAddEditPopup = () => {
    return (
      <div
        class="modal"
        tabindex="-1"
        style={showPopup ? { display: "block" } : {}}
        id="dataEntry"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">{popupTitle}</h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => showHidePopup(false)}
              ></button>
            </div>
            <div class="modal-body">{renderPopupBody()}</div>
            <div class="modal-footer">
              <div class="row" style={{ width: "100%" }}>
                <div class="col">
                  <button
                    type="button"
                    class="btn btn-primary"
                    onClick={() => {
                      onSave();
                    }}
                  >
                    Save changes
                  </button>
                </div>
                <div class="col">
                  <button
                    type="button"
                    class="btn btn-secondary"
                    data-bs-dismiss="modal"
                    onClick={() => setShowHidePopup(false)}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <>
      <Confirm
        open={openConfirmationBox}
        onCancel={handleCancel}
        content="Are you sure, you want to delete the category?"
        header="Confirmation"
        onConfirm={onDelete}
        size="tiny"
        style={{ height: "30%", top: "10%", left: "35%" }}
      />
      <div className="container">
        <div style={{ width: 100, paddingBottom: 5 }}>
          <button
            type="button"
            class="btn btn-primary btn-md"
            onClick={() => onAddNew()}
          >
            Add New
          </button>
        </div>
        <table class="table table-striped table-hover table-bordered ">
          <thead>
            <tr className="table-dark">
              <th scope="col-xs">#</th>
              <th scope="col-xs">#</th>
              <th scope="col">Brand Name</th>
              <th scope="col">Description</th>
              <th scope="col">Image</th>
            </tr>
          </thead>
          <tbody>{renderGrid()}</tbody>
        </table>
      </div>
      {renderAddEditPopup()}
      <TransitionablePortal open={open} transition={{ animation, duration }}>
        <Segment
          // style={{
          //   left: "85%",
          //   position: "fixed",
          //   top: "20%",
          //   zIndex: 1000,
          //   backgroundColor: "green",
          //   color: "white",
          //   width: 350,
          // }}
          id="transationMessage"
        >
          <Header>Information</Header>
          <p>{messageText}</p>
        </Segment>
      </TransitionablePortal>
      <Loader showLoader={showLoader} />
    </>
  );
};

export default BrandMaster;
